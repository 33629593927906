import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../constants';
import { IUserResponse } from '../../models';

async function getUser(onGetErrorMessage?: string): Promise<IUserResponse> {
    const { data } = await axios.get('/auth/user', { errorMessage: onGetErrorMessage });
    return data;
}

export const useGetUser = () => {
    const { t } = useTranslation();
    return useQuery<IUserResponse, AxiosError>({
        queryKey: [queryKeys.AUTH_USER],
        queryFn: () => getUser(t('getUserError')),
    });
};
