import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DialogTitleWithClose, ISupportee } from '../../../shared';
import { useRelations, useSaveSupportee } from '../../hooks';
import { supporteeMapperFromForm, supporteeMapperToForm } from '../../mappers';
import { ISupporteeForm } from '../../models';
import { useSupporteeSchema } from '../../validators';
import { SupporteeInputs } from '../supportee-inputs/supportee-inputs.component';

export function SaveSupporteeModal({
    handleClose,
    open,
    supportCaseId,
    supportee,
}: {
    open: boolean;
    supportCaseId: string;
    supportee?: ISupportee | null;
    handleClose: () => void;
}) {
    const { t, i18n } = useTranslation();

    const { mutateAsync: saveSupportee } = useSaveSupportee(supportCaseId);

    const formMethods = useForm<ISupporteeForm>({
        resolver: yupResolver(useSupporteeSchema()),
        mode: 'onSubmit',
    });
    const { reset, handleSubmit } = formMethods;

    useEffect(() => {
        if (supportee) {
            reset({ ...supporteeMapperToForm(supportee, i18n.language.substr(0, 2)) });
        } else {
            reset({
                firstName: '',
                lastName: '',
                dateOfBirth: null,
                nationalSecurityNumber: '',
                gender: '',
                nationality: null,
                language: null,
                relation: null,
            });
        }
    }, [supportee, open]);

    const relations = useRelations();
    const onSubmit = async (data: ISupporteeForm) => {
        await saveSupportee(supporteeMapperFromForm(data, relations));
        handleClose();
    };

    return (
        <>
            <Dialog open={open}>
                <FormProvider {...formMethods}>
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <DialogTitleWithClose onClose={handleClose}>
                            {supportee ? t('editSupportee') : t('addSupportee')}
                        </DialogTitleWithClose>
                        <DialogContent>
                            <Box sx={{ pt: 1, minWidth: 500 }}>
                                <SupporteeInputs vertical={true} />
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button type="submit" variant="contained" color="primary">
                                {t('save')}
                            </Button>
                            <Button onClick={handleClose} color="secondary">
                                {t('cancel')}
                            </Button>
                        </DialogActions>
                    </form>
                </FormProvider>
            </Dialog>
        </>
    );
}
