import axios, { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { ListModel, ListQueryParam, queryKeys } from '../../../shared';
import { ICardSet } from '../../models';

async function getCardSets(params: ListQueryParam, onGetErrorMessage?: string): Promise<ListModel<ICardSet>> {
    const { data } = await axios.get('/support-api/card-sets', {
        params,
        errorMessage: onGetErrorMessage,
    });
    return data;
}

export const useGetCardSets = (params: ListQueryParam) => {
    return useQuery<ListModel<ICardSet>, AxiosError>({
        queryKey: [queryKeys.CARD_SETS, params],
        queryFn: () => getCardSets(params),
        enabled: params.pageSize == undefined || params.pageSize > 0,
    });
};
