import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FormCard, IParams, Page } from '../../../shared';
import {
    OrganisationAddressInputs,
    OrganisationContactInputs,
    OrganisationDataInputs,
    OrganisationPreferredPostalCodeInputs,
} from '../../components';
import { useGetOrganisation, useSaveOrganisation } from '../../hooks';
import { organisationToFormMapper } from '../../mappers';
import { IEditOrganisation } from '../../models';
import { useOrganisationSchema } from '../../validators';

export const OrganisationEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams<keyof IParams>() as IParams;
    const { data: organisation } = useGetOrganisation(id);
    const { mutateAsync: saveOrganisation, isPending } = useSaveOrganisation();

    const form = useForm<IEditOrganisation>({
        resolver: yupResolver(useOrganisationSchema()),
        defaultValues: { defaultSupportPeriod: 52 },
        mode: 'all',
    });

    useEffect(() => {
        if (organisation) form.reset(organisationToFormMapper(organisation));
    }, [organisation, form]);

    const onSubmit = async (data: IEditOrganisation) => {
        await saveOrganisation(data);
        navigate('/admin/organisations');
    };

    return (
        <Page
            title={organisation?.name || t('newOrganisation')}
            actions={[
                <Button onClick={() => navigate('/admin/organisations')} color="secondary">
                    {t('cancel')}
                </Button>,
                <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)} disabled={isPending}>
                    {t('save')}
                </Button>,
            ]}
            onBack={() => navigate(-1)}
        >
            <FormProvider {...form}>
                <FormCard
                    handleSubmit={form.handleSubmit(onSubmit)}
                    actions={[
                        <Button type="submit" variant="contained" color="primary">
                            {t('save')}
                        </Button>,
                        <Button onClick={() => navigate('/admin/organisations')} color="secondary">
                            {t('cancel')}
                        </Button>,
                    ]}
                >
                    <OrganisationDataInputs />
                    <OrganisationAddressInputs />
                    <OrganisationPreferredPostalCodeInputs />
                    <OrganisationContactInputs />
                </FormCard>
            </FormProvider>
        </Page>
    );
};
