import {
    ICompleteIntake,
    ICreateIntake,
    IIntake,
    IntakeQuery,
    IUpdateIntake,
    ListModel,
    supportApi,
} from '../../shared';

class IntakesClient {
    public async getList(supportCaseId: string, { pageSize = 10, ...query }: IntakeQuery): Promise<ListModel<IIntake>> {
        const { data } = await supportApi.get<ListModel<IIntake>>(`/support-cases/${supportCaseId}/intakes`, {
            params: { pageSize, ...query },
        });
        return data;
    }

    public async getOne(supportCaseId?: string, intakeId?: string): Promise<IIntake> {
        const { data } = await supportApi.get<IIntake>(`/support-cases/${supportCaseId}/intakes/${intakeId}`);
        return data;
    }
    public async createOne(supportCaseId: string, item: ICreateIntake): Promise<IIntake> {
        const { data } = await supportApi.post<IIntake>(`/support-cases/${supportCaseId}/intakes/`, item);
        return data;
    }

    public async updateOne(supportCaseId: string, intakeId: string, item: IUpdateIntake): Promise<IIntake> {
        const { data } = await supportApi.put<IIntake>(`/support-cases/${supportCaseId}/intakes/${intakeId}`, item);
        return data;
    }

    public async complete(supportCaseId: string, intakeId: string, item: ICompleteIntake): Promise<IIntake> {
        const { data } = await supportApi.post<IIntake>(
            `/support-cases/${supportCaseId}/intakes/${intakeId}/complete`,
            item,
        );
        return data;
    }
}

export const intakesClient = new IntakesClient();
