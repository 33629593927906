import { IIntake, IIntakeExpenseForm } from '../../shared';

export const intakeExpensesToFormMapper = (intake: IIntake): IIntakeExpenseForm => {
    return {
        expenses:
            intake.expenses
                ?.filter(({ lumpSum }) => !lumpSum)
                .sort((a, b) => a.expenseTypeName.localeCompare(b.expenseTypeName)) || [],
    };
};
