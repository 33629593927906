import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { queryKeys } from '../../shared';
import { IReportResult, IRunReportSort } from '../models';
import { IRunReportFilter } from '../models';

async function runReport(id: string, filters: IRunReportFilter[], sort?: IRunReportSort): Promise<IReportResult> {
    const { data } = await axios.post(`/reporting/reports/${id}/run`, {
        filters,
        sort,
    });
    return data;
}

export const useReportResult = (id: string, filters: IRunReportFilter[], sort?: IRunReportSort, enabled?: boolean) => {
    return useQuery<IReportResult, AxiosError>({
        queryKey: [queryKeys.REPORT_RESULT, id, filters, sort],
        queryFn: () => runReport(id, filters, sort),
        enabled,
    });
};
