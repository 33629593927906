import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IReferralOrganisation, queryKeys } from '../../../shared';

async function deleteReferralOrganisation(
    id: string,
    onDeleteSuccessMessage?: string,
    onDeleteErrorMessage?: string,
): Promise<IReferralOrganisation> {
    const { data } = await axios.delete(`/support-api/referral-organisations/${id}`, {
        successMessage: onDeleteSuccessMessage,
        errorMessage: onDeleteErrorMessage,
    });
    return data;
}

export const useDeleteReferralOrganisation = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation<IReferralOrganisation, AxiosError, string>({
        mutationFn: (id) =>
            deleteReferralOrganisation(
                id,
                t('deleteReferralOrganisationSuccess'),
                t('deleteReferralOrganisationError'),
            ),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [queryKeys.REFERRAL_ORGANISATIONS] });
        },
    });
};
