import { Autocomplete, TextField } from '@mui/material';
import { Control, Controller } from 'react-hook-form';

interface Props {
    name: string;
    label: string;
    control: Control<any>;
    options: any[];
    getOptionLabel?: (option: any) => string;
    isOptionEqualToValue?: (option: any, value: any) => boolean;
    allValuesAllowed?: boolean;
    defaultValue: any;
    minWidth?: number;
}
export const AutocompleteComponent: React.FC<Props> = ({
    name,
    label,
    control,
    options,
    getOptionLabel,
    isOptionEqualToValue,
    allValuesAllowed,
    defaultValue,
    minWidth = 227,
}) => {
    return (
        <Controller<any>
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Autocomplete
                    id={`${name}-field`}
                    sx={{ minWidth: minWidth }}
                    autoComplete={allValuesAllowed}
                    freeSolo={allValuesAllowed}
                    onChange={(e, data) => onChange(data)}
                    onInputChange={allValuesAllowed ? (e, data) => onChange(data) : undefined}
                    value={value}
                    options={options}
                    getOptionLabel={getOptionLabel}
                    isOptionEqualToValue={isOptionEqualToValue}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
                            error={!!error}
                            helperText={error ? error.message : null}
                        />
                    )}
                />
            )}
        />
    );
};
