import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, MenuItem } from '@mui/material';
import * as countries from 'i18n-iso-countries';
import i18n from 'i18next';
import { ChangeEvent } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    AutocompleteComponent,
    ContactType,
    FormCard,
    FormCardSectionComponent,
    FormGrid,
    InputTextField,
    Page,
    ControlledSelect,
    useSelectedOrganisation,
} from '../../../shared';
import { ContactInputs, SupporteeInputs } from '../../components';
import { FamilySituation, LivingSituation } from '../../enums';
import { useRelations, useSaveSupportCase } from '../../hooks';
import { ObjectToArrayMapper } from '../../mappers';
import { supportCaseFromFormMapper } from '../../mappers/support-case.mapper';
import { ISupportCaseForm } from '../../models';
import { useSupportCaseSchema } from '../../validators';

export function SupportCaseCreatePage() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const form = useForm<ISupportCaseForm>({
        resolver: yupResolver(useSupportCaseSchema()),
        mode: 'all',
        defaultValues: {
            address: { country: { code: 'BE', name: 'België' } },
        },
    });
    const { control, getValues, setValue, handleSubmit } = form;
    const {
        fields: contactFields,
        append: appendContact,
        remove: removeContact,
    } = useFieldArray({
        control,
        name: 'contacts',
    });

    const { mutateAsync } = useSaveSupportCase();
    const { organisation } = useSelectedOrganisation();
    const relations = useRelations();

    const onSubmit = async (data: ISupportCaseForm) => {
        if (organisation) {
            const savedSupportCase = await mutateAsync(supportCaseFromFormMapper(data, organisation?.id, relations));
            navigate(`/support/support-cases/${savedSupportCase.id}/detail`);
        }
    };

    const handlePrimaryContactChange = (event: ChangeEvent, onChange: any) => {
        const index = getValues().contacts.findIndex((contact) => contact.primaryContact);
        if (index >= 0) {
            setValue(`contacts.${index}.primaryContact`, false);
        }

        onChange(event);
    };

    return (
        <Page
            title={t('newSupportCase')}
            actions={[
                <Button onClick={() => navigate(-1)} color="secondary">
                    {t('cancel')}
                </Button>,
                <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
                    {t('save')}
                </Button>,
            ]}
        >
            <FormProvider {...form}>
                <FormCard
                    handleSubmit={handleSubmit(onSubmit)}
                    actions={[
                        <Button type="submit" variant="contained" color="primary">
                            {t('save')}
                        </Button>,
                        <Button onClick={() => navigate(-1)} color="secondary">
                            {t('cancel')}
                        </Button>,
                    ]}
                >
                    {window.carity.environment.shareSupportCases && (
                        <FormCardSectionComponent title={t('organisation')}>
                            {organisation?.name || ''}
                        </FormCardSectionComponent>
                    )}
                    <FormCardSectionComponent title={t('primarySupportee')}>
                        <SupporteeInputs field="primarySupportee" />
                    </FormCardSectionComponent>
                    <FormCardSectionComponent title={t('details')}>
                        <FormGrid xs={12} sm={6} lg={3} xl={2} containerProps={{ pl: 2 }}>
                            <ControlledSelect
                                name={`livingSituation`}
                                label={t('livingSituation')}
                                control={control}
                                minWidth={0}
                            >
                                <MenuItem value={''}>{t('unknown')}</MenuItem>
                                {Object.values(LivingSituation).map((situation) => (
                                    <MenuItem value={situation} key={situation}>
                                        {t(situation)}
                                    </MenuItem>
                                ))}
                            </ControlledSelect>

                            <ControlledSelect
                                name={`familySituation`}
                                label={t('familySituation')}
                                control={control}
                                minWidth={0}
                            >
                                <MenuItem value={''}>{t('unknown')}</MenuItem>
                                {Object.values(FamilySituation).map((situation) => (
                                    <MenuItem value={situation} key={situation}>
                                        {t(situation)}
                                    </MenuItem>
                                ))}
                            </ControlledSelect>
                        </FormGrid>
                    </FormCardSectionComponent>
                    <FormCardSectionComponent title={t('addressLabel')}>
                        <FormGrid xs={12} sm={6} lg={3} xl={2} containerProps={{ pl: 2 }}>
                            <InputTextField name="address.street" label={t('streetLabel')} control={control} />
                            <InputTextField name="address.number" label={t('numberLabel')} control={control} />
                            <InputTextField name="address.box" label={t('boxLabel')} control={control} />

                            <InputTextField name="address.postalCode" label={t('postalCodeLabel')} control={control} />
                            <InputTextField name="address.city" label={t('cityLabel')} control={control} />
                            <AutocompleteComponent
                                minWidth={0}
                                name="address.country"
                                label={t('countryLabel')}
                                control={control}
                                defaultValue={null}
                                options={ObjectToArrayMapper(countries.getNames(i18n.language.slice(0, 2)))}
                                getOptionLabel={(option) => option.name}
                                isOptionEqualToValue={(option, value) =>
                                    value ? option.code === value.code || option.name == value.name : false
                                }
                            />
                        </FormGrid>
                    </FormCardSectionComponent>
                    <FormCardSectionComponent
                        title={t('contactInformation')}
                        footer={
                            <Button
                                data-testid="add-contact"
                                onClick={() => {
                                    appendContact({ type: ContactType.Email, data: '', primaryContact: false });
                                }}
                                color="secondary"
                            >
                                + {t('contactsAdd')}
                            </Button>
                        }
                    >
                        <Grid container direction="column" spacing={2}>
                            {contactFields.map((field, index) => (
                                <Grid item key={field.id}>
                                    <ContactInputs
                                        field={`contacts.${index}`}
                                        control={control}
                                        onRemoveContact={() => removeContact(index)}
                                        handlePrimaryContactChange={handlePrimaryContactChange}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </FormCardSectionComponent>
                </FormCard>
            </FormProvider>
        </Page>
    );
}
