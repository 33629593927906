export const adminTranslationsNL = {
    admin: 'Admin',
    codeLabel: 'Code',
    codeUniqErr: 'De code moet uniek zijn.',
    createOrganisationSuccess: 'Organisatie succesvol aangemaakt.',
    createOrganisationError: 'Er is een probleem opgetreden bij het aanmaken van de organisatie.',
    createOrganisationTypeSuccess: 'Organisatietype succesvol aangemaakt.',
    createOrganisationTypeError: 'Er is een probleem opgetreden bij het aanmaken van het organisatietype.',
    createSupportTypeSuccess: 'Hulptype succesvol aangemaakt.',
    createSupportTypeError: 'Er is een probleem opgetreden bij het aanpassen van het hulptype.',
    deleteOrganisationSuccess: 'Organisatie succesvol verwijderd',
    deleteOrganisationError: 'Er is een probleem opgetreden bij het verwijderen van de organisatie',
    deleteOrganisationTypeSuccess: 'Organisatietype succesvol verwijderd.',
    deleteOrganisationTypeError: 'Er is een probleem opgetreden bij het verwijderen van het organisatietype',
    deleteSupportTypeSuccess: 'Hulptype succesvol verwijderd.',
    deleteSupportTypeError: 'Er is een probleem opgetreden bij het verwijderen van het hulptype.',
    external: 'extern',
    getOrganisationError: 'Er is een probleem opgetreden bij het ophalen van de organisatie.',
    getOrganisationsError: 'Er is een probleem opgetreden bij het ophalen van de organisaties.',
    getOrganisationTypeError: 'Er is een probleem opgetreden bij het ophalen van het organisatietype.',
    getOrganisationTypesError: 'Er is een probleem opgetreden bij het ophalen van de organisatietypes.',
    getSupportTypeError: 'Er is een probleem opgetreden bij het ophalen van het hulptype.',
    getSupportTypesError: 'Er is een probleem opgetreden bij het ophalen van de hulptypes.',
    internal: 'intern',
    makePrimary: 'Maak primair',

    newOrganisation: 'Nieuwe organisatie',
    newOrganisationType: 'Nieuw organisatietype',
    newSupportType: 'Nieuw hulptype',
    organisationTypeDeleteErr: 'Deze organisatie kan niet verwijderd worden omdat ze in gebruik is.',
    organisations: 'Organisaties',
    organisationSearch: 'Zoek organisatie',
    organisationTypes: 'Organisatietypes',
    organisationDeleteWarningText: 'Bent u zeker dat u deze organisatie wil verwijderen?',
    organisationDeleteWarningTitle: 'Verwijder organisatie',
    typeDeleteWarningText: 'Bent u zeker dat u dit organisatietype wil verwijderen?',
    typeDeleteWarningTitle: 'Verwijder type',
    typeLabel: 'Type',
    supportTypeDeleteWarningText: 'Bent u zeker dat u dit hulptype wil verwijderen?',
    supportTypeDeleteWarningTitle: 'Verwijder hulptype',
    supportTypes: 'Hulptypes',
    updateOrganisationSuccess: 'Organisatie succesvol aangepast.',
    updateOrganisationError: 'Er is een probleem opgetreden bij het aanpassen van de organisatie.',
    updateOrganisationTypeSuccess: 'Organisatietype succesvol aangepast.',
    updateOrganisationTypeError: 'Er is een probleem opgetreden bij het aanpassen van het organisatietype.',
    updateSupportTypeSuccess: 'Hulptype succesvol aangepast.',
    updateSupportTypeError: 'Er is een probleem opgetreden bij het aanpassen van het hulptype.',
    preferredPostalCodes: 'Extra toegestane postcodes voor bedeling',
    addPreferredPostalCode: 'Postcode toevoegen',
    defaultSupportPeriod: 'Standaard duur hulp periode',

    features: 'Features',
    users: 'Gebruikers',
    newUser: 'Nieuwe gebruiker',
    getUsersError: 'Er is een probleem opgetreden bij het ophalen van de gebruikers.',
    status: 'Status',
    pending: 'Inactief',
    enabled: 'Actief',
    emailLabel: 'Email',
    updateUserError: 'Er is een probleem opgetreden bij het aanpassen van de gebruiker.',
    updateUserSuccess: 'Gebruiker successvol aangepast.',
    createUserError: 'Er is een probleem opgetreden bij het aanmaken van de gebruiker.',
    createUserSuccess: 'Gebruiker successvol aangemaakt.',
    emailRequired: 'Email is verplicht',
    invalidEmail: 'Geen geldig mailadres.',

    roles: 'Rollen',
    addUserRoles: 'Voeg rollen toe',
    updateUserRolesError: 'Er is een probleem opgetreden bij het aanpassen van de rollen van de gebruiker.',
    updateUserRolesSuccess: 'Rollen gebruiker successvol aangepast.',
    getUserRolesError: 'Er is een probleem opgetreden bij het ophalen van de rollen van de gebruiker.',
    getRolesError: 'Er is een probleem opgetreden bij het ophalen van de rollen.',
    roleDeleteWarningTitle: 'Verwijder rollen',
    roleDeleteWarningText: 'Bent u zeker dat u de rollen van de gebruiker voor deze organisatie wil verwijderen?',
    deleteUserRolesSuccess: 'Rollen gebruiker successvol verwijdert.',
    deleteUserRolesError: 'Er is een probleem opgetreden bij het verwijderen van de rollen.',
    deleteUserSuccess: 'Gebruiker successvol verwijdert.',
    deleteUserError: 'Er is een probleem opgetreden bij het verwijderen van de gebruiker.',
    userDeleteWarningText: 'Bent u zeker dat u deze gebruiker wil verwijderen?',
    userDeleteWarningTitle: 'Verwijder gebruiker',

    referralOrganisations: 'Doorverwijzers',
    referralOrganisation: 'Doorverwijzer',
    newReferralOrganisation: 'Nieuwe doorverwijzer',
    contacts: 'Contactpersonen',
    phoneLabel: 'Telefoon',
    referralOrganisationDeleteWarningText: 'Bent u zeker dat u deze doorverwijzer wil verwijderen?',
    referralOrganisationDeleteWarningTitle: 'Verwijder doorverwijzer',
    createReferralOrganisationSuccess: 'Doorverwijzer succesvol aangemaakt.',
    createReferralOrganisationError: 'Er is een probleem opgetreden bij het aanmaken van de doorverwijzer.',
    updateReferralOrganisationSuccess: 'Doorverwijzer succesvol aangepast.',
    updateReferralOrganisationError: 'Er is een probleem opgetreden bij het aanpassen van de doorverwijzer.',
    deleteReferralOrganisationSuccess: 'Doorverwijzer succesvol verwijderd',
    deleteReferralOrganisationError: 'Er is een probleem opgetreden bij het verwijderen van de doorverwijzer.',
    organisationSettings: 'Organisatie instellingen',
    commonSettings: 'Algemene instellingen',

    customAttributeConfigurations: 'Attributen',
    defaultSelectedLabel: 'Standaard geselecteerd bij registraties',
    newCustomAttributeConfiguration: 'Nieuw attribuut',
    customAttributeConfigurationDeleteWarningTitle: 'Verwijder attribuut',
    customAttributeConfigurationDeleteWarningText: 'Bent u zeker dat u dit attribuut wil verwijderen?',
    createCustomAttributeConfigurationSuccess: 'Attribuut succesvol aangemaakt.',
    createCustomAttributeConfigurationError: 'Er is een probleem opgetreden bij het aanmaken van het attribuut.',
    updateCustomAttributeConfigurationSuccess: 'Attribuut succesvol aangepast.',
    updateCustomAttributeConfigurationError: 'Er is een probleem opgetreden bij het aanpassen van het attribuut.',
    deleteCustomAttributeConfigurationSuccess: 'Attribuut succesvol verwijderd',
    deleteCustomAttributeConfigurationError: 'Er is een probleem opgetreden bij het verwijderen van het attribuut.',

    supportTypesFrequencies: 'Hulptypes frequenties',
    frequency: 'Frequentie',
    frequencies: 'Frequenties',
    getSupportTypesFrequenciesError:
        'Er is een probleem opgetreden bij het ophalen van het hulptypes met frequencties voor jou organisatie.',
    updateSupportTypesFrequenciesSuccess: 'Hulptype frequenties succesvol aangepast.',
    updateSupportTypesFrequenciesError:
        'Er is een probleem opgetreden bij het aanpassen van het hulptype frequenties .',

    cardSets: 'Kaarten',
    countCards: 'Aantal kaarten',
    newCardSet: 'Nieuwe kaarten',

    incomeTypes: 'Inkomsttypes',
    incomeType: 'Inkomsttype',
    newIncomeType: 'Nieuw inkomsttype',
    editIncomeType: 'Inkomsttype bewerken',
    incomeTypeDeleteWarningTitle: 'Verwijder inkomsttype',
    incomeTypeDeleteWarningText: 'Bent u zeker dat u dit inkomsttype wilt verwijderen?',

    expenseTypes: 'Uitgavetypes',
    expenseType: 'Uitgavetype',
    newExpenseType: 'Nieuw uitgavetype',
    editExpenseType: 'Uitgavetype bewerken',
    expenseTypeDeleteWarningTitle: 'Verwijder uitgavetype',
    expenseTypeDeleteWarningText: 'Bent u zeker dat u dit uitgavetype wilt verwijderen?',
    lumpSum: 'Forfaitair',
    baseLumpSum: 'Basisbedrag',
    startAge: 'Minimum leeftijd',
    amountPerPerson: 'Bedrag per persoon',
    addLumpSumRule: 'Bedrag per persoon toevoegen',

    foodRestrictions: 'Voedselrestricties',
    foodRestriction: 'Voedselrestrictie',
    newFoodRestriction: 'Nieuwe voedselrestrictie',
    editFoodRestriction: 'Wijzig voedselrestrictie',
    foodRestrictionDeleteWarningText: 'Bent u zeker dat u deze voedselrestrictie wil verwijderen?',
    foodRestrictionDeleteWarningTitle: 'Verwijder voedselrestrictie',

    incomeLimits: 'Maximum leefgeld',
    hasCreditLabel: 'Heeft krediet',
    addIncomeLimit: 'Bedrag per persoon toevoegen',
    baseIncomeLimit: 'Basis bedrag',
};
