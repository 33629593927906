import { IOrganisation } from '../../shared';
import { IEditOrganisation } from '../models';

export const organisationToFormMapper = (organisation: IOrganisation): IEditOrganisation => {
    return {
        ...organisation,
        type: organisation.type.id || '',
        supportTypes: organisation.supportTypes.map(({ id }) => id),
    };
};
