import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    ControlledCheckbox,
    FormCard,
    FormCardSectionComponent,
    FormGrid,
    InputTextField,
    IParams,
    ISaveSupportType,
    Page,
} from '../../../shared';
import { IncomeLimitInputs } from '../../components';
import { useGetSupportType, useSaveSupportType } from '../../hooks';
import { useSupportTypeSchema } from '../../validators';

export function SupportTypesEditPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams<keyof IParams>() as IParams;
    const { data } = useGetSupportType(id);

    const form = useForm<ISaveSupportType>({
        resolver: yupResolver(useSupportTypeSchema()),
        mode: 'all',
    });

    useEffect(() => {
        if (data) {
            form.reset(data);
        }
    }, [data, form]);

    const { mutateAsync: saveSupportType } = useSaveSupportType();

    const onSubmit = async (data: ISaveSupportType) => {
        await saveSupportType(data);
        navigate('/admin/support-types');
    };

    return (
        <Page
            title={data?.name || t('newSupportType')}
            actions={[
                <Button onClick={() => navigate('/admin/support-types')} color="secondary">
                    {t('cancel')}
                </Button>,
                <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)}>
                    {t('save')}
                </Button>,
            ]}
            onBack={() => navigate('/admin/support-types')}
        >
            <FormProvider {...form}>
                <FormCard
                    actions={[
                        <Button type="submit" variant="contained" color="primary">
                            {t('save')}
                        </Button>,
                        <Button onClick={() => navigate('/admin/support-types')} color="secondary">
                            {t('cancel')}
                        </Button>,
                    ]}
                    handleSubmit={form.handleSubmit(onSubmit)}
                >
                    <FormCardSectionComponent title={t('data')}>
                        <FormGrid>
                            <InputTextField name="name" label={t('nameLabel')} required sx={{ width: '400px' }} />
                            <ControlledCheckbox name="hasCredit" label={t('hasCreditLabel')} control={form.control} />
                        </FormGrid>
                    </FormCardSectionComponent>
                    <IncomeLimitInputs />
                </FormCard>
            </FormProvider>
        </Page>
    );
}
