import { Box } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    data: { anonymous?: boolean; firstName?: string | null; lastName?: string | null };
}

export const FullName: FC<Props> = ({ data }) => {
    const { t } = useTranslation();

    return (
        <>
            {data.anonymous ? (
                <Box sx={{ fontStyle: 'italic' }} component="span">
                    {t('anonymousLabel')}
                </Box>
            ) : (
                <>
                    {data.lastName} {data.firstName}
                </>
            )}
        </>
    );
};
