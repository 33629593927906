import { DeleteOutline, QrCodeTwoTone } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    ConfirmModal,
    CustomAttributeType,
    formatDateTimeString,
    ICustomAttribute,
    IOrganisation,
    IParams,
    IRegistration,
    ISupportType,
    Page,
    PermissionKeys,
    SortOrder,
    Table,
    useHasPermission,
    useQueryParams,
    useSelectedOrganisation,
    Widget,
} from '../../../shared';
import { DuplicatesWarning, SaveRegistrationModal, SupportCaseTitle } from '../../components';
import { useDeleteRegistration, useGetRegistrations, useGetSupportCase } from '../../hooks';

export const SupportCaseRegistrationsPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams<keyof IParams>() as IParams;
    const [createModal, setCreateModal] = useState(false);

    const { organisation } = useSelectedOrganisation();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.REGISTRATIONS_WRITE);

    const { page, pageSize, setPage, setPageSize, sort, setSort } = useQueryParams({
        defaultSort: [{ field: 'date', sort: 'desc' }],
    });

    const { data: supportCase } = useGetSupportCase(id);
    const { mutateAsync: deleteRegistration } = useDeleteRegistration();
    const { data: registrations, isPending } = useGetRegistrations(
        id,
        {
            page,
            pageSize,
            sortBy: 'date',
            sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
            organisationId: window.carity.environment.shareSupportCases ? undefined : organisation?.id,
        },
        !!id && (window.carity.environment.shareSupportCases || !!organisation?.id),
    );

    const columns: GridColDef[] = [
        {
            field: 'date',
            headerName: t('date'),
            width: 140,
            valueFormatter: (_, row) => formatDateTimeString(row.dateOfRegistration),
        },
        {
            field: 'supportType',
            headerName: t('supportType'),
            sortable: false,
            minWidth: 150,
            flex: 1,
            valueFormatter: (value: ISupportType) => value?.name,
        },
        {
            field: 'organisation',
            headerName: t('organisation'),
            sortable: false,
            minWidth: 150,
            flex: 1,
            valueFormatter: (value: IOrganisation) => value?.name,
        },
        {
            field: 'createdBy',
            headerName: t('createdBy'),
            sortable: false,
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'attributes',
            headerName: t('attributes'),
            sortable: false,
            minWidth: 200,
            flex: 2,
            renderCell: ({ row: { attributes } }: GridCellParams<IRegistration>) =>
                attributes
                    ?.filter((attribute: ICustomAttribute) => attribute.booleanValue || attribute.amountValue)
                    .map((attribute: ICustomAttribute) =>
                        attribute.type === CustomAttributeType.AMOUNT
                            ? `${attribute.amountValue} x ${attribute.name}`
                            : attribute.name,
                    )
                    .join(', '),
        },
        { field: 'comment', headerName: t('comment'), sortable: false, minWidth: 200, flex: 2 },
        ...(hasWritePermission
            ? ([
                  {
                      field: 'edit',
                      headerName: ' ',
                      sortable: false,
                      minWidth: 100,
                      align: 'right',
                      flex: 0.5,
                      renderCell: ({ row }: GridCellParams<IRegistration>) => (
                          <>
                              <ConfirmModal
                                  onConfirm={() =>
                                      row.id &&
                                      deleteRegistration({
                                          supportCaseId: id,
                                          registrationId: row.id,
                                      })
                                  }
                                  button={
                                      <IconButton aria-label="delete" size="large" data-testid={`delete-${id}`}>
                                          <DeleteOutline />
                                      </IconButton>
                                  }
                                  title={t('registrationDeleteWaringTitle')}
                                  text={
                                      <>
                                          {t('registrationDeleteWaringText')}
                                          <span style={{ fontWeight: 'bold' }}>
                                              {`${formatDateTimeString(row.dateOfRegistration)} - ${row.organisation?.name} - ${row.supportType?.name}`}
                                          </span>
                                      </>
                                  }
                              />
                          </>
                      ),
                  },
              ] as GridColDef[])
            : ([] as GridColDef[])),
    ];

    return (
        <Page onBack={() => navigate(-1)} title={supportCase && <SupportCaseTitle supportCase={supportCase} />}>
            <DuplicatesWarning supportCaseId={id} />
            <Widget
                icon={<QrCodeTwoTone color="primary" />}
                title={t('registrations')}
                hasTable
                actions={
                    <Button
                        variant="outlined"
                        color="primary"
                        data-testid="add-registration"
                        onClick={() => setCreateModal(true)}
                    >
                        + {t('addRegistration')}
                    </Button>
                }
            >
                <Table
                    rows={registrations?.data || []}
                    columns={columns}
                    rowCount={registrations?.pagination.size || 0}
                    page={page}
                    pageSize={pageSize}
                    setPage={setPage}
                    setPageSize={setPageSize}
                    loading={isPending}
                    sortModel={sort}
                    onSortChange={setSort}
                />
            </Widget>
            <SaveRegistrationModal show={createModal} supportCase={id} handleClose={() => setCreateModal(false)} />
        </Page>
    );
};
