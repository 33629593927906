import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AUTH_API_BASE_URL, queryKeys } from '../../../shared';

async function deleteUser(id: string, onDeleteSuccessMessage?: string, onDeleteErrorMessage?: string): Promise<void> {
    await axios.delete(`${AUTH_API_BASE_URL}/v1/users/${id}`, {
        successMessage: onDeleteSuccessMessage,
        errorMessage: onDeleteErrorMessage,
    });
}

export const useDeleteUser = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation<void, AxiosError, string>({
        mutationFn: (id) => deleteUser(id, t('deleteUserSuccess'), t('deleteUserError')),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [queryKeys.USERS] });
        },
    });
};
