import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { ISupportCase } from '../../../shared';
import { queryKeys } from '../../constants';

async function getSupportCase(id: string, onGetErrorMessage?: string): Promise<ISupportCase> {
    const { data } = await axios.get(`/support-api/support-cases/${id}`, { errorMessage: onGetErrorMessage });
    return data;
}

export const useGetSupportCase = (
    id: string,
    options?: Omit<UseQueryOptions<ISupportCase, AxiosError>, 'queryKey'>,
) => {
    const { t } = useTranslation();

    return useQuery<ISupportCase, AxiosError>({
        queryKey: [queryKeys.SUPPORT_CASE, id],
        queryFn: () => getSupportCase(id, t('getSupportCaseError')),
        enabled: !!id,
        ...options,
    });
};
