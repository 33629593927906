import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IReferralOrganisation, ISaveReferralOrganisation, queryKeys } from '../../../shared';

async function saveReferralOrganisation(
    organisation: ISaveReferralOrganisation,
    onUpdateSuccessMessage?: string,
    onUpdateErrorMessage?: string,
    onCreateSuccessMessage?: string,
    onCreateErrorMessage?: string,
): Promise<IReferralOrganisation> {
    let result;
    if (organisation.id) {
        result = await axios.put(`/support-api/referral-organisations/${organisation.id}`, organisation, {
            successMessage: onUpdateSuccessMessage,
            errorMessage: onUpdateErrorMessage,
        });
    } else {
        result = await axios.post('/support-api/referral-organisations', organisation, {
            successMessage: onCreateSuccessMessage,
            errorMessage: onCreateErrorMessage,
        });
    }

    return result.data;
}

export const useSaveReferralOrganisation = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation<IReferralOrganisation, AxiosError, ISaveReferralOrganisation>({
        mutationFn: (data) =>
            saveReferralOrganisation(
                data,
                t('updateReferralOrganisationSuccess'),
                t('updateReferralOrganisationError'),
                t('createReferralOrganisationSuccess'),
                t('createReferralOrganisationError'),
            ),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [queryKeys.REFERRAL_ORGANISATIONS] });
        },
    });
};
