import axios, { AxiosError, AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AUTH_API_BASE_URL, IUser, queryKeys } from '../../../shared';

async function saveUser(
    { id, ...user }: IUser,
    messages: {
        onUpdateSuccessMessage?: string;
        onUpdateErrorMessage?: string;
        onCreateSuccessMessage?: string;
        onCreateErrorMessage?: string;
    },
): Promise<IUser> {
    let result: AxiosResponse<IUser>;

    if (id) {
        result = await axios.put(`${AUTH_API_BASE_URL}/v1/users/${id}`, user, {
            successMessage: messages.onUpdateSuccessMessage,
            errorMessage: messages.onUpdateErrorMessage,
        });
    } else {
        result = await axios.post(`${AUTH_API_BASE_URL}/v1/users`, user, {
            successMessage: messages.onCreateSuccessMessage,
            errorMessage: messages.onCreateErrorMessage,
        });
    }

    return result.data;
}

export const useSaveUser = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation<IUser, AxiosError, IUser>({
        mutationFn: (data) =>
            saveUser(data, {
                onCreateSuccessMessage: t('createUserSuccess'),
                onCreateErrorMessage: t('createUserError'),
                onUpdateSuccessMessage: t('updateUserSuccess'),
                onUpdateErrorMessage: t('updateUserError'),
            }),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [queryKeys.USERS] });
        },
    });
};
