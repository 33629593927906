import axios, { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { IReferralOrganisation, ListModel, ListQueryParam, queryKeys } from '../../../shared';

async function getReferralOrganisations(
    params: ListQueryParam,
    onGetErrorMessage?: string,
): Promise<ListModel<IReferralOrganisation>> {
    const { data } = await axios.get(`/support-api/referral-organisations`, {
        params,
        errorMessage: onGetErrorMessage,
    });

    return data;
}

export const useGetReferralOrganisations = (params: ListQueryParam) => {
    return useQuery<ListModel<IReferralOrganisation>, AxiosError>({
        queryKey: [queryKeys.REFERRAL_ORGANISATIONS, params],
        queryFn: () => getReferralOrganisations(params),
        enabled: params.pageSize == undefined || params.pageSize > 0,
    });
};
