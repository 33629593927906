import { Button } from '@mui/material';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConfirmModal } from '../../../shared';
import { useMergeSupportCase } from '../../hooks';

interface Props {
    subjectId: string;
    targetId: string;
    onClose: () => void;
}

export const MergeSupportCases: FC<Props> = ({ subjectId, targetId, onClose }) => {
    const { t } = useTranslation();
    const { mutateAsync: merge } = useMergeSupportCase();
    const location = useLocation();
    const navigate = useNavigate();

    const onMerge = useCallback(async () => {
        await merge({ subjectId: subjectId, targetId: targetId });

        onClose();

        if (location.pathname === '/registration/info') {
            navigate(`/registration/info?case=${targetId}`);
        } else {
            navigate(`/support/support-cases/${targetId}/detail`);
        }
    }, [navigate, subjectId, targetId]);

    return (
        <ConfirmModal
            button={
                <Button variant="outlined" sx={{ width: '100%', mx: 0.5 }} size="small">
                    {t('mergeAndKeepInfo')}
                </Button>
            }
            title={t('merge')}
            text={t('mergeConfirmation')}
            onConfirm={onMerge}
        />
    );
};
