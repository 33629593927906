import WarningIcon from '@mui/icons-material/Warning';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid } from '@mui/material';
import React, { FC, ReactElement, ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogTitleWithClose } from '../dialog-title-with-close/dialog-title-with-close.component';

interface Props {
    button: ReactElement;
    title: string;
    text: string | ReactNode;
    onConfirm: () => void;
}

export const ConfirmModal: FC<Props> = (props) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const button = React.cloneElement(props.button, {
        onClick: (event: MouseEvent) => {
            event.stopPropagation();
            setIsOpen(true);
        },
    });

    const onConfirm = useCallback(() => {
        setIsOpen(false);
        props.onConfirm();
    }, [props]);

    const onCancel = useCallback(() => setIsOpen(false), []);

    return (
        <>
            {button}
            <Dialog open={isOpen}>
                <DialogTitleWithClose onClose={onCancel}>
                    <Grid container direction="row" alignItems="center">
                        <WarningIcon color="secondary" style={{ marginRight: '0.5em' }} />
                        {props.title}
                    </Grid>
                </DialogTitleWithClose>
                <DialogContent>
                    <DialogContentText>{props.text}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onConfirm} color="secondary" autoFocus variant="contained" aria-label="confirm">
                        {t('confirm')}
                    </Button>
                    <Button onClick={onCancel} color="secondary">
                        {t('cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
