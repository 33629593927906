import { Alert, AlertTitle, Button } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IOrganisation, PermissionKeys, useHasPermission } from '../../../shared';
import { useConfirmSupportCase } from '../../hooks';
import { ISupportCaseInfo } from '../../models';
import { InfoSupportees } from '../info-supportees/info-supportees.component';

interface Props {
    supportCase: ISupportCaseInfo;
    organisation: IOrganisation;
}

export const ConfirmSupportCase: FC<Props> = ({ supportCase, organisation }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { mutateAsync: confirmSupportCase, isPending } = useConfirmSupportCase();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.SUPPORT_CASES_WRITE);

    const onClick = useCallback(async () => {
        await confirmSupportCase({
            supportCaseId: supportCase.supportCaseId,
            organisationId: organisation.id,
        });
        navigate(`/registration/info?case=${supportCase.supportCaseId}`);
    }, [supportCase, organisation, confirmSupportCase]);

    return (
        <>
            <Alert sx={{ mb: 2 }} severity="info">
                <AlertTitle>{t('unconfirmedSupportCase')}</AlertTitle>
                {t('unconfirmedSupportCaseWarning')}
            </Alert>
            {!hasWritePermission && (
                <Alert sx={{ mb: 2 }} severity="warning">
                    <AlertTitle>{t('noPermissionsToConfirm')}</AlertTitle>
                    {t('noPermissionsToConfirmWarning')}
                </Alert>
            )}
            <InfoSupportees
                supportCase={supportCase}
                footer={
                    <>
                        <Button
                            variant="contained"
                            onClick={onClick}
                            disabled={isPending || !hasWritePermission}
                            data-testid="back-btn"
                        >
                            {t('confirm')}
                        </Button>
                    </>
                }
            />
        </>
    );
};
