import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetSupportCase } from '../../hooks';
import { ISupportCaseDuplicate } from '../../models';
import { DuplicateRow } from '../duplicate-row/duplicate-row.component';

interface Props {
    supportCaseId: string;
    duplicates: ISupportCaseDuplicate[];
    onClose: () => void;
}

export const Duplicates: FC<Props> = ({ supportCaseId, duplicates, onClose }) => {
    const { t } = useTranslation();
    const { data: supportCase } = useGetSupportCase(supportCaseId);

    return (
        <TableContainer sx={{ border: 1, borderColor: 'rgb(224, 224, 224)', borderRadius: 1 }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>{t('caseNumberLabel')}</TableCell>
                        <TableCell>{t('primarySupportee')}</TableCell>
                        <TableCell>{t('organisations')}</TableCell>
                        <TableCell>{t('reason')}</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {supportCase &&
                        duplicates.map((row) => (
                            <Fragment key={row.id}>
                                <DuplicateRow supportCase={supportCase} duplicate={row} onClose={onClose} />
                            </Fragment>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
