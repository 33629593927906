import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { DialogTitleWithClose, InputTextField, StatusCode } from '../../../shared';
import { useCreateCard, useLinkCardSupportCase } from '../../hooks';

interface Props {
    open: boolean;
    supportCase: string;
    handleClose: () => void;
}

enum LinkCardOption {
    NEW_CARD = 'new',
    EXISTING_CARD = 'existing',
}

const schema = yup.object().shape({
    action: yup.string(),
    code: yup
        .string()
        .when('action', ([action], schema) => (action === LinkCardOption.EXISTING_CARD ? schema.required() : schema)),
});

export const LinkCardModal: FC<Props> = ({ open, supportCase, handleClose }) => {
    const { t } = useTranslation();

    const { control, handleSubmit, watch, setError, setValue } = useForm({ resolver: yupResolver(schema) });
    const watchAction = watch('action');

    useEffect(() => {
        const option = window.carity.environment.defaultLinkCardOption;
        const defaultOption = Object.values(LinkCardOption).includes(option as LinkCardOption) ? option : '';
        setValue('action', defaultOption);
    }, [open]);

    const { mutateAsync: createCard } = useCreateCard();
    const { mutateAsync: linkCardToSupportCase } = useLinkCardSupportCase();

    const onSubmit = async (data: any /*{ action: string; code: string }*/) => {
        try {
            if (data.action == LinkCardOption.NEW_CARD) {
                await createCard(supportCase);
            } else {
                await linkCardToSupportCase({ supportCase: supportCase, cardCode: data.code });
            }
            handleClose();
        } catch (e: any) {
            if (e.response.status === StatusCode.NOT_FOUND) {
                setError('code', { message: t('codeNotFoundError') });
            } else if (e.response.status === StatusCode.CONFLICT) {
                setError('code', { message: t('codeConflictError') });
            }
        }
    };

    return (
        <Dialog open={open} maxWidth="lg">
            <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
                <DialogTitleWithClose onClose={handleClose}>{t('linkCard')}</DialogTitleWithClose>
                <DialogContent>
                    <Grid container direction="column" spacing={3}>
                        <Grid item>
                            <Controller
                                name="action"
                                control={control}
                                defaultValue={LinkCardOption.EXISTING_CARD}
                                render={({ field: { onChange, value } }) => (
                                    <RadioGroup value={value} onChange={onChange}>
                                        <FormControlLabel
                                            value={LinkCardOption.EXISTING_CARD}
                                            control={<Radio />}
                                            label={`${t('existingCard')}`}
                                            data-testid="radio-existing"
                                        />
                                        <FormControlLabel
                                            value={LinkCardOption.NEW_CARD}
                                            control={<Radio />}
                                            label={`${t('newCard')}`}
                                            data-testid="radio-new"
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </Grid>
                        {watchAction === LinkCardOption.EXISTING_CARD && (
                            <Grid item>
                                <InputTextField
                                    name="code"
                                    control={control}
                                    label={`${t('code')}`}
                                    required
                                    autoFocus
                                />
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" variant="contained" data-testid="confirm" disabled={watchAction === ''}>
                        {t('linkCard')}
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        {t('cancel')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
