import { useMemo } from 'react';
import { array } from 'yup';
import { baseSchema, referrerContactSchema } from '../../shared';

export function useReferralOrganisationSchema() {
    return useMemo(
        () =>
            baseSchema().shape({
                contacts: array().of(referrerContactSchema()).required(),
            }),
        [],
    );
}
