import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ISupportPeriod } from '../../../shared';
import { queryKeys } from '../../constants';

async function deleteSupportPeriod(
    caseId: string,
    id: string,
    onDeleteSuccessMessage?: string,
    onDeleteErrorMessage?: string,
): Promise<ISupportPeriod> {
    const { data } = await axios.delete(`/support-api/support-cases/${caseId}/support-periods/${id}`, {
        successMessage: onDeleteSuccessMessage,
        errorMessage: onDeleteErrorMessage,
    });
    return data;
}

export const useDeleteSupportPeriod = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation<ISupportPeriod, AxiosError, { supportCase: string; supportPeriod: string }>({
        mutationFn: (data) =>
            deleteSupportPeriod(
                data.supportCase,
                data.supportPeriod,
                t('deleteSupportPeriodSuccess'),
                t('deleteSupportPeriodError'),
            ),

        onSuccess: async (data, variables) => {
            await queryClient.invalidateQueries({ queryKey: [variables.supportCase, queryKeys.SUPPORT_PERIODS] });
        },
    });
};
