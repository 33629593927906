import { CalendarTodayTwoTone } from '@mui/icons-material';
import { Alert, List, ListItem, ListItemText } from '@mui/material';
import { TFunction } from 'i18next';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateString, ISupportPeriod, Widget } from '../../../shared';

interface Props {
    supportPeriods: ISupportPeriod[];
}

export const InfoActiveSupportPeriods: FC<Props> = ({ supportPeriods }) => {
    const { t } = useTranslation();

    return (
        <Widget icon={<CalendarTodayTwoTone color="primary" />} title={t('activeSupportPeriods')}>
            {supportPeriods?.length === 0 ? (
                <Alert severity="error">{t('emptyStateActiveSupportPeriods')}</Alert>
            ) : (
                <List dense data-testid="support-periods">
                    {supportPeriods.map((period) => (
                        <ListItem key={period.id}>
                            <ListItemText
                                primary={formatSupportPeriod(period, t)}
                                secondary={`${formatPeriodDates(period)}`}
                            />
                        </ListItem>
                    ))}
                </List>
            )}
        </Widget>
    );
};

function formatSupportPeriod(period: ISupportPeriod, t: TFunction): string {
    return formatPeriodSupportTypes(period) + formatOrganisation(period) + formatFrequency(period, t);
}

function formatPeriodDates(period: ISupportPeriod): string {
    return `${formatDateString(period.startDate)} - ${formatDateString(period.endDate)}`;
}

function formatPeriodSupportTypes(period: ISupportPeriod): string {
    return period.supportTypesInfo.map(({ supportType }) => supportType?.name).join(', ');
}

function formatOrganisation(period: ISupportPeriod): string {
    return period.organisation ? ` - ${period.organisation?.name}` : '';
}

function formatFrequency(period: ISupportPeriod, t: TFunction): string {
    return period.frequency ? ` (${t(period.frequency)})` : '';
}
