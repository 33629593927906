import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ISupportCaseFoodRestriction, ISupportCaseFoodRestrictions } from '../../../shared';
import { queryKeys } from '../../constants';

async function updateFoodRestrictions(
    supportCaseId: string,
    data: ISupportCaseFoodRestrictions,
    onUpdateSuccessMessage?: string,
    onUpdateErrorMessage?: string,
): Promise<ISupportCaseFoodRestriction[]> {
    const result = await axios.put(`/support-api/support-cases/${supportCaseId}/food-restrictions`, data, {
        successMessage: onUpdateSuccessMessage,
        errorMessage: onUpdateErrorMessage,
    });
    return result.data;
}

export const useUpdateFoodRestrictions = (supportCaseId: string) => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation<ISupportCaseFoodRestriction[], AxiosError, ISupportCaseFoodRestrictions>({
        mutationFn: (data) =>
            updateFoodRestrictions(
                supportCaseId,
                data,
                t('updateFoodRestrictionsSuccess'),
                t('updateFoodRestrictionsError'),
            ),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [queryKeys.SUPPORT_CASE, supportCaseId] });
        },
    });
};
