import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { queryKeys } from '../../constants';
import { IComment } from '../../models';

async function saveComment(
    supportCase: string,
    comment: IComment,
    onCreateErrorMessage: string,
    onUpdateErrorMessage: string,
): Promise<IComment> {
    let result;
    if (comment.id) {
        result = await axios.put(`/support-api/support-cases/${supportCase}/comments/${comment.id}`, comment, {
            errorMessage: onUpdateErrorMessage,
        });
    } else {
        result = await axios.post(`/support-api/support-cases/${supportCase}/comments`, comment, {
            errorMessage: onCreateErrorMessage,
        });
    }

    return result.data;
}

export const useSaveComment = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation<IComment, AxiosError, { supportCase: string; comment: IComment }>({
        mutationFn: (data) =>
            saveComment(data.supportCase, data.comment, t('createCommentError'), t('updateCommentError')),

        onSuccess: async (data, variables) => {
            await queryClient.invalidateQueries({ queryKey: [queryKeys.COMMENTS, variables.supportCase] });
        },
    });
};
