import { Button } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from '../../../shared';
import { CardSetsTable, CreateCardSet } from '../../components';

export const CardSetsPage: FC = () => {
    const { t } = useTranslation();
    const [showCreateCardSet, setShowCreateCardSet] = useState(false);

    return (
        <Page
            title={t('cardSets')}
            actions={
                <Button color="primary" variant="contained" onClick={() => setShowCreateCardSet(true)}>
                    {t('newCardSet')}
                </Button>
            }
        >
            <CardSetsTable />
            <CreateCardSet show={showCreateCardSet} handleClose={() => setShowCreateCardSet(false)} />
        </Page>
    );
};
