import { alpha, Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelectedOrganisation } from '../../contexts';

export const SelectOrganisation: FC = () => {
    const { t } = useTranslation();
    const { organisation, organisations, setOrganisation } = useSelectedOrganisation();

    const handleChange = useCallback(
        (e: any) => setOrganisation(organisations.find(({ id }) => id === e.target.value)),
        [setOrganisation, organisations],
    );

    return organisations?.length > 1 ? (
        <FormControl
            variant="filled"
            sx={{
                margin: '0 8px',
                width: 250,
            }}
        >
            <InputLabel
                sx={{
                    color: (theme) => theme.palette.grey['400'],
                    '&.Mui-focused': { color: (theme) => theme.palette.grey['400'] },
                }}
            >
                {t('organisation')}
            </InputLabel>
            <Box
                sx={{
                    borderRadius: 0,
                    borderBottomWidth: 0,
                    backgroundColor: (theme) => alpha(theme.palette.grey['400'], 0.15),
                    '&:hover': {
                        backgroundColor: (theme) => alpha(theme.palette.grey['400'], 0.25),
                    },
                    '&.Mui-focused': {
                        backgroundColor: (theme) => alpha(theme.palette.grey['400'], 0.15),
                        '&:hover': {
                            backgroundColor: (theme) => alpha(theme.palette.grey['400'], 0.25),
                        },
                    },
                    color: 'grey',
                    '& .MuiSvgIcon-root': {
                        color: 'grey',
                    },
                    '&:before': {
                        borderBottom: 'none !important',
                    },
                    '&:after': {
                        borderBottom: 'none',
                    },
                }}
                component={Select}
                value={organisation?.id || ''}
                onChange={handleChange}
                id={'select-organisation'}
            >
                {organisations.map(({ name, id }) => (
                    <MenuItem key={id} value={id}>
                        {name}
                    </MenuItem>
                ))}
            </Box>
        </FormControl>
    ) : null;
};
