import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { array, boolean, number, object, string } from 'yup';

export function useSupportTypeSchema() {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                name: string().required(),
                hasCredit: boolean().required(),
                baseIncomeLimit: number().typeError(t('invalidNumber')).required(),
                incomeLimits: array()
                    .of(
                        object().shape({
                            startAge: number().typeError(t('invalidNumber')).required(),
                            amountPerPerson: number().typeError(t('invalidNumber')).required(),
                        }),
                    )
                    .required(),
            }),
        [t],
    );
}
