import { useTranslation } from 'react-i18next';
import { SupporteeRelation } from '../../enums';

export const useRelations = () => {
    const { t } = useTranslation();
    return Object.values(SupporteeRelation).map((value) => ({
        value: value,
        translation: t(value.toLowerCase()),
    }));
};
