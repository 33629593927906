import { useMemo } from 'react';
import { string } from 'yup';
import { addressWithCountrySchema } from '../../shared';

export function useResidenceSchema() {
    return useMemo(
        () =>
            addressWithCountrySchema().shape({
                livingSituation: string().optional(),
                familySituation: string().optional(),
            }),
        [],
    );
}
