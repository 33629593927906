import { queryKeys, useDelete, useItem, UseItemParams, useList, UseListParams, useSave } from '../../../shared';
import { foodRestrictionClient } from '../../clients';
import { IFoodRestriction } from '../../models';

const client = foodRestrictionClient;
const listKey = queryKeys.FOOD_RESTRICTIONS;
const itemKey = queryKeys.FOOD_RESTRICTION;

export function useFoodRestrictionsList(...args: UseListParams<IFoodRestriction>) {
    return useList(client, listKey, ...args);
}

export function useFoodRestriction(...args: UseItemParams<IFoodRestriction>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveFoodRestriction() {
    return useSave(client, listKey, itemKey);
}

export function useDeleteFoodRestriction() {
    return useDelete(client, listKey);
}
