import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { IOrganisationType, ListModel, ListQueryParam, queryKeys } from '../../../shared';

async function getOrganisationTypes(
    params: ListQueryParam,
    onGetErrorMessage?: string,
): Promise<ListModel<IOrganisationType>> {
    const { data } = await axios.get('/support-api/organisation-types', {
        params,
        errorMessage: onGetErrorMessage,
    });
    return data;
}

export const useGetOrganisationTypes = (params: ListQueryParam) => {
    const { t } = useTranslation();

    return useQuery<ListModel<IOrganisationType>, AxiosError>({
        queryKey: [queryKeys.ORGANISATION_TYPES, params],
        queryFn: () => getOrganisationTypes(params, t('getOrganisationTypesError')),
    });
};
