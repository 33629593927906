import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { IOrganisation, queryKeys } from '../../../shared';
import { IEditOrganisation } from '../../models';

async function saveOrganisation(
    organisation: IEditOrganisation,
    onUpdateSuccessMessage?: string,
    onUpdateErrorMessage?: string,
    onCreateSuccessMessage?: string,
    onCreateErrorMessage?: string,
): Promise<IOrganisation> {
    let result;
    if (organisation.id) {
        result = await axios.put(`/support-api/organisations/${organisation.id}`, organisation, {
            successMessage: onUpdateSuccessMessage,
            errorMessage: onUpdateErrorMessage,
        });
    } else {
        result = await axios.post('/support-api/organisations', organisation, {
            successMessage: onCreateSuccessMessage,
            errorMessage: onCreateErrorMessage,
        });
    }

    return result.data;
}

export const useSaveOrganisation = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation<IOrganisation, AxiosError, IEditOrganisation>({
        mutationFn: (data) =>
            saveOrganisation(
                data,
                t('updateOrganisationSuccess'),
                t('updateOrganisationError'),
                t('createOrganisationSuccess'),
                t('createOrganisationError'),
            ),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [queryKeys.ORGANISATIONS] });
        },
    });
};
