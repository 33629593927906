import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, ToggleButton } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputTextField, ToggleButtonGroupComponent, useSelectedOrganisation } from '../../../shared';
import { useSaveComment } from '../../hooks';
import { IComment, ICommentForm } from '../../models';
import { useCommentSchema } from '../../validators';

interface Props {
    supportCase: string;
    comment?: IComment;
    handleClose?: () => void;
}

export const SaveCommentComponent: FC<Props> = ({ supportCase, comment, handleClose }) => {
    const { t } = useTranslation();
    const { organisation } = useSelectedOrganisation();

    const { control, handleSubmit, watch, reset } = useForm<ICommentForm>({
        resolver: yupResolver(useCommentSchema()),
    });
    const watchMessage = watch('message');

    useEffect(() => {
        if (comment) {
            reset({ ...comment, type: comment.external ? 'external' : 'internal' });
        } else {
            reset({ message: '', type: 'internal' });
        }
    }, [comment]);

    const { mutateAsync: saveComment } = useSaveComment();

    const onSubmit = async (data: ICommentForm) => {
        if (organisation) {
            if (comment) {
                await saveComment({
                    supportCase: supportCase,
                    comment: {
                        ...comment,
                        message: data.message,
                        external: data.type === 'external',
                    },
                });
            } else {
                await saveComment({
                    supportCase: supportCase,
                    comment: {
                        ...data,
                        external: data.type === 'external',
                        timestamp: new Date().toISOString(),
                        organisationId: organisation.id,
                    },
                });
                reset({ message: '', type: data.type });
            }
            if (handleClose) {
                handleClose();
            }
        }
    };

    const onCancel = () => {
        if (comment) {
            reset({ ...comment, type: external ? 'external' : 'internal' });
        } else {
            reset({ message: '', type: 'internal' });
        }
        if (handleClose) {
            handleClose();
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <ToggleButtonGroupComponent
                        name={'type'}
                        control={control}
                        defaultValue={'internal'}
                        exclusive={true}
                    >
                        <ToggleButton value={'internal'}>{t('internal')}</ToggleButton>
                        <ToggleButton value={'external'}>{t('external')}</ToggleButton>
                    </ToggleButtonGroupComponent>
                </Grid>
                <Grid item>
                    <InputTextField
                        control={control}
                        name="message"
                        label={t('moreInfo')}
                        multiline={true}
                        fullWidth={true}
                    />
                </Grid>
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button type="submit" variant="contained" disabled={watchMessage === ''}>
                                {t('save')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" onClick={onCancel}>
                                {t('cancel')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};
