import { PeopleTwoTone } from '@mui/icons-material';
import { Alert, List, ListItem, ListItemText, Typography } from '@mui/material';
import React, { FC, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelectedOrganisation, Widget } from '../../../shared';
import { IInfoSupportee, ISupportCaseInfo } from '../../models';

interface Props {
    supportCase: ISupportCaseInfo;
    footer?: ReactNode;
}

export const InfoSupportees: FC<Props> = ({ supportCase, footer }) => {
    const { t } = useTranslation();
    const { organisation } = useSelectedOrganisation();

    const adults = supportCase.supportees
        .filter((supportee) => supportee.age >= 18)
        ?.sort((a) => (a.isPrimary ? -1 : 1));
    const children = supportCase.supportees.filter((supportee) => supportee.age < 18 && supportee.age != null);
    const unknown = supportCase.supportees.filter((supportee) => supportee.age === null);

    const formatSupportee = (supportee: IInfoSupportee) => {
        return supportee.firstName
            ? `${supportee.firstName} ${supportee.lastName} (${supportee.age} ${t('yearOld')})`
            : `${supportee.age} ${t('yearOld')}`;
    };

    const organisationHasAccess = useMemo(
        () =>
            window.carity.environment.shareSupportCases ||
            supportCase?.isUnconfirmed ||
            supportCase?.organisations.find((org) => org.id === organisation?.id),
        [supportCase, organisation],
    );

    return (
        <Widget
            icon={<PeopleTwoTone color="primary" />}
            title={
                <>
                    {t('supportees')} ({supportCase.supportees.length})
                </>
            }
            footer={footer}
        >
            {!organisationHasAccess && <Alert severity="warning">{t('unknownSupportCaseWarning')}</Alert>}
            <List dense data-testid="supportees" sx={{ fontSize: '14px' }}>
                <ListItem>
                    <ListItemText
                        primary={t('adults')}
                        secondary={
                            <>
                                {adults.length === 0
                                    ? '/'
                                    : adults.map((supportee, index) => (
                                          <Typography
                                              component="span"
                                              sx={{ fontWeight: supportee.isPrimary ? 'bold' : 'normal' }}
                                              variant={'body2'}
                                              key={supportee.id}
                                          >
                                              {formatSupportee(supportee)}
                                              {index + 1 !== adults.length && ', '}
                                          </Typography>
                                      ))}
                            </>
                        }
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={t('children')}
                        secondary={
                            <>
                                {children.length === 0
                                    ? '/'
                                    : children.map((supportee, index) => (
                                          <Typography
                                              component="span"
                                              sx={{ fontWeight: supportee.isPrimary ? 'bold' : 'normal' }}
                                              variant={'body2'}
                                              key={supportee.id}
                                          >
                                              {formatSupportee(supportee)}
                                              {index + 1 !== children.length && ', '}
                                          </Typography>
                                      ))}
                            </>
                        }
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={t('ageUnknown')}
                        secondary={
                            <>
                                {unknown.length === 0
                                    ? '/'
                                    : unknown.map((supportee, index) => (
                                          <Typography
                                              component="span"
                                              sx={{ fontWeight: supportee.isPrimary ? 'bold' : 'normal' }}
                                              variant={'body2'}
                                              key={supportee.id}
                                          >
                                              {supportee.firstName
                                                  ? `${supportee.firstName} ${supportee.lastName}`
                                                  : `${t('anonymousLabel')}`}
                                              {index + 1 !== unknown.length && ', '}
                                          </Typography>
                                      ))}
                            </>
                        }
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={t('familySituation')}
                        secondary={
                            supportCase.familySituation
                                ? t(supportCase.familySituation)
                                : t('emptyStateFamilySituation')
                        }
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={t('livingSituation')}
                        secondary={
                            supportCase.livingSituation
                                ? t(supportCase.livingSituation)
                                : t('emptyStateLivingSituation')
                        }
                    />
                </ListItem>
            </List>
        </Widget>
    );
};
