import { createContext, FC, ReactNode, useContext, useState } from 'react';

interface IDrawerContext {
    showDrawer: boolean;
    setShowDrawer: (show: boolean) => void;
    hasDrawer: boolean;
    setHasDrawer: (hasDrawer: boolean) => void;
}

interface Props {
    children?: ReactNode;
}

const DrawerContext = createContext<IDrawerContext>({
    showDrawer: false,
    // eslint-disable-next-line
    setShowDrawer: () => {},
    hasDrawer: false,
    // eslint-disable-next-line
    setHasDrawer: () => {},
});

export const DrawerContextProvider: FC<Props> = ({ children }) => {
    const [showDrawer, setShowDrawer] = useState(false);
    const [hasDrawer, setHasDrawer] = useState(false);

    return (
        <DrawerContext.Provider value={{ showDrawer, setShowDrawer, hasDrawer, setHasDrawer }}>
            {children}
        </DrawerContext.Provider>
    );
};

export const useShowDrawer = (): IDrawerContext => useContext(DrawerContext);
