import { CssBaseline, GlobalStyles, StyledEngineProvider, ThemeProvider } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import { useTheme } from '../../hooks';
import { getGlobalStyles } from '../../utils/global-styles.util';

interface Props {
    children?: ReactNode;
}

export const CarityTheme: FC<Props> = ({ children }) => {
    const theme = useTheme();

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <GlobalStyles styles={getGlobalStyles(theme.palette)} />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
};
