import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ISaveSupportType, ISupportType, queryKeys } from '../../../shared';

async function saveSupportType(
    supportType: ISaveSupportType,
    onUpdateSuccessMessage?: string,
    onUpdateErrorMessage?: string,
    onCreateSuccessMessage?: string,
    onCreateErrorMessage?: string,
): Promise<ISupportType> {
    let result;
    if (supportType.id) {
        result = await axios.put(`/support-api/support-types/${supportType.id}`, supportType, {
            successMessage: onUpdateSuccessMessage,
            errorMessage: onUpdateErrorMessage,
        });
    } else {
        result = await axios.post(`/support-api/support-types`, supportType, {
            successMessage: onCreateSuccessMessage,
            errorMessage: onCreateErrorMessage,
        });
    }
    return result.data;
}

export const useSaveSupportType = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation<ISupportType, AxiosError, ISaveSupportType>({
        mutationFn: (data) =>
            saveSupportType(
                data,
                t('updateSupportTypeSuccess'),
                t('updateSupportTypeError'),
                t('createSupportTypeSuccess'),
                t('createSupportTypeError'),
            ),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [queryKeys.SUPPORT_TYPES] });
        },
    });
};
