import * as countries from 'i18n-iso-countries';
import { IResidenceForm, IUpdateResidence } from '../../shared';
import { CountryOptions } from '../models';
import { FamilySituation, LivingSituation } from '../enums';

export const residenceFromFormMapper = (residence: IResidenceForm): IUpdateResidence => {
    return {
        ...residence,
        street: residence.street || '',
        number: residence.number || '',
        postalCode: residence.postalCode || '',
        city: residence.city || '',
        country: residence.country?.code || '',
        livingSituation: (residence?.livingSituation as LivingSituation) || null,
        familySituation: (residence?.familySituation as FamilySituation) || null,
    };
};

export const residenceToFormMapper = (data: IUpdateResidence, language: string): IResidenceForm => {
    return {
        ...data,
        livingSituation: data.livingSituation ? data.livingSituation : undefined,
        familySituation: data.familySituation ? data.familySituation : undefined,
        country: data.country
            ? ({
                  code: data.country,
                  name: countries.getName(data.country, language),
              } as CountryOptions)
            : { code: 'BE', name: 'België' },
    };
};
