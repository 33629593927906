import * as languages from '@cospired/i18n-iso-languages';
import * as countries from 'i18n-iso-countries';
import { ISupportee } from '../../shared';
import { CountryOptions, ISupporteeForm, LanguageOptions } from '../models';
import { mapDateToApiDate } from './date-string.mapper';

export function supporteeMapperFromForm(
    supportee: ISupporteeForm,
    relations: { value: string; translation: string }[],
): ISupportee {
    return {
        ...supportee,
        language: supportee.language?.code || '',
        nationality: supportee.nationality?.code || '',
        relation:
            relations.find((relation) => relation.translation.toLowerCase() === supportee.relation?.toLowerCase())
                ?.value || supportee.relation,
        dateOfBirth: mapDateToApiDate(supportee.dateOfBirth),
        gender: supportee.gender || null,
    };
}

export function supporteeMapperToForm(supportee: ISupportee, language: string): ISupporteeForm {
    return {
        ...supportee,
        firstName: supportee.firstName ? supportee.firstName : '',
        lastName: supportee.lastName ? supportee.lastName : '',
        nationalSecurityNumber: supportee.nationalSecurityNumber ? supportee.nationalSecurityNumber : '',
        language: supportee.language
            ? ({ code: supportee.language, name: languages.getName(supportee.language, language) } as LanguageOptions)
            : null,
        nationality: supportee.nationality
            ? ({
                  code: supportee.nationality,
                  name: countries.getName(supportee.nationality, language),
              } as CountryOptions)
            : null,
        dateOfBirth: supportee.dateOfBirth ? new Date(supportee.dateOfBirth) : null,
        gender: supportee.gender || '',
    };
}
