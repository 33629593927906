import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { AUTH_API_BASE_URL, queryKeys } from '../../../shared';

async function deleteUserRoles(
    id: string,
    onDeleteSuccessMessage?: string,
    onDeleteErrorMessage?: string,
): Promise<void> {
    await axios.delete(`${AUTH_API_BASE_URL}/v1/acls/${id}`, {
        successMessage: onDeleteSuccessMessage,
        errorMessage: onDeleteErrorMessage,
    });
}

export const useDeleteUserRoles = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation<void, AxiosError, string>({
        mutationFn: (id) => deleteUserRoles(id, t('deleteUserRolesSuccess'), t('deleteUserRolesError')),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [queryKeys.USER_ROLES] });
        },
    });
};
