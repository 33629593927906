import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { AUTH_API_BASE_URL, AuthListQueryParam, IUserRoles, ListModelItems } from '../../index';
import { queryKeys } from '../../constants';

async function getUserRoles(
    userId: string,
    params: AuthListQueryParam,
    onGetErrorMessage?: string,
): Promise<ListModelItems<IUserRoles>> {
    const { data } = await axios.get(`${AUTH_API_BASE_URL}/v1/acls`, {
        params: { limit: 1000, ...params, 'subject.user.id': userId },
        errorMessage: onGetErrorMessage,
    });

    return data;
}

export const useGetUserRoles = (userId = '', params: AuthListQueryParam = {}) => {
    const { t } = useTranslation();

    return useQuery<ListModelItems<IUserRoles>, AxiosError>({
        queryKey: [queryKeys.USER_ROLES, userId, params],
        queryFn: () => getUserRoles(userId, params, t('getUserRolesError')),
        enabled: !!userId,
    });
};
