import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogTitleWithClose } from '../../../shared';
import { ISupportCaseDuplicate } from '../../models';
import { Duplicates } from '../duplicates/duplicates.component';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    supportCaseId: string;
    duplicates: ISupportCaseDuplicate[];
}

export const DuplicatesModal: FC<Props> = ({ isOpen, onClose, supportCaseId, duplicates }) => {
    const { t } = useTranslation();

    return (
        <Dialog open={isOpen} maxWidth="lg">
            <DialogTitleWithClose onClose={onClose}>{t('duplicates')}</DialogTitleWithClose>
            <DialogContent>
                <Duplicates supportCaseId={supportCaseId} duplicates={duplicates} onClose={onClose} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} type="submit" variant="contained" data-testid="confirm">
                    {t('ok')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
