import { FC } from 'react';
import { ISupportCase } from '../../../shared';

interface Prop {
    supportCase: ISupportCase;
}

export const SupportCaseTitle: FC<Prop> = ({ supportCase }) => {
    const { caseNumber, primarySupportee, externalId } = supportCase;
    const { firstName, lastName } = primarySupportee;
    const fullName = `${lastName || ''} ${firstName || ''}`.trim();
    const values = [caseNumber, fullName, externalId].filter(Boolean).join(' - ');
    return <>{values}</>;
};
