import { List, ListItem, ListItemText } from '@mui/material';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomAttributeType, formatDateString, ICustomAttribute, IRegistration } from '../../../shared';

interface Props {
    registrations: IRegistration[];
}

export const InfoLastRegistrations: FC<Props> = ({ registrations }) => {
    const { t } = useTranslation();

    const getAttributes = useCallback((registration: IRegistration) => {
        const attributes = registration.attributes
            ?.filter((attribute: ICustomAttribute) => attribute.booleanValue || attribute.amountValue)
            .map((attribute: ICustomAttribute) =>
                attribute.type === CustomAttributeType.AMOUNT
                    ? `${attribute.amountValue} x ${attribute.name}`
                    : attribute.name,
            )
            .join(', ');
        return attributes ? `- ${attributes}` : '';
    }, []);

    return (
        <>
            <List dense data-testid="registrations">
                {registrations.map((registration) => (
                    <ListItem key={registration.id}>
                        <ListItemText
                            primary={`${registration.supportType?.name} - ${registration.organisation?.name}`}
                            secondary={`${formatDateString(registration.dateOfRegistration)} ${getAttributes(
                                registration,
                            )} ${registration.comment ? `(${registration.comment})` : ''}`}
                        />
                    </ListItem>
                ))}
            </List>
            {registrations?.length == 0 && t('emptyStateRegistrations')}
        </>
    );
};
