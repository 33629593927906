import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { ISupportTypeFrequenciesQuery, ISupportTypeFrequency, ListModel, queryKeys } from '../../../shared';

async function getSupportTypesFrequencies(
    params: ISupportTypeFrequenciesQuery,
    onGetErrorMessage?: string,
): Promise<ListModel<ISupportTypeFrequency>> {
    const { data } = await axios.get(`/support-api/support-type-frequencies`, {
        params,
        errorMessage: onGetErrorMessage,
    });
    return data;
}

export const useGetSupportTypesFrequencies = (params: ISupportTypeFrequenciesQuery) => {
    const { t } = useTranslation();

    return useQuery<ListModel<ISupportTypeFrequency>, AxiosError>({
        queryKey: [queryKeys.SUPPORT_TYPES_FREQUENCIES, params],
        queryFn: () => getSupportTypesFrequencies(params, t('getSupportTypesFrequenciesError')),
        enabled: !!params.organisationId,
    });
};
