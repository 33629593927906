import axios, { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ISupportee } from '../../../shared';
import { queryKeys } from '../../constants';
import { useTranslation } from 'react-i18next';

async function saveSupportee(
    supportCaseId: string,
    supportee: ISupportee,
    onUpdateSuccessMessage?: string,
    onUpdateErrorMessage?: string,
    onCreateSuccessMessage?: string,
    onCreateErrorMessage?: string,
): Promise<ISupportee> {
    let result;
    if (supportee.id) {
        result = await axios.put(`/support-api/support-cases/${supportCaseId}/supportees/${supportee.id}`, supportee, {
            successMessage: onUpdateSuccessMessage,
            errorMessage: onUpdateErrorMessage,
        });
    } else {
        result = await axios.post(`/support-api/support-cases/${supportCaseId}/supportees`, supportee, {
            successMessage: onCreateSuccessMessage,
            errorMessage: onCreateErrorMessage,
        });
    }

    return result.data;
}

export const useSaveSupportee = (supportCaseId: string) => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation<ISupportee, AxiosError, ISupportee>({
        mutationFn: (data) =>
            saveSupportee(
                supportCaseId,
                data,
                t('updateSupporteeSuccess'),
                t('updateSupporteeError'),
                t('createSupporteeSuccess'),
                t('createSupporteeError'),
            ),

        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [queryKeys.SUPPORT_CASES] });
            await queryClient.invalidateQueries({ queryKey: [queryKeys.SUPPORT_CASE, supportCaseId] });
            await queryClient.invalidateQueries({ queryKey: [queryKeys.SUPPORT_CASES_DUPLICATES, supportCaseId] });
        },
    });
};
