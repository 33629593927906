import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../constants';
import { ISupportCaseDuplicate } from '../../models';

async function getSupportCaseDuplicates(id: string, onGetErrorMessage?: string): Promise<ISupportCaseDuplicate[]> {
    const { data } = await axios.get(`/support-api/support-cases/${id}/duplicates`, {
        errorMessage: onGetErrorMessage,
    });
    return data;
}

export const useSupportCaseDuplicates = (id: string) => {
    const { t } = useTranslation();

    return useQuery<ISupportCaseDuplicate[], AxiosError>({
        queryKey: [queryKeys.SUPPORT_CASES_DUPLICATES, id],
        queryFn: () => getSupportCaseDuplicates(id, t('getSupportCaseDuplicatesError')),
    });
};
