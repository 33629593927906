import { Box } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { PermissionKeys, ProtectedRoute, SideBar, SideNav, useHasPermission } from '../../../shared';
import { adminSubRoutes } from '../../admin.routes';

export const AdminPage: FC = () => {
    const { t } = useTranslation();
    const { hasPermission } = useHasPermission();
    const hasAttributesWritePermissions = hasPermission(PermissionKeys.ATTRIBUTES_WRITE);
    const hasFrequenciesWritePermissions = hasPermission(PermissionKeys.SUPPORT_TYPE_FREQUENCIES_WRITE);
    const hasReferralWritePermissions = hasPermission(PermissionKeys.REFERRAL_ORGANISATIONS_WRITE, true);
    const hasAdminWritePermissions = hasPermission(PermissionKeys.ORGANISATIONS_WRITE, true);
    const hasIncomeTypesWrite = hasPermission(PermissionKeys.INCOME_TYPES_WRITE, true);
    const hasExpenseTypesWrite = hasPermission(PermissionKeys.EXPENSE_TYPES_WRITE, true);
    const hasFoodRestrictionWrite = hasPermission(PermissionKeys.FOOD_RESTRICTIONS_WRITE, true);

    return (
        <Box sx={{ display: 'flex' }}>
            <SideBar>
                <SideNav
                    items={[
                        ...(hasAttributesWritePermissions || hasFrequenciesWritePermissions
                            ? [{ text: t('organisationSettings'), key: 'organisation-settings' }]
                            : []),

                        ...(hasAttributesWritePermissions
                            ? [
                                  {
                                      path: '/admin/custom-attribute-configurations',
                                      text: t('customAttributeConfigurations'),
                                  },
                              ]
                            : []),

                        ...(hasFrequenciesWritePermissions
                            ? [
                                  {
                                      path: '/admin/frequencies-edit',
                                      text: t('supportTypesFrequencies'),
                                  },
                              ]
                            : []),

                        { text: t('commonSettings'), key: 'common-settings' },
                        {
                            path: '/admin/card-sets',
                            text: t('cardSets'),
                        },
                        ...(hasReferralWritePermissions
                            ? [
                                  {
                                      path: '/admin/referral-organisations',
                                      text: t('referralOrganisations'),
                                  },
                              ]
                            : []),
                        ...(hasAdminWritePermissions
                            ? [
                                  {
                                      path: '/admin/organisations',
                                      text: t('organisations'),
                                  },
                                  {
                                      path: '/admin/organisation-types',
                                      text: t('organisationTypes'),
                                  },
                                  {
                                      path: '/admin/support-types',
                                      text: t('supportTypes'),
                                  },
                                  {
                                      path: '/admin/users',
                                      text: t('users'),
                                  },
                              ]
                            : []),
                        ...(hasIncomeTypesWrite
                            ? [
                                  {
                                      path: '/admin/income-types',
                                      text: t('incomeTypes'),
                                  },
                              ]
                            : []),
                        ...(hasExpenseTypesWrite
                            ? [
                                  {
                                      path: '/admin/expense-types',
                                      text: t('expenseTypes'),
                                  },
                              ]
                            : []),
                        ...(hasFoodRestrictionWrite
                            ? [
                                  {
                                      path: '/admin/food-restrictions',
                                      text: t('foodRestrictions'),
                                  },
                              ]
                            : []),
                    ]}
                />
            </SideBar>
            <Box sx={{ flex: 1 }}>
                <Routes>
                    {adminSubRoutes.map((subRoute) => (
                        <Route
                            key={subRoute.path}
                            path={subRoute.path}
                            element={
                                <ProtectedRoute
                                    requiredPermission={subRoute.requiredPermissions}
                                    skipOrganisationCheck={subRoute.skipOrganisationCheck}
                                >
                                    <subRoute.component />
                                </ProtectedRoute>
                            }
                        />
                    ))}
                </Routes>
            </Box>
        </Box>
    );
};
