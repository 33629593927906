import { PermissionKeys } from '../../shared';

export function getFirstNavigationTab(
    hasPermission: (requiredPermission: PermissionKeys | PermissionKeys[], skipOrganisationCheck?: boolean) => boolean,
) {
    if (hasPermission(PermissionKeys.REGISTRATIONS_WRITE, true)) {
        return '/registration';
    } else if (hasPermission(PermissionKeys.SUPPORT_CASES_READ, true)) {
        return '/support';
    } else if (hasPermission(PermissionKeys.REGISTRATIONS_READ, true)) {
        return '/reports';
    } else if (hasPermission(PermissionKeys.ATTRIBUTES_WRITE, true)) {
        return '/admin';
    } else if (hasPermission(PermissionKeys.ORGANISATIONS_READ, true)) {
        return '/admin/organisations';
    }
    return '';
}
