import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { AUTH_API_BASE_URL, AuthListQueryParam, ListModelItems, queryKeys } from '../../../shared';
import { IRole } from '../../models';

async function getRoles(params: AuthListQueryParam, onGetErrorMessage?: string): Promise<ListModelItems<IRole>> {
    const { data } = await axios.get(`${AUTH_API_BASE_URL}/v1/roles`, { params, errorMessage: onGetErrorMessage });

    return data;
}

export const useGetRoles = (params: AuthListQueryParam) => {
    const { t } = useTranslation();

    return useQuery<ListModelItems<IRole>, AxiosError>({
        queryKey: [queryKeys.ROLES, params],
        queryFn: () => getRoles(params, t('getRolesError')),
    });
};
