import axios, { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { IReferralOrganisation, queryKeys } from '../../../shared';

async function getReferralOrganisation(id: string, onGetErrorMessage?: string): Promise<IReferralOrganisation> {
    const { data } = await axios.get(`/support-api/referral-organisations/${id}`, {
        errorMessage: onGetErrorMessage,
    });

    return data;
}

export const useGetReferralOrganisation = (id: string) => {
    return useQuery<IReferralOrganisation, AxiosError>({
        queryKey: [queryKeys.REFERRAL_ORGANISATION, id],
        queryFn: () => getReferralOrganisation(id),
        enabled: !!id,
    });
};
