import { Box } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { endOfDay, startOfDay } from 'date-fns';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    formatDateTimeString,
    IOrganisation,
    ISupportType,
    Page,
    SupportCaseSupportees,
    Table,
    useQueryParamsContext,
} from '../../../shared';
import { RegistrationsFilter } from '../../components/registrations-filter/registrations-filter.component';
import { useRegistrations } from '../../hooks/use-registrations.hook';
import { IRegistrationReport, IRegistrationReportFilter } from '../../models/registration-report.model';

export const ReportRegistrations: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const {
        queryParams: { page, pageSize, sort },
        setPage,
        setPageSize,
        setSort,
    } = useQueryParamsContext('organisation-types', {
        sort: [{ field: 'date', sort: 'asc' }],
    });
    const [filter, setFilter] = useState<IRegistrationReportFilter>({
        startDate: new Date().toString(),
        endDate: new Date().toString(),
    });

    const { data: registrations, isPending } = useRegistrations({
        page,
        pageSize,
        startDate: getStartDate(filter.startDate),
        endDate: getEndDate(filter.endDate),
        organisationIds: filter.organisations,
    });

    const columns: GridColDef[] = [
        {
            field: 'dateOfRegistration',
            headerName: t('date'),
            minWidth: 150,
            flex: 1,
            sortable: false,
            valueFormatter: (value) => formatDateTimeString(value),
        },
        {
            field: 'supportType',
            headerName: t('supportType'),
            sortable: false,
            minWidth: 150,
            flex: 1,
            valueFormatter: (value: ISupportType) => value?.name,
        },
        {
            field: 'supportCase',
            headerName: t('supportCase'),
            sortable: false,
            minWidth: 150,
            flex: 1,
            renderCell: ({ row: { supportCase } }: GridCellParams<IRegistrationReport>) =>
                supportCase ? (
                    <SupportCaseSupportees supportCase={supportCase} />
                ) : (
                    <Box sx={{ fontStyle: 'italic' }}>{t('unknown')}</Box>
                ),
        },
        {
            field: 'organisation',
            headerName: t('organisation'),
            sortable: false,
            minWidth: 150,
            flex: 1,
            valueFormatter: (value: IOrganisation) => value.name,
        },
    ];

    return (
        <Page
            title={t('report.registrations')}
            filter={<RegistrationsFilter filter={filter} onChange={setFilter} />}
            loading={isPending}
        >
            <Table
                rows={registrations?.data || []}
                columns={columns}
                rowCount={registrations?.pagination.size || 0}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                loading={isPending}
                sortModel={sort}
                onSortChange={setSort}
                onRowClick={({ row: registration }) =>
                    registration.supportCase && navigate(`/support/support-cases/${registration.supportCase.id}/detail`)
                }
            />
        </Page>
    );
};

function getStartDate(startDate: string | undefined) {
    try {
        if (!startDate) return;
        return startOfDay(new Date(startDate)).toISOString();
    } catch {
        //no-empty
    }
}

function getEndDate(endDate: string | undefined) {
    try {
        if (!endDate) return;
        return endOfDay(new Date(endDate)).toISOString();
    } catch {
        //no-empty
    }
}
