import { useMutation, UseMutationResult, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ICompleteIntake, ICreateIntake, IIntake, IntakeQuery, IUpdateIntake, ListModel } from '../../../shared';
import { intakesClient } from '../../clients';
import { queryKeys } from '../../constants';

export function useGetIntakes(caseId: string, params: IntakeQuery) {
    return useQuery<ListModel<IIntake>, AxiosError>({
        queryKey: [caseId, queryKeys.INTAKES, params],
        queryFn: () => intakesClient.getList(caseId, params),
        enabled: !!caseId && !!params.organisationId,
    });
}

export function useGetIntake(caseId?: string, intakeId?: string) {
    return useQuery<IIntake, AxiosError>({
        queryKey: [queryKeys.INTAKE, intakeId],
        queryFn: () => intakesClient.getOne(caseId, intakeId),
        enabled: !!caseId && !!intakeId,
    });
}

export function useCreateIntake(): UseMutationResult<
    IIntake,
    AxiosError,
    { item: ICreateIntake; supportCaseId: string }
> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ supportCaseId, item }) => intakesClient.createOne(supportCaseId, item),
        onSuccess: async (intake) => {
            await queryClient.invalidateQueries({ queryKey: [intake.supportCaseId, queryKeys.INTAKES] });
            await queryClient.setQueryData([queryKeys.INTAKE, intake.id], intake);
        },
    });
}

export function useUpdateIntake(): UseMutationResult<
    IIntake,
    AxiosError,
    { item: IUpdateIntake; supportCaseId: string; intakeId: string }
> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ supportCaseId, intakeId, item }) => intakesClient.updateOne(supportCaseId, intakeId, item),
        onSuccess: async (intake) => {
            await queryClient.setQueryData([queryKeys.INTAKE, intake.id], intake);
        },
    });
}

export function useCompleteIntake(): UseMutationResult<
    IIntake,
    AxiosError,
    { item: ICompleteIntake; supportCaseId: string; intakeId: string }
> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ supportCaseId, intakeId, item }) => intakesClient.complete(supportCaseId, intakeId, item),
        onSuccess: async (intake) => {
            await queryClient.invalidateQueries({ queryKey: [intake.supportCaseId, queryKeys.INTAKES] });
            await queryClient.setQueryData([queryKeys.INTAKE, intake.id], intake);
            await queryClient.invalidateQueries({ queryKey: [intake.supportCaseId, queryKeys.SUPPORT_PERIODS] });
        },
    });
}
