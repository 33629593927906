import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { IRegistration, ListModel, ListQueryParam, SupportCaseRegistrationsQuery } from '../../../shared';
import { queryKeys } from '../../constants';

async function getRegistrations(
    caseId: string,
    params: ListQueryParam,
    errorMessage: string,
): Promise<ListModel<IRegistration>> {
    const { data } = await axios.get(`/support-api/support-cases/${caseId}/registrations`, {
        params,
        errorMessage: errorMessage,
    });
    return data;
}

export const useGetRegistrations = (caseId: string, params: SupportCaseRegistrationsQuery, enabled: boolean) => {
    const { t } = useTranslation();

    return useQuery<ListModel<IRegistration>, AxiosError>({
        queryKey: [caseId, queryKeys.REGISTRATIONS, params],
        queryFn: () => getRegistrations(caseId, params, t('getRegistrationsError')),
        enabled,
    });
};
