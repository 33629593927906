import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { IAddress, IUpdateResidence } from '../../../shared';
import { queryKeys } from '../../constants';

async function updateAddress(
    supportCaseId: string,
    address: IUpdateResidence,
    onUpdateSuccessMessage?: string,
    onUpdateErrorMessage?: string,
): Promise<IAddress> {
    const result = await axios.put(`/support-api/support-cases/${supportCaseId}/address`, address, {
        successMessage: onUpdateSuccessMessage,
        errorMessage: onUpdateErrorMessage,
    });

    return result.data;
}

export const useUpdateAddress = (supportCaseId: string) => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation<IAddress, AxiosError, IUpdateResidence>({
        mutationFn: (data) =>
            updateAddress(supportCaseId, data, t('updateResidenceSuccess'), t('updateResidenceError')),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [queryKeys.SUPPORT_CASE, supportCaseId] });
            await queryClient.invalidateQueries({ queryKey: [queryKeys.SUPPORT_CASES_DUPLICATES, supportCaseId] });
        },
    });
};
