import { BaseClient, supportApi } from '../../shared';
import { IFoodRestriction, ISaveFoodRestriction } from '../models';

class FoodRestrictionClient extends BaseClient<IFoodRestriction, ISaveFoodRestriction> {
    constructor() {
        super(supportApi, '/food-restrictions');
    }
}

export const foodRestrictionClient = new FoodRestrictionClient();
