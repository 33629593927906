import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Stack } from '@mui/material';
import React, { FC, Fragment } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledNumberInput, FormCardSectionComponent } from '../../../shared';

export const IncomeLimitInputs: FC = () => {
    const { t } = useTranslation();
    const { control } = useFormContext();

    const {
        fields: incomeLimits,
        append,
        remove,
    } = useFieldArray({
        control,
        name: 'incomeLimits',
    });

    return (
        <FormCardSectionComponent title={t('incomeLimits')}>
            <ControlledNumberInput
                name="baseIncomeLimit"
                label={t('baseIncomeLimit')}
                sx={{ maxWidth: '400px' }}
                required
            />
            {incomeLimits.map((limit, index) => (
                <Fragment key={limit.id}>
                    <Stack direction="row" sx={{ width: '465px' }} spacing={2}>
                        <ControlledNumberInput
                            name={`incomeLimits.${index}.startAge`}
                            label={t('startAge')}
                            decimalScale={0}
                            required
                        />
                        <ControlledNumberInput
                            name={`incomeLimits.${index}.amountPerPerson`}
                            label={t('amountPerPerson')}
                            required
                        />
                        <IconButton aria-label="delete" onClick={() => remove(index)} size="large">
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </Fragment>
            ))}
            <Button onClick={() => append({ startAge: 0, amountPerPerson: 0 })} color="secondary">
                + {t('addIncomeLimit')}
            </Button>
        </FormCardSectionComponent>
    );
};
