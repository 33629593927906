import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IRegistration } from '../../../shared';
import axios, { AxiosError } from 'axios';
import { queryKeys } from '../../constants';

async function deleteRegistration(
    caseId: string,
    id: string,
    onDeleteSuccessMessage?: string,
    onDeleteErrorMessage?: string,
): Promise<IRegistration> {
    const { data } = await axios.delete(`/support-api/support-cases/${caseId}/registrations/${id}`, {
        successMessage: onDeleteSuccessMessage,
        errorMessage: onDeleteErrorMessage,
    });
    return data;
}

export const useDeleteRegistration = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation<IRegistration, AxiosError, { supportCaseId: string; registrationId: string }>({
        mutationFn: (data) =>
            deleteRegistration(
                data.supportCaseId,
                data.registrationId,
                t('deleteRegistrationSuccess'),
                t('deleteRegistrationError'),
            ),

        onSuccess: async (data, variables) => {
            await queryClient.invalidateQueries({ queryKey: [variables.supportCaseId, queryKeys.REGISTRATIONS] });
        },
    });
};
