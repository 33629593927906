import { IconButton, Tooltip } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SUPPORT_API_BASE_URL } from '../../../shared';
import { Download } from '@mui/icons-material';

export const DownloadCardAction: FC<{ cardId: string }> = ({ cardId }) => {
    const { t } = useTranslation();

    return (
        <Tooltip title={t('downloadCard')} placement="bottom" arrow>
            <IconButton
                data-testid="download-card"
                href={`${SUPPORT_API_BASE_URL}/cards/${cardId}/download`}
                target="__blank"
            >
                <Download />
            </IconButton>
        </Tooltip>
    );
};
