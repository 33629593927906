import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ICustomAttributeConfiguration, ISaveCustomAttributeConfiguration, queryKeys } from '../../../shared';

async function saveCustomAttributeConfiguration(
    config: ISaveCustomAttributeConfiguration,
    onUpdateSuccessMessage?: string,
    onUpdateErrorMessage?: string,
    onCreateSuccessMessage?: string,
    onCreateErrorMessage?: string,
): Promise<ICustomAttributeConfiguration> {
    let result;
    if (config.id) {
        result = await axios.put(`/support-api/custom-attribute-configurations/${config.id}`, config, {
            successMessage: onUpdateSuccessMessage,
            errorMessage: onUpdateErrorMessage,
        });
    } else {
        result = await axios.post('/support-api/custom-attribute-configurations', config, {
            successMessage: onCreateSuccessMessage,
            errorMessage: onCreateErrorMessage,
        });
    }

    return result.data;
}

export const useSaveCustomAttributeConfiguration = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation<ICustomAttributeConfiguration, AxiosError, ISaveCustomAttributeConfiguration>({
        mutationFn: (data) =>
            saveCustomAttributeConfiguration(
                data,
                t('updateCustomAttributeConfigurationSuccess'),
                t('updateCustomAttributeConfigurationError'),
                t('createCustomAttributeConfigurationSuccess'),
                t('createCustomAttributeConfigurationError'),
            ),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [queryKeys.CUSTOM_ATTRIBUTE_CONFIGURATIONS] });
        },
    });
};
