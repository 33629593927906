import { yupResolver } from '@hookform/resolvers/yup';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    FormCardSectionComponent,
    IIntake,
    IIntakeCompletionForm,
    InputTextField,
    useSelectedOrganisation,
    Widget,
} from '../../../../shared';
import { IntakeCreateSupportPeriod, IntakeSummary } from '../../../components';
import { useCompleteIntake } from '../../../hooks';
import { useIntakeIncomeResult } from '../../../mappers';
import {
    intakeCompletionFormFormMapper,
    intakeCompletionToFormMapper,
} from '../../../mappers/intake-completion.mapper';
import { useIntakeCompletionSchema } from '../../../validators';

interface Props {
    intake?: IIntake;
}

export const IntakeCompletionPage: FC<Props> = ({ intake }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { organisation } = useSelectedOrganisation();

    const form = useForm<IIntakeCompletionForm>({
        resolver: yupResolver(useIntakeCompletionSchema()),
        mode: 'onSubmit',
    });

    const { mutateAsync: completeIntake, isPending } = useCompleteIntake();
    const intakeIncomeResult = useIntakeIncomeResult(intake);

    useEffect(() => {
        if (organisation && intake) {
            form.reset(intakeCompletionToFormMapper(organisation, intake, intakeIncomeResult));
        }
    }, [organisation, intake, intakeIncomeResult, form.reset, intakeCompletionToFormMapper]);

    const onSubmit = async (item: IIntakeCompletionForm) => {
        if (intake) {
            await completeIntake({
                supportCaseId: intake.supportCaseId,
                intakeId: intake.id,
                item: intakeCompletionFormFormMapper(item),
            });
            navigate('../../intakes');
        }
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} noValidate={true} autoComplete="off">
                <Widget
                    footer={
                        <Stack direction="row" justifyContent="space-between">
                            <Button
                                startIcon={<ChevronLeft />}
                                onClick={() =>
                                    intake?.externalIntake ? navigate('../intro') : navigate('../expenses')
                                }
                                disabled={isPending}
                            >
                                {t('previous')}
                            </Button>
                            <Button
                                endIcon={<ChevronRight />}
                                variant="contained"
                                color="primary"
                                onClick={form.handleSubmit((data) => onSubmit(data))}
                                disabled={isPending}
                            >
                                {t('complete')}
                            </Button>
                        </Stack>
                    }
                >
                    <Stack spacing={2} sx={{ overflow: 'scroll' }}>
                        <IntakeSummary intake={intake} />
                        <IntakeCreateSupportPeriod form={form} />
                        <FormCardSectionComponent title={t('comments')}>
                            <InputTextField name="comment" multiline />
                        </FormCardSectionComponent>
                    </Stack>
                </Widget>
            </form>
        </FormProvider>
    );
};
