import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ISupportCase } from '../../../shared';
import { queryKeys } from '../../constants';

async function removeCardFromSupportCase(
    supportCase: string,
    cardId: string,
    onUpdateSuccessMessage?: string,
    onUpdateErrorMessage?: string,
): Promise<ISupportCase> {
    const result = await axios.put(
        `/support-api/support-cases/${supportCase}/remove-card`,
        { cardId },
        {
            successMessage: onUpdateSuccessMessage,
            errorMessage: onUpdateErrorMessage,
        },
    );
    return result.data;
}

export const useRemoveCardFromSupportCase = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation<ISupportCase, AxiosError, { supportCaseId: string; cardId: string }>({
        mutationFn: (data) =>
            removeCardFromSupportCase(
                data.supportCaseId,
                data.cardId,
                t('updateSupportCaseSuccess'),
                t('updateSupportCaseError'),
            ),

        onSuccess: async (data) => {
            await queryClient.invalidateQueries({ queryKey: [queryKeys.SUPPORT_CASE, data.id] });
        },
    });
};
