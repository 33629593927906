import { useMemo } from 'react';
import { boolean, string } from 'yup';
import { baseSchema } from '../../shared';

export function useCustomAttributeConfigurationSchema() {
    return useMemo(
        () =>
            baseSchema().shape({
                type: string().required(),
                defaultSelected: boolean().required(),
            }),
        [],
    );
}
