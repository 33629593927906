import { Box } from '@mui/material';
import { FC, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Loading, ProtectedRoute, SideBar, SideNav } from '../../../shared';
import { useDashboards } from '../../hooks';
import { reportSubRoutes } from '../../reports.routes';

export const ReportsPage: FC = () => {
    const { data: dashboards = [], isLoading } = useDashboards();
    const navigate = useNavigate();

    useEffect(() => {
        if (dashboards.length) {
            const reports = dashboards[0].reports;
            if (reports.length) navigate(`/reports/${reports[0].id}`);
        }
    }, [dashboards]);

    if (isLoading) return <Loading fullscreen />;
    return (
        <Box sx={{ display: 'flex' }}>
            <SideBar>
                <SideNav
                    items={[
                        ...dashboards.flatMap(({ id, name, reports }) => [
                            {
                                text: name,
                                key: id,
                            },
                            ...reports.map((report) => ({
                                path: `/reports/${report.id}`,
                                text: report.name,
                            })),
                        ]),
                    ]}
                />
            </SideBar>
            <Box sx={{ flex: 1 }}>
                <Routes>
                    {reportSubRoutes.map((subRoute) => (
                        <Route
                            key={subRoute.path}
                            path={subRoute.path}
                            element={
                                <ProtectedRoute requiredPermission={subRoute.requiredPermissions}>
                                    <subRoute.component />
                                </ProtectedRoute>
                            }
                        />
                    ))}
                </Routes>
            </Box>
        </Box>
    );
};
