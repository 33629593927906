export const queryKeys = {
    ORGANISATION: 'organisation',
    ORGANISATIONS: 'organisations',
    ORGANISATION_TYPE: 'organisation-type',
    ORGANISATION_TYPES: 'organisation-types',
    SUPPORT_TYPE: 'support-type',
    SUPPORT_TYPES: 'support-types',
    USERS: 'users',
    USER: 'user',
    ROLES: 'roles',
    USER_ROLES: 'user-roles',
    REFERRAL_ORGANISATION: 'referral-organisation',
    REFERRAL_ORGANISATIONS: 'referral-organisations',
    CUSTOM_ATTRIBUTE_CONFIGURATIONS: 'custom-attribute-configurations',
    CUSTOM_ATTRIBUTE_CONFIGURATION: 'custom-attribute-configuration',
    CARD_SETS: 'CARD_SETS',
    CARD_SET: 'CARD_SET',
    REGISTRATIONS: 'REGISTRATIONS',
    SUPPORT_TYPES_FREQUENCIES: 'support-types-frequencies',
    INCOME_TYPES: 'income-types',
    INCOME_TYPE: 'income-type',
    EXPENSE_TYPES: 'expense-types',
    EXPENSE_TYPE: 'expense-type',
    FOOD_RESTRICTIONS: 'food-restrictions',
    FOOD_RESTRICTION: 'food-restriction',
    DASHBOARDS: 'DASHBOARDS',
    REPORT: 'REPORT',
    REPORT_RESULT: 'REPORT_RESULT',
};
