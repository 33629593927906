import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { array, boolean, number, object, string } from 'yup';

export function useIntakeExpensesSchema() {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                expenses: array()
                    .of(
                        object().shape({
                            expenseTypeId: string().required(),
                            expenseTypeName: string().required(),
                            amount: number()
                                .transform((value) => (value === '' || Number.isNaN(value) ? null : value))
                                .typeError(t('invalidNumber'))
                                .min(0, t('invalidNumber'))
                                .nullable(),
                            lumpSum: boolean().required(),
                        }),
                    )
                    .required(),
            }),
        [t],
    );
}
