import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, Stack } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    DialogTitleWithClose,
    getDefaultSupportTypes,
    ISupportPeriod,
    ISupportPeriodForm,
    useOrganisations,
    useSelectedOrganisation,
    useSupportTypes,
} from '../../../shared';
import { useSaveSupportPeriod } from '../../hooks';
import { newSupportPeriodMapperToForm, supportPeriodMapperFromForm, supportPeriodMapperToForm } from '../../mappers';
import { useSupportPeriodSchema } from '../../validators';
import { SupportTypesInfo } from '../support-types-info/support-types-info.component';
import { ReferrerInputs } from './referrer-inputs.component';
import { SupportPeriodGeneralInputs } from './support-period-general-inputs.component';

interface Props {
    show: boolean;
    supportCase: string;
    supportPeriod: ISupportPeriod | null;
    handleClose: () => void;
}

export const SaveSupportPeriodModal: React.FC<Props> = ({ show, supportCase, supportPeriod, handleClose }) => {
    const { t } = useTranslation();
    const { organisation } = useSelectedOrganisation();
    const defaultSupportTypes = getDefaultSupportTypes(organisation);

    const organisations = useOrganisations();

    const { mutateAsync: saveSupportPeriod } = useSaveSupportPeriod();

    const form = useForm<ISupportPeriodForm>({
        resolver: yupResolver(useSupportPeriodSchema()),
        mode: 'onSubmit',
    });

    const watchOrganisation = form.watch('organisation');

    useEffect(() => {
        if (watchOrganisation?.id !== supportPeriod?.organisationId) {
            form.setValue(
                'supportTypesInfo',
                getDefaultSupportTypes(watchOrganisation).map((id) => ({ supportTypeId: id })),
            );
        }
    }, [watchOrganisation, supportPeriod, form.setValue]);

    const supportTypesList = useSupportTypes();
    const supportTypes = useMemo(
        () => watchOrganisation?.supportTypes || supportTypesList,
        [watchOrganisation, supportTypesList],
    );

    useEffect(() => {
        if (supportPeriod && supportTypes) form.reset({ ...supportPeriodMapperToForm(supportPeriod, organisations) });
        else form.reset(newSupportPeriodMapperToForm(organisation, defaultSupportTypes));
    }, [form.reset, supportPeriod]);

    const onSubmit = useCallback(async (data: ISupportPeriodForm) => {
        await saveSupportPeriod({
            supportPeriod: supportPeriodMapperFromForm({ ...supportPeriod, ...data }),
            supportCase: supportCase,
        });

        handleClose();
    }, []);

    return (
        <FormProvider {...form}>
            <Dialog
                open={show}
                fullWidth
                maxWidth="lg"
                PaperProps={{
                    component: 'form',
                    onSubmit: form.handleSubmit(onSubmit),
                }}
            >
                <DialogTitleWithClose onClose={handleClose}>{t('addSupportPeriod')}</DialogTitleWithClose>
                <DialogContent>
                    <Stack spacing={2} mt={2}>
                        <SupportPeriodGeneralInputs organisations={organisations} />

                        <SupportTypesInfo supportTypes={supportTypes} defaultSupportTypes={defaultSupportTypes} />

                        <ReferrerInputs supportPeriod={supportPeriod} />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" variant="contained" color="primary">
                        {t('save')}
                    </Button>
                    <Button onClick={handleClose} color="secondary">
                        {t('cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </FormProvider>
    );
};
