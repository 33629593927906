import { Apps, ContactsTwoTone, ShoppingCartTwoTone } from '@mui/icons-material';
import { IconButton, ListItemIcon, Menu, MenuItem, MenuList, Typography } from '@mui/material';
import React, { FC } from 'react';
import { PermissionKeys, useHasPermission } from '../../../shared';

export const AppMenu: FC = () => {
    const { hasPermission } = useHasPermission();
    const hasShopPermission = hasPermission(PermissionKeys.SHOP_APP, true);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (app: string) => {
        setAnchorEl(null);
        if (app === 'shop') {
            window.location.href = window.carity.environment.shopUrl;
        }
    };

    if (!hasShopPermission) return <></>;

    return (
        <>
            <IconButton
                id="menu-button"
                onClick={handleClick}
                aria-controls={open ? 'menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                sx={{ ml: 2 }}
            >
                <Apps />
            </IconButton>
            <Menu
                id="menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'menu-button',
                }}
            >
                <MenuList>
                    <MenuItem onClick={() => handleClose('support')} disabled>
                        <ListItemIcon>
                            <ContactsTwoTone fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography>Carity support</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => handleClose('shop')}>
                        <ListItemIcon>
                            <ShoppingCartTwoTone fontSize="small" color="primary" />
                        </ListItemIcon>
                        <Typography>Carity shop</Typography>
                    </MenuItem>
                </MenuList>
            </Menu>
        </>
    );
};
