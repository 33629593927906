export const queryKeys = {
    COMMENTS: 'comments',
    SUPPORT_CASE: 'support-case',
    SUPPORT_CASES: 'support-cases',
    SUPPORT_CASE_INFO: 'support-case-info',
    SUPPORT_CASES_DUPLICATES: 'support-cases-duplicates',
    SUPPORT_PERIODS: 'support-periods',
    REGISTRATIONS: 'registrations',
    INTAKES: 'intakes',
    INTAKE: 'intake',
};
