import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { ISupportCase, ListModel, ListQueryParam } from '../../../shared';
import { queryKeys } from '../../constants';

async function getSupportCases(
    params: ListQueryParam,
    organisation?: string,
    onGetErrorMessage?: string,
): Promise<ListModel<ISupportCase>> {
    const { data } = await axios.get('/support-api/support-cases', {
        params: { ...params, organisationId: organisation },
        errorMessage: onGetErrorMessage,
    });
    return data;
}

export const useGetSupportCases = (params: ListQueryParam, enabled: boolean, organisation?: string) => {
    const { t } = useTranslation();

    return useQuery<ListModel<ISupportCase>, AxiosError>({
        queryKey: [queryKeys.SUPPORT_CASES, params, { organisation: organisation }],
        queryFn: () => getSupportCases(params, organisation, t('getSupportCasesError')),
        enabled,
    });
};
