import { Grid } from '@mui/material';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IParams, Page, PermissionKeys, useHasPermission } from '../../../shared';
import {
    Contacts,
    DeleteSupportCaseAction,
    DuplicatesWarning,
    Residence,
    SupportCaseFoodRestrictions,
    SupportCaseTitle,
    Supportees,
} from '../../components';
import { useGetSupportCase } from '../../hooks';

export const SupportCaseDetailsPage: FC = () => {
    const navigate = useNavigate();
    const { id } = useParams<keyof IParams>() as IParams;
    const { data: supportCase } = useGetSupportCase(id);
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.SUPPORT_CASES_WRITE);

    return (
        <Page
            onBack={() => navigate(-1)}
            title={supportCase && <SupportCaseTitle supportCase={supportCase} />}
            actions={supportCase && hasWritePermission && <DeleteSupportCaseAction supportCase={supportCase} />}
        >
            <DuplicatesWarning supportCaseId={id} />
            {supportCase && (
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Supportees supportCase={supportCase} />
                    </Grid>
                    <Grid item>
                        <Residence supportCase={supportCase} />
                    </Grid>
                    <Grid item>
                        <Contacts supportCase={supportCase} />
                    </Grid>
                    <Grid item>
                        <SupportCaseFoodRestrictions supportCase={supportCase} />
                    </Grid>
                </Grid>
            )}
        </Page>
    );
};
