import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Button, Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ISupportCase } from '../../../shared';
import { ISupportCaseDuplicate } from '../../models';
import { DuplicateSubRow } from '../duplicate-sub-row/duplicate-sub-row.component';
import { FullName } from '../full-name/full-name.component';

interface Props {
    supportCase: ISupportCase;
    duplicate: ISupportCaseDuplicate;
    onClose: () => void;
}
export const DuplicateRow: FC<Props> = ({ supportCase, duplicate, onClose }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <TableCell>{duplicate.caseNumber}</TableCell>
                <TableCell>
                    <FullName data={duplicate} />
                </TableCell>
                <TableCell>{duplicate.organisations.map(({ name }) => name).join(', ')}</TableCell>
                <TableCell>{t(duplicate.reason)}</TableCell>
                <TableCell>
                    <Button size="small" onClick={() => setOpen(!open)} variant="outlined">
                        {t('details')}
                    </Button>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <DuplicateSubRow supportCase={supportCase} duplicate={duplicate} onClose={onClose} />
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};
