import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { queryKeys } from '../../constants';

async function noDuplicateSupportCase(subjectId: string, targetId: string): Promise<void> {
    await axios.post(`/support-api/support-cases/${subjectId}/no-duplicate`, { supportCaseId: targetId });
}

export const useNoDuplicateSupportCase = () => {
    const queryClient = useQueryClient();

    return useMutation<void, AxiosError, { subjectId: string; targetId: string }>({
        mutationFn: ({ subjectId, targetId }) => noDuplicateSupportCase(subjectId, targetId),
        onSuccess: async (result, params) => {
            await queryClient.invalidateQueries({ queryKey: [queryKeys.SUPPORT_CASES_DUPLICATES, params.subjectId] });
            await queryClient.invalidateQueries({ queryKey: [queryKeys.SUPPORT_CASES_DUPLICATES, params.targetId] });
        },
    });
};
