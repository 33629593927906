import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { ListModel, ListQueryParam, ISupportType } from '../../index';
import { queryKeys } from '../../constants';

async function getSupportTypes(params: ListQueryParam, onGetErrorMessage?: string): Promise<ListModel<ISupportType>> {
    const { data } = await axios.get('/support-api/support-types', { params, errorMessage: onGetErrorMessage });
    return data;
}

export const useGetSupportTypes = (params: ListQueryParam) => {
    const { t } = useTranslation();

    return useQuery<ListModel<ISupportType>, AxiosError>({
        queryKey: [queryKeys.SUPPORT_TYPES, params],
        queryFn: () => getSupportTypes(params, t('getSupportTypesError')),
    });
};
