import axios, { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../../../shared';
import { ICardSet, ISaveCardSet } from '../../models';

async function createCardSet(cardSet: ISaveCardSet): Promise<ICardSet> {
    const { data } = await axios.post('/support-api/card-sets', cardSet);
    return data;
}

export const useCreateCardSet = () => {
    const queryClient = useQueryClient();

    return useMutation<ICardSet, AxiosError, ISaveCardSet>({
        mutationFn: (data) => createCardSet(data),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [queryKeys.CARD_SETS] });
        },
    });
};
