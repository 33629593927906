import { useMemo } from 'react';
import { array, mixed, object, string } from 'yup';
import { Frequency } from '../../support/enums';

export function useSupportTypeFrequencySchema() {
    return useMemo(
        () =>
            object().shape({
                frequencies: array()
                    .of(
                        object().shape({
                            supportType: object()
                                .shape({ id: string().required(), name: string().required() })
                                .required(),
                            frequency: mixed<Frequency | ''>()
                                .oneOf([...Object.values(Frequency), ''])
                                .optional(),
                        }),
                    )
                    .required(),
            }),
        [],
    );
}
