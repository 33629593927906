import {
    Checkbox,
    FormControl,
    FormHelperText,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
} from '@mui/material';
import React, { ComponentProps, FC } from 'react';
import { Control, Controller } from 'react-hook-form';

type Props = ComponentProps<typeof Select> & {
    name: string;
    control?: Control<any>;
    minWidth?: number;
    items: { id: string; name: string }[];
};

export const MultipleSelectComponent: FC<Props> = ({
    name,
    required,
    label,
    control,
    items,
    minWidth = 227,
    ...selectProps
}) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={[]}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
                return (
                    <FormControl sx={{ minWidth: 195 }} error={!!error}>
                        <InputLabel id={name} required={required}>
                            {label}
                        </InputLabel>
                        <Select
                            multiple
                            sx={{ minWidth: minWidth }}
                            onChange={onChange}
                            value={value}
                            id={`${name}-select`}
                            required={required}
                            input={<OutlinedInput notched label={label} />}
                            {...selectProps}
                            renderValue={(selected) =>
                                (selected as string[])
                                    .map((value) => items.find((item) => item.id == value))
                                    .map((item) => (item?.name ? item.name : ''))
                                    .join(', ')
                            }
                        >
                            {items.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    <Checkbox
                                        checked={item.id && value ? (value as string[]).includes(item.id) : false}
                                    />
                                    <ListItemText primary={item.name} />
                                </MenuItem>
                            ))}
                        </Select>
                        {error && (
                            <FormHelperText id={`${name}-field-helper-text`}>
                                {error ? error.message : null}
                            </FormHelperText>
                        )}
                    </FormControl>
                );
            }}
        />
    );
};
