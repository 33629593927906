import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IOrganisationType } from '../../../shared';
import { queryKeys } from '../../../shared';

async function deleteOrganisationType(
    id: string,
    onDeleteSuccessMessage?: string,
    onDeleteErrorMessage?: string,
): Promise<IOrganisationType> {
    const { data } = await axios.delete(`/support-api/organisation-types/${id}`, {
        successMessage: onDeleteSuccessMessage,
        errorMessage: onDeleteErrorMessage,
    });
    return data;
}

export const useDeleteOrganisationType = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation<IOrganisationType, AxiosError, string>({
        mutationFn: (id) =>
            deleteOrganisationType(id, t('deleteOrganisationTypeSuccess'), t('deleteOrganisationTypeError')),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [queryKeys.ORGANISATION_TYPES] });
        },
    });
};
