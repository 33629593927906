import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IRegistration } from '../../../shared';
import { queryKeys } from '../../../support/constants';
import { ICreateRegistration } from '../../models';

async function createRegistration(
    supportCase: string,
    registration: ICreateRegistration,
    onCreateSuccessMessage: string,
    onCreateErrorMessage: string,
): Promise<IRegistration> {
    const { data } = await axios.post(`/support-api/support-cases/${supportCase}/registrations`, registration, {
        successMessage: onCreateSuccessMessage,
        errorMessage: onCreateErrorMessage,
    });
    return data;
}

export const useCreateRegistration = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation<IRegistration, AxiosError, { supportCase: string; registration: ICreateRegistration }>({
        mutationFn: (data) =>
            createRegistration(
                data.supportCase,
                data.registration,
                t('createRegistrationSuccess'),
                t('createRegistrationError'),
            ),
        onSuccess: async (data, variables) => {
            await queryClient.invalidateQueries({ queryKey: [variables.supportCase, queryKeys.REGISTRATIONS] });
        },
    });
};
