import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { IOrganisationType, queryKeys } from '../../../shared';

async function saveOrganisationType(
    organisationType: IOrganisationType,
    onUpdateSuccessMessage?: string,
    onUpdateErrorMessage?: string,
    onCreateSuccessMessage?: string,
    onCreateErrorMessage?: string,
): Promise<IOrganisationType> {
    let result;
    if (organisationType.id) {
        result = await axios.put(`/support-api/organisation-types/${organisationType.id}`, organisationType, {
            successMessage: onUpdateSuccessMessage,
            errorMessage: onUpdateErrorMessage,
        });
    } else {
        result = await axios.post('/support-api/organisation-types', organisationType, {
            successMessage: onCreateSuccessMessage,
            errorMessage: onCreateErrorMessage,
        });
    }

    return result.data;
}

export const useSaveOrganisationType = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation<IOrganisationType, AxiosError, IOrganisationType>({
        mutationFn: (data) =>
            saveOrganisationType(
                data,
                t('updateOrganisationTypeSuccess'),
                t('updateOrganisationTypeError'),
                t('createOrganisationTypeSuccess'),
                t('createOrganisationTypeError'),
            ),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [queryKeys.ORGANISATION_TYPES] });
        },
    });
};
