import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { FormCard, IParams, ISaveExpenseType, Page } from '../../../shared';
import { ExpenseTypeInputs } from '../../components';
import { useExpenseType, useSaveExpenseType } from '../../hooks';
import { expenseTypeFromFormMapper } from '../../mappers';
import { useExpenseTypeSchema } from '../../validators';

export const ExpenseTypeEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams<keyof IParams>() as IParams;

    const { data: expenseType, isLoading } = useExpenseType(id);
    const { mutateAsync: saveExpenseType, isPending } = useSaveExpenseType();

    const form = useForm<ISaveExpenseType>({
        resolver: yupResolver(useExpenseTypeSchema()),
        mode: 'onSubmit',
    });

    useEffect(() => {
        if (expenseType) {
            form.reset(expenseType);
        }
    }, [form, expenseType]);

    const onSubmit = useCallback(
        async (item: ISaveExpenseType) => {
            await saveExpenseType({ id, item: expenseTypeFromFormMapper(item) });
            navigate('/admin/expense-types');
        },
        [saveExpenseType, id, navigate],
    );

    const actions = useMemo(
        () => [
            <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)} disabled={isPending}>
                {t('save')}
            </Button>,
            <Button onClick={() => navigate('/admin/expense-types')} color="secondary">
                {t('cancel')}
            </Button>,
        ],
        [form, onSubmit, isPending, t, navigate],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            title={<Box width="300px">{id ? t('editExpenseType') : t('newExpenseType')}</Box>}
            actions={reversedActions}
            onBack={() => navigate('/admin/expense-types')}
            loading={isLoading}
        >
            <FormProvider {...form}>
                <FormCard handleSubmit={form.handleSubmit(onSubmit)} actions={actions}>
                    <ExpenseTypeInputs />
                </FormCard>
            </FormProvider>
        </Page>
    );
};
