import { FilterList } from '@mui/icons-material';
import { Checkbox, FormControlLabel, IconButton, Popover, Stack, Typography } from '@mui/material';
import { FC, ReactNode, useCallback, useMemo, useState } from 'react';

export interface Option {
    label: string;
    active?: boolean;
    type?: 'option' | 'title';
    hide?: boolean;
}

export type Options = Record<string, Option>;

interface Props {
    icon?: ReactNode;
    options: Options;
    onChange: (options: Options) => void;
}

export const OptionsToggle: FC<Props> = ({ icon = <FilterList />, options, onChange }) => {
    const [anchor, setAnchor] = useState<HTMLElement | null>();

    const hasFilterOptions = useMemo(
        () => Object.values(options).some(({ type = 'option' }) => type === 'option'),
        [options],
    );

    return (
        <>
            <IconButton aria-haspopup="true" onClick={({ currentTarget }) => setAnchor(currentTarget)}>
                {icon}
            </IconButton>
            {hasFilterOptions && (
                <Popover open={Boolean(anchor)} anchorEl={anchor} onClose={() => setAnchor(null)}>
                    <Typography sx={{ p: 2 }} component="div">
                        <Stack>
                            {Object.entries(options).map(([key, option]) => {
                                if (option.hide) return;
                                if (option.type === 'title') return <TitleOption key={key} option={option} />;
                                return (
                                    <FilterOption
                                        key={key}
                                        name={key}
                                        option={option}
                                        options={options}
                                        onChange={onChange}
                                    />
                                );
                            })}
                        </Stack>
                    </Typography>
                </Popover>
            )}
        </>
    );
};

interface TitleOptionProps {
    option: Option;
}

function TitleOption({ option }: TitleOptionProps) {
    return (
        <Typography variant="subtitle2" sx={{ fontVariant: 'all-small-caps' }}>
            {option.label}
        </Typography>
    );
}

interface FilterOptionProps {
    name: string;
    options: Options;
    option: Option;
    onChange: (options: Options) => void;
}

function FilterOption({ name, options, option, onChange }: FilterOptionProps) {
    const onCheckboxChange = useCallback(
        () =>
            onChange({
                ...options,
                [name]: { ...option, active: !option.active },
            }),
        [onChange, name, option, options],
    );

    return (
        <FormControlLabel
            label={option.label}
            control={<Checkbox checked={option.active} onChange={onCheckboxChange} />}
        />
    );
}
