import { createTheme, Theme, ThemeOptions } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { IEnvironment } from '../../shared';
import { createBaseTheme } from './base.theme';

const errorColor = '#aa1409';

export const createAntwerpTheme = (themeOptions: IEnvironment['theme']): Theme => {
    const palette = getPalette();
    const theme = createTheme({ palette });

    const typography = getTypography();
    const components = getComponents();

    return deepmerge(
        createBaseTheme(themeOptions, palette),
        createTheme(theme, {
            shape: { borderRadius: 0 },
            palette,
            typography,
            components,
        }),
    );
};

function getComponents() {
    return {
        MuiDataGrid: {
            styleOverrides: {
                columnHeaderTitle: {
                    fontWeight: 700,
                },
            },
        },
    };
}

function getPalette(): ThemeOptions['palette'] {
    return {
        primary: {
            main: '#0057b7',
            shade: '#E0EBF6',
        },
        secondary: {
            main: '#0057b7',
            shade: '#E0EBF6',
        },
        background: {
            default: '#FAFAFA',
            paper: '#FFF',
        },
        error: {
            main: errorColor,
            shade: '#aa14094d',
        },
    };
}

function getTypography(): ThemeOptions['typography'] {
    return {
        h1: { fontFamily: '"Sun Antwerpen", sans-serif', fontWeight: 700 },
        h2: { fontFamily: '"Sun Antwerpen", sans-serif', fontWeight: 700 },
        h3: { fontFamily: '"Sun Antwerpen", sans-serif', fontWeight: 700 },
        h4: { fontFamily: '"Sun Antwerpen", sans-serif', fontWeight: 700 },
        h5: { fontFamily: '"Sun Antwerpen", sans-serif', fontWeight: 700 },
        h6: { fontFamily: '"Sun Antwerpen", sans-serif', fontWeight: 700 },
        body1: { fontFamily: '"Sun Antwerpen", sans-serif' },
        body2: { fontFamily: '"Sun Antwerpen", sans-serif' },
        button: {
            fontFamily: '"Sun Antwerpen", sans-serif',
            textTransform: 'none',
            fontWeight: 700,
        },
        subtitle1: { fontFamily: '"Antwerpen Regular", sans-serif' },
        subtitle2: { fontFamily: '"Antwerpen Regular", sans-serif' },
    };
}
