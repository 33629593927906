import { format } from 'date-fns';
import { nl } from 'date-fns/locale';

export function formatDateString(date: string | undefined): string {
    return date ? format(new Date(date), 'dd/MM/yyyy') : '';
}

export function formatDateTimeString(date: Date | string | undefined): string {
    if (!date) return '';
    else if (date instanceof Date) return format(date, 'dd/MM/yyyy - HH:mm');
    else return format(new Date(date), 'dd/MM/yyyy - HH:mm');
}

export function formatEpoch(epoch: number): string {
    return epoch ? format(new Date(epoch), 'dd/MM/yyyy - HH:mm') : '';
}

export function formatMonth(month: number): string {
    return month ? format(new Date(2024, month, 1), 'LLLL', { locale: nl }) : '';
}
