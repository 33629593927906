export enum LivingSituation {
    Homeowner = 'HOMEOWNER',
    PrivateTenant = 'PRIVATE_TENANT',
    SocialTenant = 'SOCIAL_TENANT',
    LivingWithFamilyOrAcquaintance = 'LIVING_WITH_FAMILY_OR_ACQUAINTANCE',
    Homeless = 'HOMELESS',
    Cohousing = 'COHOUSING',
    Campings = 'CAMPINGS',
    CareHotel = 'CARE_HOTEL',
}
