import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { array, object, string } from 'yup';

export function useUserRoleSchema() {
    const { t } = useTranslation();

    return useMemo(
        () =>
            object().shape({
                organisationId: string().required(t('organisationRequired')),
                roles: array()
                    .of(string().required())
                    .test('len', t('minOneValueSelected'), (val) => !!val && val.length > 0)
                    .required(),
            }),
        [t],
    );
}
