import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IContact } from '../../../shared';
import { queryKeys } from '../../constants';

async function updateContacts(
    supportCase: string,
    data: { contacts: IContact[] },
    onUpdateSuccessMessage?: string,
    onUpdateErrorMessage?: string,
): Promise<IContact[]> {
    const result = await axios.put(`/support-api/support-cases/${supportCase}/contacts`, data, {
        successMessage: onUpdateSuccessMessage,
        errorMessage: onUpdateErrorMessage,
    });

    return result.data.contacts;
}

export const useUpdateContacts = (supportCase: string) => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation<IContact[], AxiosError, { contacts: IContact[] }>({
        mutationFn: (data) => updateContacts(supportCase, data, t('updateContactsSuccess'), t('updateContactsError')),

        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [queryKeys.SUPPORT_CASE, supportCase] });
        },
    });
};
