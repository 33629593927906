import React, { FC, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGetReferralOrganisations } from '../../../admin/hooks';
import {
    AutocompleteComponent,
    ControlledCheckbox,
    FormGrid,
    InputTextField,
    ISupportPeriod,
    Section,
    sortByName,
} from '../../../shared';
import { referrerContactFormatter } from '../../formatters';

interface Props {
    supportPeriod?: ISupportPeriod | null;
}

export const ReferrerInputs: FC<Props> = ({ supportPeriod }) => {
    const { t } = useTranslation();
    const { data: referralOrganisations } = useGetReferralOrganisations({ pageSize: 500 });

    const { watch, control, setValue } = useFormContext();
    const watchReferralOrganisation = watch('referralOrganisation');
    const watchNewContact = watch('isNewContact');

    useEffect(() => {
        if (watchReferralOrganisation === undefined) {
            setValue('contact', supportPeriod?.contact || null);
        } else if (watchReferralOrganisation?.id !== supportPeriod?.referralOrganisation?.id) {
            setValue('contact', null);
        }
    }, [watchReferralOrganisation, supportPeriod, setValue]);

    const referrerContacts = useMemo(() => {
        return (
            referralOrganisations?.data
                .find((org) => org.id === watchReferralOrganisation?.id)
                ?.contacts.sort(sortByName) || []
        );
    }, [referralOrganisations, watchReferralOrganisation]);

    return (
        <Section title={t('contact')}>
            <FormGrid xs={12} md={6}>
                <AutocompleteComponent
                    name="referralOrganisation"
                    label={t('referralOrganisation')}
                    control={control}
                    options={referralOrganisations?.data || []}
                    getOptionLabel={(option) => `${option.name}`}
                    defaultValue={null}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                />

                {!watchNewContact && (
                    <AutocompleteComponent
                        name="contact"
                        label={t('referrerContact')}
                        control={control}
                        options={referrerContacts}
                        getOptionLabel={(option) => referrerContactFormatter(option)}
                        defaultValue={null}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                    />
                )}
            </FormGrid>

            <ControlledCheckbox control={control} label={t('newContact')} name="isNewContact" />

            {watchNewContact && (
                <FormGrid xs={12} md={4}>
                    <InputTextField name={`newContact.name`} label={t('nameLabel')} control={control} key="name" />
                    <InputTextField name={`newContact.email`} label={t('emailLabel')} control={control} key="email" />
                    <InputTextField name={`newContact.phone`} label={t('phoneLabel')} control={control} key="phone" />
                </FormGrid>
            )}
        </Section>
    );
};
