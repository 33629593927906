import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { IOrganisation, queryKeys } from '../../../shared';

async function getOrganisation(id?: string, onGetErrorMessage?: string): Promise<IOrganisation> {
    const { data } = await axios.get(`/support-api/organisations/${id}`, {
        errorMessage: onGetErrorMessage,
    });
    return data;
}

export const useGetOrganisation = (id?: string) => {
    const { t } = useTranslation();
    return useQuery<IOrganisation, AxiosError>({
        queryKey: [queryKeys.ORGANISATION, id],
        queryFn: () => getOrganisation(id, t('getOrganisationError')),
        enabled: !!id,
    });
};
