import { ISupportType } from '../../models';
import { useGetSupportTypes } from './get-support-types.hook';

export function useSupportTypes(): ISupportType[] {
    const { data } = useGetSupportTypes({
        page: 1,
        pageSize: 100,
        sortBy: 'name',
        sortOrder: 'ASC',
    });
    return data?.data || [];
}
