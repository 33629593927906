import { IOrganisation, ISaveSupportPeriod, ISupportPeriod, ISupportPeriodForm } from '../../shared';
import { mapRequiredDateString } from './date-string.mapper';
import { add } from 'date-fns';

export function newSupportPeriodMapperToForm(organisation: IOrganisation | undefined, defaultSupportTypes: string[]) {
    return {
        startDate: new Date(),
        endDate: new Date(add(new Date(), { weeks: organisation?.defaultSupportPeriod })),
        supportTypesInfo: defaultSupportTypes?.map((id) => ({ supportTypeId: id })),
        organisation,
    };
}

export function supportPeriodMapperToForm(
    supportPeriod: ISupportPeriod,
    organisations: IOrganisation[],
): ISupportPeriodForm {
    return {
        ...supportPeriod,
        startDate: new Date(supportPeriod.startDate),
        endDate: new Date(supportPeriod.endDate),
        supportTypesInfo: supportPeriod.supportTypesInfo?.map((type) => ({ ...type, frequency: type.frequency || '' })),
        organisation: organisations.find((organisation) => organisation.id === supportPeriod.organisationId) || null,
        referralOrganisation: supportPeriod?.referralOrganisation || null,
        contact: supportPeriod?.contact || null,
        isNewContact: false,
    };
}

export function supportPeriodMapperFromForm(supportPeriod: ISupportPeriodForm): ISaveSupportPeriod {
    return {
        ...supportPeriod,
        startDate: mapRequiredDateString(supportPeriod.startDate),
        endDate: mapRequiredDateString(supportPeriod.endDate),
        supportTypesInfo: supportPeriod.supportTypesInfo?.map((type) => ({
            ...type,
            credit: type.credit || null,
            frequency: type.frequency || null,
        })),
        organisationId: supportPeriod.organisation?.id || null,
        referralOrganisation: supportPeriod.referralOrganisation?.id || null,
        contact: supportPeriod.contact?.id || null,
        isNewContact: supportPeriod.isNewContact,
        newContact:
            supportPeriod.referralOrganisation && supportPeriod.newContact
                ? { ...supportPeriod.newContact, organisation: supportPeriod.referralOrganisation.id }
                : null,
    };
}
