import React, { FC } from 'react';
import { FormCardSectionComponent, FormGrid, InputTextField } from '../../../shared';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

export const OrganisationAddressInputs: FC = () => {
    const { t } = useTranslation();
    const { control } = useFormContext();

    return (
        <FormCardSectionComponent title={t('addressLabel')}>
            <FormGrid xs={2}>
                <InputTextField name="address.street" label={t('streetLabel')} control={control} />
                <InputTextField name="address.number" label={t('numberLabel')} control={control} />
                <InputTextField name="address.box" label={t('boxLabel')} control={control} />
                <InputTextField name="address.postalCode" label={t('postalCodeLabel')} control={control} />
                <InputTextField name="address.city" label={t('cityLabel')} control={control} />
                <InputTextField name="address.country" label={t('countryLabel')} control={control} />
            </FormGrid>
        </FormCardSectionComponent>
    );
};
