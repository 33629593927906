import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { boolean, date, object, string } from 'yup';
import { baseSchema, isValidSocialSecurityNumber } from '../../shared';

export const useSupporteeSchema = () => {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                id: string().optional(),
                firstName: string()
                    .when('anonymous', ([anonymous], schema) => (!anonymous ? schema.required() : schema.optional()))
                    .optional(),
                lastName: string()
                    .when('anonymous', ([anonymous], schema) => (!anonymous ? schema.required() : schema.optional()))
                    .optional(),
                dateOfBirth: date().typeError(t('invalidDate')).nullable().optional(),
                nationalSecurityNumber: string()
                    .optional()
                    .test({
                        test: (value) => {
                            if (!value) return true;
                            return isValidSocialSecurityNumber(value);
                        },
                        message: t('invalidNationalSecurityNumber'),
                    }),
                gender: string().optional(),
                nationality: baseSchema().nullable().optional(),
                language: baseSchema().nullable().optional(),
                relation: string().nullable().optional(),
                anonymous: boolean().required(),
            }),
        [t],
    );
};
