import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import {
    CustomAttributeConfigurationQueryParam,
    ICustomAttributeConfiguration,
    ListModel,
    ListQueryParam,
    queryKeys,
} from '../../index';

async function getCustomAttributeConfigurations(
    params: ListQueryParam,
    onGetErrorMessage?: string,
): Promise<ListModel<ICustomAttributeConfiguration>> {
    const { data } = await axios.get(`/support-api/custom-attribute-configurations`, {
        params,
        errorMessage: onGetErrorMessage,
    });

    return data;
}

export const useGetCustomAttributeConfigurations = (params: CustomAttributeConfigurationQueryParam) => {
    return useQuery<ListModel<ICustomAttributeConfiguration>, AxiosError>({
        queryKey: [queryKeys.CUSTOM_ATTRIBUTE_CONFIGURATIONS, params],
        queryFn: () => getCustomAttributeConfigurations(params),
        enabled: !!params.organisationId && (params.pageSize == undefined || params.pageSize > 0),
    });
};
