import { Button } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Page, RowActions, SortOrder, Table, useQueryParamsContext } from '../../../shared';
import { useDeleteExpenseType, useExpenseTypesList } from '../../hooks';

export const ExpenseTypesPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        queryParams: { page, pageSize, sort },
        setPage,
        setPageSize,
        setSort,
    } = useQueryParamsContext('expense-types', {
        sort: [{ field: 'name', sort: 'asc' }],
    });

    const { data: expenseTypes, isPending } = useExpenseTypesList({
        page,
        pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
    });
    const { mutateAsync: deleteExpenseType } = useDeleteExpenseType();

    const columns: GridColDef[] = [
        { field: 'name', headerName: t('nameLabel'), flex: 1 },
        {
            field: ' ',
            headerName: '',
            width: 150,
            align: 'right',
            sortable: false,
            renderCell: ({ row: { id } }: GridCellParams) => (
                <RowActions
                    onEdit={() => navigate(`/admin/expense-types/${id}/edit`)}
                    onDelete={() => deleteExpenseType(id)}
                    deleteWarningTitle={t('expenseTypeDeleteWarningTitle')}
                    deleteWarningText={t('expenseTypeDeleteWarningText')}
                />
            ),
        },
    ];
    return (
        <Page
            title={t('expenseTypes')}
            actions={
                <Button component={Link} to="/admin/expense-types/new" color="primary" variant="contained">
                    {t('newExpenseType')}
                </Button>
            }
        >
            <Table
                rows={expenseTypes?.data || []}
                columns={columns}
                rowCount={expenseTypes?.pagination?.size || 0}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                loading={isPending}
                sortModel={sort}
                onSortChange={setSort}
            />
        </Page>
    );
};
