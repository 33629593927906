import { ListQueryParam } from '../models';
import { ReadOnlyClient } from './read-only.client';

export abstract class BaseClient<
    ResponseModel,
    DataModel,
    QueryModel extends ListQueryParam = ListQueryParam,
> extends ReadOnlyClient<ResponseModel, QueryModel> {
    public async createOne(item: DataModel): Promise<ResponseModel> {
        const { data } = await this.api.post<ResponseModel>(`${this.basePath}`, item);
        return data;
    }

    public async updateOne(id: string, item: DataModel): Promise<ResponseModel> {
        const { data } = await this.api.put<ResponseModel>(`${this.basePath}/${id}`, item);
        return data;
    }

    public async deleteOne(id: string): Promise<ResponseModel> {
        const { data } = await this.api.delete<ResponseModel>(`${this.basePath}/${id}`);
        return data;
    }
}
