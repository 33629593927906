import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { array, number, object, string } from 'yup';
import { addressSchema, contactSchema } from '../../shared';

export function useOrganisationSchema() {
    const { t } = useTranslation();

    return useMemo(
        () =>
            object().shape({
                id: string().optional(),
                name: string().required(),
                type: string().required(),
                address: addressSchema().optional(),
                contacts: array().of(contactSchema()).required(),
                supportTypes: array().of(string().required()).required(),
                defaultSupportPeriod: number().typeError(t('invalidNumber')).required(),
                preferredPostalCodes: array().of(string().required()).nullable().optional(),
            }),
        [t],
    );
}
