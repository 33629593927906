import { Button } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ISupportCase } from '../../../shared';
import { LinkCardModal } from '../link-card-modal/link-card-modal.component';

export const LinkCardAction: FC<{ supportCase: ISupportCase }> = ({ supportCase }) => {
    const { t } = useTranslation();
    const [linkCardModal, setLinkCardModal] = useState(false);

    return (
        <>
            <Button variant="contained" onClick={() => setLinkCardModal(true)} data-testid="link-card">
                {t('linkCard')}
            </Button>
            <LinkCardModal
                open={linkCardModal}
                handleClose={() => setLinkCardModal(false)}
                supportCase={supportCase.id}
            />
        </>
    );
};
