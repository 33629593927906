import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ISupportCase } from '../../../shared';

async function confirmSupportCase(
    supportCaseId: string,
    organisationId: string,
    successMessage: string,
    errorMessage: string,
): Promise<ISupportCase> {
    const { data } = await axios.post(
        `/support-api/support-cases/${supportCaseId}/confirm`,
        { organisationId },
        {
            successMessage,
            errorMessage,
        },
    );
    return data;
}

interface MutationData {
    supportCaseId: string;
    organisationId: string;
}

export const useConfirmSupportCase = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation<ISupportCase, AxiosError, MutationData>({
        mutationFn: ({ supportCaseId, organisationId }) =>
            confirmSupportCase(supportCaseId, organisationId, t('confirmSuccessMessage'), t('confirmErrorMessage')),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['support-case-info'] });
        },
    });
};
