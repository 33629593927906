import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ISupportType } from '../../../shared';
import { queryKeys } from '../../../shared';

async function deleteSupportType(
    id: string,
    onDeleteSuccessMessage?: string,
    onDeleteErrorMessage?: string,
): Promise<ISupportType> {
    const { data } = await axios.delete(`/support-api/support-types/${id}`, {
        successMessage: onDeleteSuccessMessage,
        errorMessage: onDeleteErrorMessage,
    });
    return data;
}

export const useDeleteSupportType = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    return useMutation<ISupportType, AxiosError, string>({
        mutationFn: (id) => deleteSupportType(id, t('deleteSupportTypeSuccess'), t('deleteSupportTypeError')),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [queryKeys.SUPPORT_TYPES] });
        },
    });
};
