import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { array, boolean, date, mixed, number, object, ref, string } from 'yup';
import { baseSchema, referrerContactSchema } from '../../shared';
import { Frequency } from '../enums';
import { supportTypesInfoSchema } from './support-type-info.validator';

export function useSupportPeriodSchema() {
    const { t } = useTranslation();

    return useMemo(
        () =>
            object().shape({
                id: string().optional(),
                startDate: date().typeError(t('startDateInvalid')).required(t('startDateRequired')),
                endDate: date()
                    .typeError(t('endDateInvalid'))
                    .min(ref('startDate'), t('endBeforeStartDateError'))
                    .required(t('endDateRequired')),
                supportTypesInfo: array()
                    .of(supportTypesInfoSchema(t))
                    .test('unique', t('supportTypeIdMustBeUnique'), (value) =>
                        value && value?.length > 0
                            ? value.map((type) => type.supportTypeId).length ===
                              new Set(value.map((type) => type.supportTypeId))?.size
                            : true,
                    )
                    .required(),
                organisation: object()
                    .shape({
                        id: string().required(),
                        name: string().required(),
                        supportTypes: array()
                            .of(object().shape({ id: string().required(), name: string().required() }))
                            .required(),
                        defaultSupportPeriod: number().required(),
                    })
                    .nullable(),
                referralOrganisation: baseSchema()
                    .shape({ id: string().required() })
                    .when('isNewContact', {
                        is: true,
                        then: (schema) => schema.required(t('referralOrganisationRequired')),
                    })
                    .nullable(),
                contact: referrerContactSchema().shape({ id: string().required() }).nullable(),
                isNewContact: boolean()
                    .when(
                        ['newContact.name', 'newContact.email', 'newContact.phone'],
                        ([name, email, phone], schema) =>
                            !name && !email && !phone
                                ? schema.test('Values-new-contact-empty', t('contactEmpty'), (value) => value === false)
                                : schema,
                    )
                    .required(),
                newContact: referrerContactSchema().nullable().optional(),
                frequency: mixed<Frequency>().oneOf(Object.values(Frequency)).nullable().optional(),
            }),
        [t],
    );
}
