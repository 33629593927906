import {
    CustomAttributeType,
    ICustomAttributeConfiguration,
    IOrganisation,
    IRegistration,
    IRegistrationForm,
} from '../index';

export const registrationFromFormMapper = (
    registration: IRegistrationForm,
    organisationId: string,
    attributes: ICustomAttributeConfiguration[],
): IRegistration => {
    return {
        ...registration,
        supportTypeId: registration.supportType.id || '',
        dateOfRegistration: registration.dateOfRegistration
            ? registration.dateOfRegistration.toISOString()
            : new Date().toISOString(),
        organisationId,
        attributes: attributes.map((attribute) => ({
            name: attribute.name,
            code: attribute.name,
            type: attribute.type,
            booleanValue: registration.attributes?.find(
                (item) => item.configurationId === attribute.id && attribute.type === CustomAttributeType.BOOLEAN,
            )?.booleanValue,
            amountValue: registration.attributes?.find(
                (item) => item.configurationId === attribute.id && attribute.type === CustomAttributeType.AMOUNT,
            )?.amountValue,
        })),
    };
};

export const registrationToFormMapper = (
    organisation: IOrganisation | undefined,
    customAttributeConfigurations: ICustomAttributeConfiguration[],
) => {
    return {
        supportType: organisation?.supportTypes[0],
        attributes:
            customAttributeConfigurations?.map(({ id, defaultSelected }) => ({
                configurationId: id,
                booleanValue: defaultSelected,
            })) || [],
    };
};
