import { yupResolver } from '@hookform/resolvers/yup';
import { Button, MenuItem } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    ControlledCheckbox,
    CustomAttributeType,
    FormCard,
    FormCardSectionComponent,
    FormGrid,
    IFormCustomAttributeConfiguration,
    InputTextField,
    IParams,
    Page,
    ControlledSelect,
    useSelectedOrganisation,
} from '../../../shared';
import { useGetCustomAttributeConfiguration, useSaveCustomAttributeConfiguration } from '../../hooks';
import { useCustomAttributeConfigurationSchema } from '../../validators';

export const CustomAttributeConfigurationEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams<keyof IParams>() as IParams;
    const { organisation } = useSelectedOrganisation();

    const { data: customAttributeConfiguration } = useGetCustomAttributeConfiguration(id);
    const { mutateAsync: saveCustomAttributeConfiguration } = useSaveCustomAttributeConfiguration();

    const form = useForm<IFormCustomAttributeConfiguration>({
        resolver: yupResolver(useCustomAttributeConfigurationSchema()),
        mode: 'all',
    });

    useEffect(() => {
        if (customAttributeConfiguration) {
            form.reset(customAttributeConfiguration);
        } else {
            form.reset({ type: Object.values(CustomAttributeType)[0] });
        }
    }, [customAttributeConfiguration]);

    const onSubmit = useCallback(
        async (data: IFormCustomAttributeConfiguration) => {
            if (organisation) {
                await saveCustomAttributeConfiguration({
                    ...data,
                    organisationId: organisation.id,
                    defaultSelected: data.type === CustomAttributeType.BOOLEAN ? data.defaultSelected : false,
                });
                navigate('/admin/custom-attribute-configurations');
            }
        },
        [organisation],
    );

    const actions = useMemo(() => {
        return [
            <Button onClick={() => navigate('/admin/custom-attribute-configurations')} color="secondary">
                {t('cancel')}
            </Button>,
            <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)}>
                {t('save')}
            </Button>,
        ];
    }, []);

    return (
        <Page title={customAttributeConfiguration?.name || t('newCustomAttributeConfiguration')} actions={actions}>
            <FormCard handleSubmit={form.handleSubmit(onSubmit)} actions={actions.slice().reverse()}>
                <FormCardSectionComponent title={t('data')}>
                    <FormGrid>
                        <InputTextField
                            name="name"
                            label={t('nameLabel')}
                            control={form.control}
                            required={true}
                            sx={{ width: '400px' }}
                        />
                        <InputTextField name="code" label={t('codeLabel')} control={form.control} required={true} />
                        <ControlledSelect name="type" label={t('typeLabel')} control={form.control} required={true}>
                            {Object.values(CustomAttributeType).map((type) => (
                                <MenuItem value={type} key={type}>
                                    {t(type)}
                                </MenuItem>
                            ))}
                        </ControlledSelect>
                    </FormGrid>
                    {form.watch('type') === CustomAttributeType.BOOLEAN && (
                        <ControlledCheckbox
                            name="defaultSelected"
                            control={form.control}
                            label={t('defaultSelectedLabel')}
                        />
                    )}
                </FormCardSectionComponent>
            </FormCard>
        </Page>
    );
};
