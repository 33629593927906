import { Autocomplete, TextField } from '@mui/material';
import { AutocompleteProps, createFilterOptions } from '@mui/material/Autocomplete/Autocomplete';
import { Controller, FieldError } from 'react-hook-form';

interface Props<T, Multiple extends boolean | undefined, FreeSolo extends boolean | undefined>
    extends Omit<AutocompleteProps<T, Multiple, undefined, FreeSolo>, 'renderInput'> {
    name: string;
    label?: string;
    placeholder?: string;
    defaultValue: any;
    minWidth?: number;
}

export function ControlledAutocomplete<
    T,
    Multiple extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined,
>({ name, label, placeholder, defaultValue, minWidth = 227, ...autoCompleteProps }: Props<T, Multiple, FreeSolo>) {
    return (
        <Controller
            name={name}
            defaultValue={defaultValue}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Autocomplete
                    {...autoCompleteProps}
                    id={`${name}-field`}
                    sx={{
                        minWidth: minWidth,
                        '.MuiInputBase-root': {
                            height: 'auto',
                        },
                    }}
                    autoComplete={false}
                    onChange={(e, data) => onChange(data)}
                    value={value}
                    filterOptions={createFilterOptions({
                        ignoreCase: true,
                    })}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            placeholder={placeholder}
                            InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
                            error={!!error}
                            helperText={
                                error
                                    ? autoCompleteProps.multiple
                                        ? (error as unknown as FieldError[]).filter((error) => !!error)[0]?.message
                                        : error.message
                                    : null
                            }
                        />
                    )}
                />
            )}
        />
    );
}
