import { Button } from '@mui/material';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '../../../shared';
import { useNoDuplicateSupportCase } from '../../hooks';

interface Props {
    subjectId: string;
    targetId: string;
    onClose: () => void;
}

export const NoDuplicateSupportCase: FC<Props> = ({ subjectId, targetId, onClose }) => {
    const { t } = useTranslation();
    const { mutateAsync: markAsNoDuplicate } = useNoDuplicateSupportCase();

    const onMerge = useCallback(async () => {
        await markAsNoDuplicate({ subjectId: subjectId, targetId: targetId });

        onClose();
    }, [subjectId, targetId]);

    return (
        <ConfirmModal
            button={
                <Button variant="outlined" size="small" color="warning">
                    {t('markAsNoDuplicate')}
                </Button>
            }
            title={t('markAsNoDuplicate')}
            text={t('markAsNoDuplicateConfirmation')}
            onConfirm={onMerge}
        />
    );
};
