import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IOrganisation } from '../../../shared';
import { queryKeys } from '../../../shared';

async function deleteOrganisation(
    id: string,
    onDeleteSuccessMessage?: string,
    onDeleteErrorMessage?: string,
): Promise<IOrganisation> {
    const { data } = await axios.delete(`/support-api/organisations/${id}`, {
        successMessage: onDeleteSuccessMessage,
        errorMessage: onDeleteErrorMessage,
    });
    return data;
}

export const useDeleteOrganisation = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation<IOrganisation, AxiosError, string>({
        mutationFn: (id) => deleteOrganisation(id, t('deleteOrganisationSuccess'), t('deleteOrganisationError')),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [queryKeys.ORGANISATIONS] });
        },
    });
};
