import { FormControl, FormHelperText, InputLabel, OutlinedInput, Select } from '@mui/material';
import { ComponentProps, FC, ReactNode } from 'react';
import { Control, Controller } from 'react-hook-form';

type Props = ComponentProps<typeof Select> & {
    name: string;
    label: string;
    control: Control<any>;
    children: ReactNode;
    minWidth?: number;
    items?: string[];
};

export const ControlledSelect: FC<Props> = ({
    name,
    required,
    label,
    control,
    children,
    minWidth = 227,
    items = [],
    ...selectProps
}) => {
    const handleOnChangeMultipleSelect = (e: any) => {
        if (e.target.value.includes('all')) {
            if (e.target.value.length === items.length + 1) {
                e.target.value = [];
            } else {
                e.target.value = items;
            }
        }
        return e;
    };
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={selectProps.multiple ? [] : ''}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
                return (
                    <FormControl sx={{ minWidth: 195 }} error={!!error}>
                        <InputLabel id={name} required={required}>
                            {label}
                        </InputLabel>
                        <Select
                            sx={{ minWidth: minWidth }}
                            onChange={
                                !selectProps.multiple ? onChange : (e) => onChange(handleOnChangeMultipleSelect(e))
                            }
                            value={value}
                            id={`${name}-select`}
                            required={required}
                            input={<OutlinedInput notched label={label} />}
                            {...selectProps}
                        >
                            {children}
                        </Select>
                        {error && (
                            <FormHelperText id={`${name}-field-helper-text`}>
                                {error ? error.message : null}
                            </FormHelperText>
                        )}
                    </FormControl>
                );
            }}
        />
    );
};
