import React, { FC, useMemo } from 'react';
import { AutocompleteComponent, ControlledDatePicker, FormGrid, IOrganisation, Section } from '../../../shared';
import { Frequency } from '../../enums';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

export interface Props {
    organisations: IOrganisation[];
}

export const SupportPeriodGeneralInputs: FC<Props> = ({ organisations }) => {
    const { t } = useTranslation();
    const form = useFormContext();

    const organisationsWithSupportTypes = useMemo(() => {
        return organisations.filter((organisation) => organisation.supportTypes?.length > 0) || [];
    }, [organisations]);

    return (
        <Section title={t('general')}>
            <FormGrid xs={12} md={6}>
                <ControlledDatePicker name="startDate" control={form.control} label={t('startDateLabel')} />
                <ControlledDatePicker name="endDate" control={form.control} label={t('endDateLabel')} />

                <AutocompleteComponent
                    name="organisation"
                    label={t('preferredOrganisation')}
                    control={form.control}
                    options={organisationsWithSupportTypes}
                    getOptionLabel={(option) => `${option.name} - ${option.type.name}`}
                    defaultValue={null}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                />
                <AutocompleteComponent
                    name={`frequency`}
                    label={t('frequencyLabel')}
                    control={form.control}
                    options={Object.values(Frequency)}
                    getOptionLabel={(option) => t(option)}
                    defaultValue={null}
                />
            </FormGrid>
        </Section>
    );
};
