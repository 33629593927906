import { ComponentProps } from 'react';
import { GlobalStyles, Palette } from '@mui/material';
import { rgba } from 'polished';

export function getGlobalStyles(palette: Palette): ComponentProps<typeof GlobalStyles>['styles'] {
    return {
        '.MuiDataGrid-root': {
            background: palette.background.paper,
            '.report-total-row': { background: rgba(0, 0, 0, 0.05) },
        },
        '.MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': { display: 'none' },
    };
}
