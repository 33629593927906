import { WarningTwoTone } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Widget } from '../../../shared';

interface Props {
    code: string;
    onClick: () => void;
}

export const NoSupportCaseFound = ({ code, onClick }: Props) => {
    const { t } = useTranslation();

    return (
        <Widget sx={{ alignItems: 'center', textAlign: 'center', p: 4 }}>
            <WarningTwoTone sx={{ fontSize: 80 }} color="primary" />
            <Typography variant="h6">{t('noSupportCaseFound')}</Typography>
            <Box sx={{ mb: 2 }}>
                {code && (
                    <>
                        {t('noSupportCaseFoundForCode')} <b>{code}</b>.
                    </>
                )}
            </Box>
            <Button variant="outlined" onClick={onClick} data-testid="back-btn">
                {t('backToRegistrationPage')}
            </Button>
        </Widget>
    );
};
