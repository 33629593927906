import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { IOrganisationType, queryKeys } from '../../../shared';

async function getOrganisationType(id: string, onGetErrorMessage?: string): Promise<IOrganisationType> {
    const { data } = await axios.get(`/support-api/organisation-types/${id}`, {
        errorMessage: onGetErrorMessage,
    });
    return data;
}

export const useGetOrganisationType = (id: string) => {
    const { t } = useTranslation();

    return useQuery<IOrganisationType, AxiosError>({
        queryKey: [queryKeys.ORGANISATION_TYPE, id],
        queryFn: () => getOrganisationType(id, t('getOrganisationTypeError')),
        enabled: !!id,
    });
};
