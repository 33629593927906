import React, { FC } from 'react';
import { IInfoSupportee, ISupportCaseInfo } from '../../models';
import { useTranslation } from 'react-i18next';
import { FullName } from '../../../support/components';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import { ISupportee, NoRowsOverlay, Widget } from '../../../shared';
import { NoMeals } from '@mui/icons-material';
import { useSupportCaseFoodRestrictionsMapper } from '../../../support/mappers';

interface Props {
    supportCase: ISupportCaseInfo;
}

export const RegistrationFoodRestrictions: FC<Props> = ({ supportCase }) => {
    const { t } = useTranslation();
    const mappedRestrictions = useSupportCaseFoodRestrictionsMapper(supportCase);

    const columns: GridColDef[] = [
        {
            field: 'supportee',
            headerName: t('supportee'),
            minWidth: 100,
            flex: 1,
            sortable: false,
            renderCell: ({ row: { supportee } }: GridCellParams<{ supportee?: ISupportee | IInfoSupportee }>) =>
                supportee ? <FullName data={supportee} /> : <>{t('all')}</>,
        },
        { field: 'foodRestriction', headerName: t('foodRestriction'), minWidth: 100, flex: 1, sortable: false },
    ];

    return (
        <>
            {!!supportCase.foodRestrictions?.length && (
                <Widget icon={<NoMeals color="primary" />} title={t('foodRestrictions')} hasTable>
                    <DataGrid
                        rows={mappedRestrictions || []}
                        columns={columns}
                        disableColumnFilter
                        disableColumnMenu
                        columnHeaderHeight={40}
                        rowHeight={45}
                        hideFooter
                        slots={{ noRowsOverlay: NoRowsOverlay }}
                    />
                </Widget>
            )}
        </>
    );
};
