import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { IOrganisation, ListModel, SupportCaseListQueryParam } from '../../index';
import { queryKeys } from '../../constants';

async function getOrganisations(
    params: SupportCaseListQueryParam,
    onGetErrorMessage?: string,
): Promise<ListModel<IOrganisation>> {
    const { data } = await axios.get('/support-api/organisations', { params, errorMessage: onGetErrorMessage });
    return data;
}

export const useGetOrganisations = (params: SupportCaseListQueryParam) => {
    const { t } = useTranslation();
    return useQuery<ListModel<IOrganisation>, AxiosError>({
        queryKey: [queryKeys.ORGANISATIONS, params],
        queryFn: () => getOrganisations(params, t('getOrganisationsError')),
        enabled: params.pageSize == undefined || params.pageSize > 0,
    });
};
