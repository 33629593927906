import { Button, Chip, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ConfirmModal,
    formatDateTimeString,
    PermissionKeys,
    useHasPermission,
    useSelectedOrganisation,
} from '../../../shared';
import { useDeleteComment } from '../../hooks';
import { IComment } from '../../models';
import { SaveCommentComponent } from '../save-comment/save-comment.component';

interface Props {
    supportCase: string;
    comment: IComment;
}

export const CommentComponent: FC<Props> = ({ supportCase, comment }) => {
    const { t } = useTranslation();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.SUPPORT_CASE_COMMENTS_WRITE);
    const { organisation } = useSelectedOrganisation();
    const [editMode, setEditMode] = useState(false);
    const { mutateAsync: deleteComment } = useDeleteComment();

    return (
        <>
            <ListItem
                data-testid="support-case-comment"
                alignItems="flex-start"
                sx={{
                    width: '100%',
                    '&:hover .update-btns': {
                        visibility: 'visible',
                    },
                }}
            >
                <ListItemAvatar sx={{ width: '5rem' }} hidden={editMode}>
                    <Chip
                        sx={{ fontSize: '0.6125rem' }}
                        size="small"
                        label={comment.external ? t('external').toUpperCase() : t('internal').toUpperCase()}
                        variant={comment.external ? 'outlined' : 'filled'}
                        color="primary"
                    />
                </ListItemAvatar>
                <ListItemText
                    hidden={editMode}
                    primary={
                        <>
                            {comment.message}{' '}
                            {comment.updatedAt && (
                                <Tooltip title={formatDateTimeString(comment.updatedAt)} placement="top" arrow>
                                    <Typography sx={{ color: 'gray' }} component={'span'} variant="caption">
                                        ({t('edited')})
                                    </Typography>
                                </Tooltip>
                            )}
                        </>
                    }
                    secondary={
                        <>
                            <Typography
                                sx={{
                                    paddingRight: '4px',
                                }}
                                component={'span'}
                                variant={'body2'}
                            >
                                {comment.organisation?.name} ({comment.createdBy}){' · '}
                                {formatDateTimeString(comment.timestamp)}
                            </Typography>
                            {hasWritePermission && organisation?.id === comment.organisation?.id && (
                                <Typography
                                    className="update-btns"
                                    component={'span'}
                                    variant={'body2'}
                                    sx={{ visibility: 'hidden' }}
                                >
                                    {' ·'}
                                    <Button
                                        size="small"
                                        sx={{ paddingTop: '2px' }}
                                        onClick={() => setEditMode(true)}
                                        data-testid={`edit-${comment.id}`}
                                    >
                                        {t('edit')}
                                    </Button>
                                    {'·'}
                                    <ConfirmModal
                                        onConfirm={() => deleteComment({ supportCase, comment: comment.id as string })}
                                        button={
                                            <Button
                                                size="small"
                                                sx={{ paddingTop: '2px' }}
                                                data-testid={`delete-${comment.id}`}
                                            >
                                                {t('delete')}
                                            </Button>
                                        }
                                        title={t('commentDeleteWarningTitle')}
                                        text={
                                            <>
                                                {t('commentDeleteWarningText')}
                                                <br />
                                                <b>
                                                    {comment.message} ({formatDateTimeString(comment.timestamp)})
                                                </b>
                                            </>
                                        }
                                    />
                                </Typography>
                            )}
                        </>
                    }
                />
                <ListItemText hidden={!editMode}>
                    <SaveCommentComponent
                        supportCase={supportCase}
                        comment={comment}
                        handleClose={() => setEditMode(false)}
                    />
                </ListItemText>
            </ListItem>
        </>
    );
};
