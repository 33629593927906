import { Button } from '@mui/material';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ISupportCase, ConfirmModal } from '../../../shared';
import { useDeleteSupportCase } from '../../hooks';

export const DeleteSupportCaseAction: FC<{ supportCase: ISupportCase }> = ({ supportCase }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { mutateAsync: deleteSupportCase } = useDeleteSupportCase();

    const onDelete = useCallback(async () => {
        await deleteSupportCase(supportCase.id);
        navigate('/support');
    }, [deleteSupportCase, navigate, supportCase.id]);

    return (
        <ConfirmModal
            onConfirm={onDelete}
            button={
                <Button color="primary" variant="outlined" data-testid="deleteSupportCase">
                    {t('delete')}
                </Button>
            }
            title={t('supportCaseDeleteWarningTitle')}
            text={t('supportCaseDeleteWarningText')}
        />
    );
};
