import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { ISupportPeriod, ListModel, ListQueryParam } from '../../../shared';
import { queryKeys } from '../../constants';

async function getSupportPeriods(
    caseId: string,
    params: ListQueryParam,
    errorMessage?: string,
): Promise<ListModel<ISupportPeriod>> {
    const { data } = await axios.get(`/support-api/support-cases/${caseId}/support-periods`, {
        params,
        errorMessage: errorMessage,
    });
    return data;
}

export const useGetSupportPeriods = (caseId: string, params: ListQueryParam) => {
    const { t } = useTranslation();

    return useQuery<ListModel<ISupportPeriod>, AxiosError>({
        queryKey: [caseId, queryKeys.SUPPORT_PERIODS, params],
        queryFn: () => getSupportPeriods(caseId, params, t('getSupportPeriodsError')),
        enabled: !!caseId,
    });
};
