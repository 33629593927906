import { IRouteConfig, PermissionKeys } from '../shared';
import { InfoSupportCasePage, StartRegistrationPage } from './pages';

export const registrationRoutes: IRouteConfig[] = [
    {
        path: '/registration',
        component: StartRegistrationPage,
        requiredPermissions: PermissionKeys.REGISTRATIONS_WRITE,
    },
    {
        path: '/registration/info',
        component: InfoSupportCasePage,
        requiredPermissions: PermissionKeys.REGISTRATIONS_WRITE,
    },
];
