import { IRouteConfig, IRouteConfigWithComponent, PermissionKeys } from '../shared';
import {
    SupportCaseCardsPage,
    SupportCaseCommentsPage,
    SupportCaseCreatePage,
    SupportCaseDetailsPage,
    SupportCaseIntakeWizardPage,
    SupportCaseOrganisationsPage,
    SupportCasePage,
    SupportCaseRegistrationsPage,
    SupportCasesPage,
    SupportPeriodsPage,
} from './pages';
import { SupportCaseIntakesPage } from './pages/support-case-intakes/support-case-intakes.page';

export const supportRoutes: IRouteConfig[] = [
    {
        path: '/support',
        redirect: '/support/support-cases',
    },
    {
        path: '/support/support-cases',
        component: SupportCasesPage,
        requiredPermissions: PermissionKeys.SUPPORT_CASES_READ,
    },
    {
        path: '/support/support-cases/new',
        component: SupportCaseCreatePage,
        requiredPermissions: PermissionKeys.SUPPORT_CASES_WRITE,
    },
    {
        path: '/support/support-cases/:id',
        redirect: '/support/support-cases/:id/detail',
    },
    {
        path: '/support/support-cases/:id/*',
        component: SupportCasePage,
    },
];

export const supportCaseRoutes: IRouteConfigWithComponent[] = [
    {
        path: '/detail',
        component: SupportCaseDetailsPage,
        requiredPermissions: PermissionKeys.SUPPORT_CASES_READ,
    },
    {
        path: '/organisations',
        component: SupportCaseOrganisationsPage,
        requiredPermissions: PermissionKeys.SUPPORT_CASES_READ,
    },
    {
        path: '/support-periods',
        component: SupportPeriodsPage,
        requiredPermissions: PermissionKeys.SUPPORT_CASES_READ,
    },
    {
        path: '/registrations',
        component: SupportCaseRegistrationsPage,
        requiredPermissions: PermissionKeys.SUPPORT_CASES_READ,
    },
    {
        path: '/comments',
        component: SupportCaseCommentsPage,
        requiredPermissions: PermissionKeys.SUPPORT_CASE_COMMENTS_READ,
    },
    {
        path: '/cards',
        component: SupportCaseCardsPage,
        requiredPermissions: PermissionKeys.SUPPORT_CASES_READ,
    },
    { path: '/intakes', component: SupportCaseIntakesPage, requiredPermissions: PermissionKeys.INTAKES_READ },
    {
        path: '/intakes/new/*',
        component: SupportCaseIntakeWizardPage,
        requiredPermissions: PermissionKeys.INTAKES_WRITE,
    },
    {
        path: '/intakes/:intakeId/*',
        component: SupportCaseIntakeWizardPage,
        requiredPermissions: PermissionKeys.INTAKES_WRITE,
    },
];
