import { Box, Stack, Typography } from '@mui/material';
import React, { FC, ReactElement } from 'react';

interface Props {
    label: ReactElement | string;
    value?: ReactElement | string;
}

export const LabelValue: FC<Props> = ({ label, value }) => {
    return (
        <Stack sx={{ mb: 1 }}>
            <Typography variant="caption">{label}</Typography>
            {value ? (
                <>{value instanceof String ? <Typography>{value || '-'}</Typography> : <Box>{value}</Box>}</>
            ) : (
                <Typography>{'-'}</Typography>
            )}
        </Stack>
    );
};
