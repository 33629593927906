import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { AUTH_API_BASE_URL, IUser, queryKeys } from '../../../shared';

async function getUser(id: string, onGetErrorMessage?: string): Promise<IUser> {
    const { data } = await axios.get(`${AUTH_API_BASE_URL}/v1/users/${id}`, {
        errorMessage: onGetErrorMessage,
    });
    return data;
}

export const useGetUser = (id: string) => {
    const { t } = useTranslation();
    return useQuery<IUser, AxiosError>({
        queryKey: [queryKeys.USER, id],
        queryFn: () => getUser(id, t('getUserError')),
        enabled: !!id,
    });
};
