import { useMemo } from 'react';
import { boolean, object, string } from 'yup';
import { useTranslation } from 'react-i18next';

export function useUserSchema() {
    const { t } = useTranslation();

    return useMemo(
        () =>
            object().shape({
                email: string().email(t('invalidEmail')).required(),
                firstName: string().required(),
                lastName: string().required(),
                enabled: boolean().required(),
            }),
        [t],
    );
}
