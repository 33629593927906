import { Delete } from '@mui/icons-material';
import { Alert, Button, IconButton, Stack } from '@mui/material';
import React, { FC } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ISupportType, Section } from '../../../shared';
import { SupportTypesInfoInputs } from './support-types-info-inputs.component';

interface Props {
    supportTypes: ISupportType[];
    defaultSupportTypes: string[];
}

export const SupportTypesInfo: FC<Props> = ({ supportTypes, defaultSupportTypes }) => {
    const { t } = useTranslation();
    const { control, formState } = useFormContext();

    const {
        fields: supportTypesInfo,
        append,
        remove,
    } = useFieldArray({
        control: control,
        name: 'supportTypesInfo',
    });

    return (
        <Section title={t('supportTypes')}>
            {supportTypesInfo.map((supportTypeInfo, index) => (
                <Stack key={supportTypeInfo.id} direction="row" spacing={2} sx={{ pt: 2 }}>
                    <SupportTypesInfoInputs path={`supportTypesInfo[${index}]`} supportTypes={supportTypes} />
                    <IconButton onClick={() => remove(index)}>
                        <Delete />
                    </IconButton>
                </Stack>
            ))}

            {formState.errors.supportTypesInfo?.type === 'unique' && (
                <Alert icon={false} severity="error">
                    {formState?.errors.supportTypesInfo.message?.toString()}
                </Alert>
            )}

            <Button
                onClick={() => append({ supportTypeId: defaultSupportTypes[0], frequency: '' })}
                color="secondary"
                sx={{ justifyContent: 'left' }}
            >
                + {t('supportTypesAdd')}
            </Button>
        </Section>
    );
};
