import * as languages from '@cospired/i18n-iso-languages';
import languagesEN from '@cospired/i18n-iso-languages/langs/en.json';
import languagesNL from '@cospired/i18n-iso-languages/langs/nl.json';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import * as countries from 'i18n-iso-countries';
import countriesEN from 'i18n-iso-countries/langs/en.json';
import countriesNL from 'i18n-iso-countries/langs/nl.json';
import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { CarityTheme, Main, Statusbar } from './main';
import './translations/i18n';
import { setDefaultOptions } from 'date-fns';
import { nlBE } from 'date-fns/locale';
import { QueryParamsContextProvider } from './shared';

languages.registerLocale(languagesEN);
languages.registerLocale(languagesNL);
countries.registerLocale(countriesEN);
countries.registerLocale(countriesNL);

const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

export function App() {
    setDefaultOptions({ locale: nlBE });

    return (
        <CarityTheme>
            <QueryParamsContextProvider>
                <QueryClientProvider client={queryClient}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Main />
                        <Statusbar />
                    </LocalizationProvider>
                </QueryClientProvider>
            </QueryParamsContextProvider>
        </CarityTheme>
    );
}
