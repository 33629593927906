import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ISaveSupportPeriod, ISupportPeriod } from '../../../shared';
import { queryKeys } from '../../constants';

async function saveSupportPeriod(
    caseId: string,
    supportPeriod: ISaveSupportPeriod,
    onUpdateSuccessMessage?: string,
    onUpdateErrorMessage?: string,
    onCreateSuccessMessage?: string,
    onCreateErrorMessage?: string,
): Promise<ISupportPeriod> {
    let result;
    if (supportPeriod.id) {
        result = await axios.put(
            `/support-api/support-cases/${caseId}/support-periods/${supportPeriod.id}`,
            supportPeriod,
            {
                successMessage: onUpdateSuccessMessage,
                errorMessage: onUpdateErrorMessage,
            },
        );
    } else {
        result = await axios.post(`/support-api/support-cases/${caseId}/support-periods`, supportPeriod, {
            successMessage: onCreateSuccessMessage,
            errorMessage: onCreateErrorMessage,
        });
    }

    return result.data;
}

export const useSaveSupportPeriod = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation<ISupportPeriod, AxiosError, { supportCase: string; supportPeriod: ISaveSupportPeriod }>({
        mutationFn: (data) =>
            saveSupportPeriod(
                data.supportCase,
                data.supportPeriod,
                t('updateSupportPeriodSuccess'),
                t('updateSupportPeriodError'),
                t('createSupportPeriodSuccess'),
                t('createSupportPeriodError'),
            ),

        onSuccess: async (data, variables) => {
            await queryClient.invalidateQueries({ queryKey: [variables.supportCase, queryKeys.SUPPORT_PERIODS] });
            await queryClient.invalidateQueries({
                queryKey: [queryKeys.SUPPORT_CASES_DUPLICATES, variables.supportCase],
            });
        },
    });
};
