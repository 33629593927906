import { InputAdornment, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { FormCardSectionComponent, IExpense } from '../../../shared';
import { Info } from '@mui/icons-material';
import { useExpenseTypesList } from '../../../admin/hooks';

interface Props {
    lumpSumExpenses: IExpense[];
}
export const LumpSumExpenses: FC<Props> = ({ lumpSumExpenses }) => {
    const { t } = useTranslation();
    const { data: expenses } = useExpenseTypesList({ pageSize: 1000 });

    function mapExpense(input: IExpense): string {
        const expense = expenses?.data.find((expense) => expense.id === input.expenseTypeId);
        if (expense) {
            let lumpSums = ``;
            expense.lumpSums?.forEach(
                (lumpSum) =>
                    (lumpSums += `\n${t('startAge')}: ${lumpSum.startAge}, ${t('genderLabel')}: ${t(lumpSum.gender.toLowerCase())}, ${t('amountPerPerson')}: €${lumpSum.amountPerPerson}`),
            );
            return `${t('baseLumpSum')}: €${expense.baseLumpSum}${lumpSums}`;
        }
        return '';
    }

    return (
        <FormCardSectionComponent title={t('lumpSumExpenses')}>
            {lumpSumExpenses.map((expense) => (
                <Fragment key={expense.expenseTypeId}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Tooltip
                            title={
                                <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                                    {mapExpense(expense)}
                                </Typography>
                            }
                            componentsProps={{ tooltip: { style: { maxWidth: 'none' } } }}
                            placement="right"
                            arrow
                        >
                            <Info color="primary" />
                        </Tooltip>
                        <TextField
                            label={expense.expenseTypeName}
                            value={expense.amount}
                            slotProps={{
                                input: {
                                    sx: { maxWidth: '400px' },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Typography>€</Typography>
                                        </InputAdornment>
                                    ),
                                },
                            }}
                            disabled
                        />
                    </Stack>
                </Fragment>
            ))}
        </FormCardSectionComponent>
    );
};
