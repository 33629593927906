import axios, { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { ICustomAttributeConfiguration, queryKeys } from '../../../shared';

async function getCustomAttributeConfiguration(
    id: string,
    onGetErrorMessage?: string,
): Promise<ICustomAttributeConfiguration> {
    const { data } = await axios.get(`/support-api/custom-attribute-configurations/${id}`, {
        errorMessage: onGetErrorMessage,
    });

    return data;
}

export const useGetCustomAttributeConfiguration = (id: string) => {
    return useQuery<ICustomAttributeConfiguration, AxiosError>({
        queryKey: [queryKeys.CUSTOM_ATTRIBUTE_CONFIGURATION, id],
        queryFn: () => getCustomAttributeConfiguration(id),
        enabled: !!id,
    });
};
