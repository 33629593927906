export const isValidSocialSecurityNumber = (RR: string, bornAfter2000Check = false): boolean => {
    if (RR.length !== 0) {
        const base = parseInt(
            RR.replace(/\./g, '')
                .replace('-', '')
                .slice(0, bornAfter2000Check ? 10 : 9),
            10,
        );
        const check = parseInt(RR.slice(-2), 10);
        const mod = 97 - (base % 97);
        if (mod !== check && !bornAfter2000Check) {
            return isValidSocialSecurityNumber(`2${RR}`, true);
        }
        return mod === check;
    }
    return true;
};
