import { Edit, HomeTwoTone } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ISupportCase, PermissionKeys, useHasPermission, Widget } from '../../../shared';
import { useAddressFormatter } from '../../formatters';
import { UpdateResidenceModal } from '../update-residence-modal/update-residence-modal.component';

export const Residence: FC<{ supportCase: ISupportCase }> = ({ supportCase }) => {
    const { t } = useTranslation();

    const [showAddressModal, setShowAddressModal] = useState(false);
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.SUPPORT_CASES_WRITE);

    const { address } = supportCase;

    const addressText = useAddressFormatter(supportCase.address);

    return (
        <>
            <Widget
                icon={<HomeTwoTone color="primary" />}
                title={t('residence')}
                actions={
                    hasWritePermission && (
                        <IconButton onClick={() => setShowAddressModal(true)} data-testid="edit-address">
                            <Edit />
                        </IconButton>
                    )
                }
            >
                <Stack sx={{ mb: 1 }}>
                    <Typography variant="caption">{t('familySituation')}</Typography>
                    <Typography>
                        {(supportCase.familySituation ? t(supportCase.familySituation) : '') ||
                            t('emptyStateFamilySituation')}
                    </Typography>
                </Stack>
                <Stack sx={{ mb: 1 }}>
                    <Typography variant="caption">{t('livingSituation')}</Typography>
                    <Typography>
                        {(supportCase.livingSituation ? t(supportCase.livingSituation) : '') ||
                            t('emptyStateLivingSituation')}
                    </Typography>
                </Stack>
                <Stack>
                    <Typography variant="caption">{t('addressLabel')}</Typography>
                    <Typography>
                        {!address.postalCode && !address.street ? (
                            t('emptyStateAddress')
                        ) : (
                            <span data-testid="address">{addressText}</span>
                        )}
                    </Typography>
                </Stack>
            </Widget>
            <UpdateResidenceModal
                open={showAddressModal}
                supportCaseId={supportCase.id}
                address={{
                    ...address,
                    street: address.street || '',
                    number: address.number || '',
                    box: address.box || '',
                    postalCode: address.postalCode || '',
                    city: address.city || '',
                    country: address.country || '',
                    livingSituation: supportCase.livingSituation,
                    familySituation: supportCase.familySituation,
                }}
                handleClose={() => setShowAddressModal(false)}
            />
        </>
    );
};
