import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ISaveSupportTypeFrequencies, ISupportTypeFrequency, queryKeys } from '../../../shared';

async function saveSupportTypesFrequencies(
    frequencies: ISaveSupportTypeFrequencies,
    onUpdateSuccessMessage?: string,
    onUpdateErrorMessage?: string,
): Promise<ISupportTypeFrequency[]> {
    const result = await axios.post(`/support-api/support-type-frequencies`, frequencies, {
        successMessage: onUpdateSuccessMessage,
        errorMessage: onUpdateErrorMessage,
    });

    return result.data;
}

export const useSaveSupportTypesFrequencies = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation<ISupportTypeFrequency[], AxiosError, ISaveSupportTypeFrequencies>({
        mutationFn: (data) =>
            saveSupportTypesFrequencies(
                data,
                t('updateSupportTypesFrequenciesSuccess'),
                t('updateSupportTypesFrequenciesError'),
            ),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [queryKeys.SUPPORT_TYPES_FREQUENCIES] });
        },
    });
};
