import { Box, Button, Paper, Typography } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const Unauthenticated: FC = () => {
    const { t } = useTranslation();

    const onLoginAgain = useCallback(() => {
        window.location.href = '/auth/logout';
    }, []);

    return (
        <Paper
            sx={{
                maxWidth: 420,
                width: '100%',
                mt: 8,
                marginLeft: 'auto',
                marginRight: 'auto',
                textAlign: 'center',
            }}
        >
            <Box sx={{ p: 4 }}>
                <img src={window.carity.environment.theme.logo} alt="Carity" />
                <Typography sx={{ mt: 2 }} variant="h5">
                    {t('unauthenticatedTitle')}
                </Typography>
                <Typography variant="subtitle1" component="div">
                    {t('unauthenticatedText')}
                </Typography>
                <Button variant="contained" fullWidth={true} sx={{ mt: 2 }} onClick={onLoginAgain}>
                    {t('loginAgain')}
                </Button>
            </Box>
        </Paper>
    );
};
