import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Stack } from '@mui/material';
import React, { FC, Fragment } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    ControlledAutocomplete,
    ControlledCheckbox,
    ControlledNumberInput,
    FormCardSectionComponent,
    FormGrid,
    InputTextField,
} from '../../../shared';
import { Gender } from '../../../support/enums';

export const ExpenseTypeInputs: FC = () => {
    const { t } = useTranslation();

    const form = useFormContext();

    const { fields: lumpSums, append, remove } = useFieldArray({ control: form.control, name: 'lumpSums' });
    const watchLumpSum = form.watch('lumpSum');

    return (
        <>
            <FormCardSectionComponent title={t('data')}>
                <Stack spacing={2} width="400px">
                    <InputTextField name="name" label={t('nameLabel')} required />
                    <ControlledCheckbox name="lumpSum" label={t('lumpSum')} control={form.control} />
                </Stack>
            </FormCardSectionComponent>
            {watchLumpSum && (
                <>
                    <Stack spacing={2} mb={2} ml={2}>
                        <FormGrid>
                            <ControlledNumberInput name="baseLumpSum" label={t('baseLumpSum')} required />
                        </FormGrid>

                        {lumpSums.map((lumpSumRule, index) => (
                            <Fragment key={lumpSumRule.id}>
                                <FormGrid>
                                    <ControlledNumberInput
                                        name={`lumpSums.${index}.startAge`}
                                        label={t('startAge')}
                                        required
                                    />
                                    <ControlledAutocomplete
                                        name={`lumpSums.${index}.gender`}
                                        label={t('genderLabel')}
                                        defaultValue="ALL"
                                        options={[...Object.values(Gender), 'ALL']}
                                        getOptionLabel={(val) => t(val.toLowerCase())}
                                    />
                                    <ControlledNumberInput
                                        name={`lumpSums.${index}.amountPerPerson`}
                                        label={t('amountPerPerson')}
                                        required
                                    />
                                    <IconButton onClick={() => remove(index)} size="large">
                                        <CloseIcon />
                                    </IconButton>
                                </FormGrid>
                            </Fragment>
                        ))}
                    </Stack>
                    <Button
                        onClick={() => append({ startAge: 0, gender: 'ALL', amountPerPerson: 0 })}
                        color="secondary"
                        sx={{ width: 'fit-content', ml: 2 }}
                    >
                        + {t('addLumpSumRule')}
                    </Button>
                </>
            )}
        </>
    );
};
