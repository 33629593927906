import { useMemo } from 'react';
import { IOrganisation } from '../../models';
import { useOrganisations } from './organisations.hook';

export function useOrganisationRecords(): Record<string, IOrganisation> {
    const organisations = useOrganisations();
    return useMemo(() => {
        return organisations.reduce((obj, item): any => (item.id && (obj[item.id] = item), obj), {} as any);
    }, [organisations]);
}
