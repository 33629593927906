import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

export const useDebouncedState = <T>(defaultState: T, timeout = 300) => {
    const [innerState, setInnerState] = useState<T>(defaultState);
    const [debouncedState, _setDebouncedState] = useState<T>(defaultState);
    const setDebouncedState = debounce(_setDebouncedState, timeout);

    useEffect(() => {
        setDebouncedState(innerState);
        return () => setDebouncedState.cancel();
    }, [setDebouncedState, innerState]);

    return [debouncedState, innerState, setInnerState] as [T, T, (value: T) => void];
};
