import { AxiosInstance } from 'axios';
import { ListModel, ListQueryParam } from '../models';

export abstract class ReadOnlyClient<
    ResponseModel,
    QueryModel extends ListQueryParam = ListQueryParam,
    ListResponseModel = ResponseModel,
> {
    protected constructor(
        protected api: AxiosInstance,
        protected basePath: string,
    ) {}

    public async getList({ pageSize = 10, ...query }: QueryModel): Promise<ListModel<ListResponseModel>> {
        const { data } = await this.api.get<ListModel<ListResponseModel>>(this.basePath, {
            params: { pageSize, ...query },
        });
        return data;
    }

    public async getOne(id?: string): Promise<ResponseModel> {
        const { data } = await this.api.get<ResponseModel>(`${this.basePath}/${id}`);
        return data;
    }
}
