import { List, ListItemButton, ListItemText, ListSubheader } from '@mui/material';
import { transparentize } from 'polished';
import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface IListItem {
    path: string;
    text: string;
}

interface ISubTitle {
    text: string;
    key: string;
}

interface Props {
    items: (ISubTitle | IListItem)[];
}

function isListItem(item: IListItem | ISubTitle): item is IListItem {
    return Boolean((item as IListItem).path);
}

export const SideNav: FC<Props> = ({ items }) => {
    const location = useLocation();

    return (
        <List component="div" sx={{ p: 3 }}>
            {items.map((item) =>
                isListItem(item) ? (
                    <ListItemButton
                        key={item.path}
                        selected={location.pathname.startsWith(item.path)}
                        component={Link}
                        to={item.path}
                        sx={{
                            borderRadius: 1,

                            '&:hover, &:focus': {
                                background: (theme) => transparentize(0.95, theme.palette.primary.main),
                                color: 'primary.main',
                            },

                            '&.Mui-selected': {
                                background: (theme) => transparentize(0.9, theme.palette.primary.main),
                                color: 'primary.main',
                                '& .MuiListItemText-primary': {
                                    fontWeight: 'bold',
                                },

                                '&:hover, &:focus': {
                                    background: (theme) => transparentize(0.9, theme.palette.primary.main),
                                },
                            },
                        }}
                    >
                        <ListItemText primary={item.text} />
                    </ListItemButton>
                ) : (
                    <ListSubheader key={item.key} sx={{ fontVariant: 'all-small-caps' }}>
                        {item.text}
                    </ListSubheader>
                ),
            )}
        </List>
    );
};
