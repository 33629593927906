import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { ISupportType } from '../../../shared';
import { queryKeys } from '../../../shared';

async function getSupportType(id: string, onGetErrorMessage?: string): Promise<ISupportType> {
    const { data } = await axios.get(`/support-api/support-types/${id}`, {
        errorMessage: onGetErrorMessage,
    });
    return data;
}

export const useGetSupportType = (id: string) => {
    const { t } = useTranslation();

    return useQuery<ISupportType, AxiosError>({
        queryKey: [queryKeys.SUPPORT_TYPE, id],
        queryFn: () => getSupportType(id, t('getSupportTypeError')),
        enabled: !!id,
    });
};
