import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AUTH_API_BASE_URL, IUserRolesSave, queryKeys } from '../../../shared';

async function saveUserRoles(
    { id, ...userRoles }: IUserRolesSave,
    onSuccessMessage?: string,
    onErrorMessage?: string,
): Promise<IUserRolesSave> {
    let result;
    if (!id) {
        result = await axios.post(`${AUTH_API_BASE_URL}/v1/acls`, userRoles, {
            successMessage: onSuccessMessage,
            errorMessage: onErrorMessage,
        });
    } else {
        result = await axios.put(`${AUTH_API_BASE_URL}/v1/acls/${id}`, userRoles, {
            successMessage: onSuccessMessage,
            errorMessage: onErrorMessage,
        });
    }

    return result.data;
}

export const useSaveUserRoles = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation<IUserRolesSave, AxiosError, IUserRolesSave>({
        mutationFn: (data) => saveUserRoles(data, t('updateUserRolesSuccess'), t('updateUserRolesError')),

        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [queryKeys.USER_ROLES] });
        },
    });
};
