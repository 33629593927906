import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Button, Checkbox, FormControlLabel, Stack } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IIntake, useSelectedOrganisation, Widget } from '../../../../shared';
import { useCreateIntake } from '../../../hooks';
import { IntakeIntroWarning } from '../../../components';

interface Props {
    intake?: IIntake;
    supportCaseId?: string;
}

export const IntakeIntroPage: FC<Props> = ({ intake, supportCaseId }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { organisation } = useSelectedOrganisation();
    const [useDataLastIntake, setUseDataLastIntake] = useState(true);

    const { mutateAsync: createIntake, isPending } = useCreateIntake();

    const onSubmit = async (skipIntake?: boolean) => {
        if (supportCaseId && organisation) {
            if (!intake) {
                const result = await createIntake({
                    supportCaseId,
                    item: { organisationId: organisation.id, useDataLastIntake },
                });
                navigate(
                    `/support/support-cases/${result.supportCaseId}/intakes/${result.id}/${result.externalIntake || skipIntake ? 'completion' : 'income'}`,
                );
            } else {
                navigate(intake.externalIntake || skipIntake ? '../completion' : '../income');
            }
        }
    };

    return (
        <Widget
            footer={
                <Stack direction="row" justifyContent="space-between">
                    <Button
                        startIcon={<ChevronLeft />}
                        onClick={() => navigate(`/support/support-cases/${supportCaseId}/intakes`)}
                    >
                        {t('cancel')}
                    </Button>
                    <Stack spacing={2} direction="row">
                        <Button
                            endIcon={<ChevronRight />}
                            variant="outlined"
                            color="primary"
                            onClick={() => onSubmit(true)}
                            disabled={isPending}
                        >
                            {t('checkAndSkip')}
                        </Button>
                        <Button
                            endIcon={<ChevronRight />}
                            variant="contained"
                            color="primary"
                            onClick={() => onSubmit()}
                            disabled={isPending}
                        >
                            {t('checkAndNext')}
                        </Button>
                    </Stack>
                </Stack>
            }
        >
            <Stack spacing={2}>
                <IntakeIntroWarning supportCaseId={supportCaseId} />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={useDataLastIntake}
                            onChange={() => setUseDataLastIntake(!useDataLastIntake)}
                            disabled={!!intake}
                        />
                    }
                    label={t('useDataLastIntake')}
                />
            </Stack>
        </Widget>
    );
};
