import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { object, string } from 'yup';
import {
    FormCard,
    FormCardSectionComponent,
    FormGrid,
    InputTextField,
    IOrganisationType,
    IParams,
    Page,
} from '../../../shared';
import { useGetOrganisationType, useSaveOrganisationType } from '../../hooks';

export function OrganisationTypeEditPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id } = useParams<keyof IParams>() as IParams;
    const { data } = useGetOrganisationType(id);

    const { control, reset, handleSubmit } = useForm<IOrganisationType>({
        resolver: yupResolver(object().shape({ name: string().required() })),
        mode: 'all',
    });
    const { mutateAsync: saveOrganisationType } = useSaveOrganisationType();

    useEffect(() => {
        if (data) {
            reset(data);
        }
    }, [data, reset]);

    const onSubmit = async (data: IOrganisationType) => {
        await saveOrganisationType(data);
        navigate('/admin/organisation-types');
    };

    return (
        <Page
            title={data?.name || t('newOrganisationType')}
            actions={[
                <Button onClick={() => navigate('/admin/organisation-types')} color="secondary">
                    {t('cancel')}
                </Button>,
                <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
                    {t('save')}
                </Button>,
            ]}
            onBack={() => navigate(-1)}
        >
            <FormCard
                handleSubmit={handleSubmit(onSubmit)}
                actions={[
                    <Button type="submit" variant="contained" color="primary">
                        {t('save')}
                    </Button>,
                    <Button onClick={() => navigate('/admin/organisation-types')} color="secondary">
                        {t('cancel')}
                    </Button>,
                ]}
            >
                <FormCardSectionComponent title={t('data')}>
                    <FormGrid>
                        <InputTextField
                            name="name"
                            label={t('nameLabel')}
                            control={control}
                            required
                            sx={{ width: '400px' }}
                        />
                    </FormGrid>
                </FormCardSectionComponent>
            </FormCard>
        </Page>
    );
}
