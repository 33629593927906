import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import { IRunReportFilter } from '../models';

interface IReportExportParams {
    id: string;
    name: string;
    filters: IRunReportFilter[];
}

async function getReportExport({ id, filters }: IReportExportParams): Promise<string> {
    const { data } = await axios.post(
        `/reporting/reports/${id}/export`,
        {
            filters,
        },
        { responseType: 'blob' },
    );
    return data;
}

export const useReportExport = () => {
    return useMutation<string, AxiosError, IReportExportParams>({
        mutationFn: (params) => getReportExport(params),
        onSuccess: (data, variables) => {
            // We need something like this file-saver because we are using a post and the content-disposition header only seems to work properly with get requests
            saveAs(data, `${format(new Date(), 'yyyy-MM-dd')}-${variables.name}.csv`);
        },
    });
};
