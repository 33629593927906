import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { queryKeys } from '../../../support/constants';
import { ISupportCaseInfo } from '../../models';

async function getSupportCaseInfo(
    id?: string,
    code?: string,
    nationalSecurityNumber?: string,
    organisation?: string,
): Promise<ISupportCaseInfo[]> {
    const { data } = await axios(`/support-api/support-cases/info`, {
        params: {
            id,
            code,
            nationalSecurityNumber,
            organisation,
        },
    });
    return data;
}

export const useSupportCaseInfo = (
    supportCaseId?: string,
    cardCode?: string,
    nationalSecurityNumber?: string,
    organisation?: string,
) => {
    return useQuery<ISupportCaseInfo[], AxiosError>({
        queryKey: [
            queryKeys.SUPPORT_CASE_INFO,
            {
                id: supportCaseId,
                code: cardCode,
                nationalSecurityNumber: nationalSecurityNumber,
                organisation: organisation,
            },
        ],
        queryFn: () => getSupportCaseInfo(supportCaseId, cardCode, nationalSecurityNumber, organisation),
        enabled:
            (!!supportCaseId && !!organisation) ||
            (!!cardCode && !!organisation) ||
            (!!nationalSecurityNumber && !!organisation),
        retry: false,
    });
};
