import {
    IIncomeType,
    queryKeys,
    useDelete,
    useItem,
    UseItemParams,
    useList,
    UseListParams,
    useSave,
} from '../../../shared';
import { incomeTypesClient } from '../../clients';

const client = incomeTypesClient;
const listKey = queryKeys.INCOME_TYPES;
const itemKey = queryKeys.INCOME_TYPE;

export function useIncomeTypesList(...args: UseListParams<IIncomeType>) {
    return useList(client, listKey, ...args);
}

export function useIncomeType(...args: UseItemParams<IIncomeType>) {
    return useItem(client, itemKey, ...args);
}

export function useSaveIncomeType() {
    return useSave(client, listKey, itemKey);
}

export function useDeleteIncomeType() {
    return useDelete(client, listKey);
}
