import { useMemo } from 'react';
import { array, object, string } from 'yup';

export function useSupportCaseFoodRestrictionsSchema() {
    return useMemo(
        () =>
            object().shape({
                foodRestrictions: array()
                    .of(
                        object().shape({
                            supportee: string().required().optional(),
                            foodRestriction: string().required(),
                        }),
                    )
                    .required(),
            }),
        [],
    );
}
