import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { ChangeEvent, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { array, boolean, object } from 'yup';
import { contactSchema, ContactType, DialogTitleWithClose, IContact } from '../../../shared';
import { useUpdateContacts } from '../../hooks';
import { ContactInputs } from '../contact-inputs/contact-inputs.component';

export function UpdateContactsModal(props: {
    open: boolean;
    supportCase: string;
    contacts: IContact[];
    handleClose: () => void;
}) {
    const { t } = useTranslation();

    const { mutateAsync: updateContacts } = useUpdateContacts(props.supportCase);

    const { control, handleSubmit, setValue, getValues, reset, register } = useForm<{ contacts: IContact[] }>({
        resolver: yupResolver(
            object().shape({
                contacts: array()
                    .of(contactSchema().shape({ primaryContact: boolean().required() }))
                    .required(),
            }),
        ),
        mode: 'all',
    });

    useEffect(() => {
        if (props.open && props.contacts) {
            reset({ contacts: props.contacts });
        }
    }, [props.open]);
    const {
        fields: contactFields,
        append: appendContact,
        remove: removeContact,
    } = useFieldArray({
        control,
        name: 'contacts',
        keyName: 'formId',
    });

    const handlePrimaryContactChange = (event: ChangeEvent, onChange: any) => {
        const index = getValues().contacts.findIndex((contact) => contact.primaryContact);
        if (index >= 0) {
            setValue(`contacts.${index}.primaryContact`, false);
        }

        onChange(event);
    };

    const onSubmit = async (data: { contacts: IContact[] }) => {
        const mappedContacts = data.contacts.map((contact) => {
            return { ...contact, id: contact.id ? contact.id : undefined };
        });
        await updateContacts({ contacts: mappedContacts });
        props.handleClose();
    };

    return (
        <Dialog open={props.open} fullWidth maxWidth="md">
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitleWithClose onClose={props.handleClose}>{t('editContactInformation')}</DialogTitleWithClose>
                <DialogContent>
                    <Grid container direction="column" sx={{ pt: 1 }} spacing={2}>
                        {contactFields.map((field, index) => (
                            <Grid item key={field.formId}>
                                <input type="hidden" {...register(`contacts.${index}.id`)} />
                                <ContactInputs
                                    field={`contacts.${index}`}
                                    control={control}
                                    onRemoveContact={() => removeContact(index)}
                                    handlePrimaryContactChange={handlePrimaryContactChange}
                                />
                            </Grid>
                        ))}
                        <Grid item>
                            <Button
                                data-testid="add-contact"
                                onClick={() =>
                                    appendContact({
                                        id: undefined,
                                        type: ContactType.Email,
                                        data: '',
                                        primaryContact: false,
                                    })
                                }
                                color="secondary"
                            >
                                + {t('contactsAdd')}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" variant="contained" color="primary">
                        {t('save')}
                    </Button>
                    <Button onClick={props.handleClose} color="secondary">
                        {t('cancel')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
