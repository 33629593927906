import { mixed, number, object, string } from 'yup';
import { CreditFrequency } from '../../shared';
import { TFunction } from 'i18next';

export const supportTypesInfoSchema = (t: TFunction) => {
    return object().shape({
        supportTypeId: string().required(),
        credit: number()
            .transform((value) => (value === '' || Number.isNaN(value) ? null : value))
            .when('frequency', {
                is: CreditFrequency.WEEK || CreditFrequency.MONTH,
                then: (schema) => schema.min(0).required(),
                otherwise: (schema) => schema.optional(),
            })
            .optional()
            .nullable(),
        spendCredit: number().nullable().optional(),
        frequency: mixed<CreditFrequency | ''>()
            .transform((value) => (value === '' ? null : value))
            .test('frequency', t('creditFrequencyMustBeFilledIn'), (value, context) =>
                context.parent.credit ? !!value : true,
            )
            .optional()
            .nullable(),
    });
};
