import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ICard } from '../../../shared';
import { queryKeys } from '../../constants';

async function createCard(
    supportCase: string,
    onCreateSuccessMessage?: string,
    onCreateErrorMessage?: string,
): Promise<ICard> {
    const result = await axios.post(
        '/support-api/cards',
        { supportCases: [supportCase] },
        {
            successMessage: onCreateSuccessMessage,
            errorMessage: onCreateErrorMessage,
        },
    );
    return result.data;
}

export const useCreateCard = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation<ICard, AxiosError, string>({
        mutationFn: (data) => createCard(data, t('createCardSuccess'), t('createCardError')),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: [queryKeys.SUPPORT_CASE, variables] });
        },
    });
};
