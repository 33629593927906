import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ICard } from '../../../shared';
import { queryKeys } from '../../constants';

async function linkCardSupportCase(
    code: string,
    supportCaseId: string,
    onLinkSuccessMessage?: string,
    onLinkErrorMessage?: string,
): Promise<ICard> {
    const result = await axios.put(
        '/support-api/cards/support-case',
        {
            cardCode: code,
            supportCase: supportCaseId,
        },
        {
            successMessage: onLinkSuccessMessage,
            errorMessage: onLinkErrorMessage,
        },
    );
    return result.data;
}

export const useLinkCardSupportCase = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation<ICard, AxiosError, { supportCase: string; cardCode: string }>({
        mutationFn: (data) =>
            linkCardSupportCase(data.cardCode, data.supportCase, t('linkCardSuccess'), t('linkCardError')),

        onSuccess: async (data, variables) => {
            await queryClient.invalidateQueries({ queryKey: [queryKeys.SUPPORT_CASE, variables.supportCase] });
        },
    });
};
