import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DialogTitleWithClose, InputTextField } from '../../../shared';
import { useCreateCardSet } from '../../hooks';
import { ISaveCardSet } from '../../models';
import { useCardSetSchema } from '../../validators';

interface Props {
    show: boolean;
    handleClose: () => void;
}

export const CreateCardSet: FC<Props> = ({ show, handleClose }) => {
    const { t } = useTranslation();
    const { mutateAsync: createCardSet, isPending: isCreatingCardSet } = useCreateCardSet();

    const { control, handleSubmit, reset } = useForm<ISaveCardSet>({
        resolver: yupResolver(useCardSetSchema()),
        mode: 'onSubmit',
    });

    useEffect(() => {
        reset();
    }, [show]);

    const onSubmit = async (data: ISaveCardSet) => {
        await createCardSet(data);
        handleClose();
    };
    return (
        <Dialog open={show}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitleWithClose onClose={handleClose}>{t('newCardSet')}</DialogTitleWithClose>
                <DialogContent>
                    <Box sx={{ pt: 1, minWidth: 500 }}>
                        <InputTextField
                            name={`countCards`}
                            label={t('countCards')}
                            control={control}
                            required
                            type="number"
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isCreatingCardSet}
                        startIcon={<CircularProgress size={16} />}
                    >
                        {t('save')}
                    </Button>
                    <Button onClick={handleClose} color="secondary">
                        {t('cancel')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
