import { ToggleButtonGroup } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Control, Controller } from 'react-hook-form';

interface Props {
    children?: ReactNode;
    name: string;
    control: Control<any>;
    defaultValue: any;
    exclusive?: boolean;
}

export const ToggleButtonGroupComponent: FC<Props> = ({ name, control, defaultValue, exclusive, children }) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field: { onChange, value } }) => (
                <ToggleButtonGroup
                    size="small"
                    value={value}
                    exclusive={exclusive}
                    onChange={onChange}
                    sx={{ button: { p: 0.6, fontSize: '0.7125rem' } }}
                >
                    {children}
                </ToggleButtonGroup>
            )}
        />
    );
};
