import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { boolean, number, object, string, array, mixed } from 'yup';
import { Gender } from '../../support/enums';

export function useExpenseTypeSchema() {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                name: string().required(),
                lumpSum: boolean().required(),
                baseLumpSum: number()
                    .typeError(t('invalidNumber'))
                    .optional()
                    .when('lumpSum', { is: true, then: (schema) => schema.required() }),
                lumpSums: array()
                    .of(
                        object().shape({
                            startAge: number().typeError(t('invalidNumber')).required(),
                            amountPerPerson: number().typeError(t('invalidNumber')).required(),
                            gender: mixed<Gender | 'ALL'>().required(),
                        }),
                    )
                    .when('lumpSum', { is: false, then: (schema) => schema.strip() }),
            }),
        [t],
    );
}
