import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton } from '@mui/material';
import { AxiosError } from 'axios';
import React, { FC, Fragment, useEffect, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
    FormCard,
    FormCardSectionComponent,
    FormGrid,
    InputTextField,
    IParams,
    ISaveReferralOrganisation,
    Page,
    sortByName,
    StatusCode,
} from '../../../shared';
import { useGetReferralOrganisation, useSaveReferralOrganisation } from '../../hooks';
import { useReferralOrganisationSchema } from '../../validators';

export const ReferralOrganisationEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams<keyof IParams>() as IParams;

    const { control, handleSubmit, reset, setError } = useForm<ISaveReferralOrganisation>({
        resolver: yupResolver(useReferralOrganisationSchema()),
        mode: 'all',
    });

    const {
        fields: contacts,
        append,
        remove,
    } = useFieldArray({
        control,
        name: 'contacts',
    });

    const { data: organisation } = useGetReferralOrganisation(id);
    useEffect(() => {
        if (organisation) {
            reset({
                ...organisation,
                contacts: organisation.contacts.sort(sortByName),
            });
        }
    }, [organisation, reset]);

    const { mutateAsync: saveOrganisation } = useSaveReferralOrganisation();

    const onSubmit = async (data: ISaveReferralOrganisation) => {
        try {
            await saveOrganisation({
                ...data,
                contacts: data.contacts.filter((contact) => !!contact.name || !!contact.email || !!contact.phone),
            });
            navigate('/admin/referral-organisations');
        } catch (err) {
            if ((err as AxiosError)?.response?.status === StatusCode.CONFLICT) {
                setError('code', {
                    message: t('codeUniqErr'),
                });
            }
        }
    };

    const actions = useMemo(() => {
        return [
            <Button onClick={() => navigate('/admin/referral-organisations')} color="secondary">
                {t('cancel')}
            </Button>,
            <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
                {t('save')}
            </Button>,
        ];
    }, []);

    return (
        <Page title={organisation?.name || t('newReferralOrganisation')} actions={actions}>
            <FormCard handleSubmit={handleSubmit(onSubmit)} actions={actions.slice().reverse()}>
                <FormCardSectionComponent title={t('data')}>
                    <FormGrid xs={3}>
                        <InputTextField name="name" label={t('nameLabel')} control={control} required={true} />
                        <InputTextField name="code" label={t('codeLabel')} control={control} required={true} />
                    </FormGrid>
                </FormCardSectionComponent>
                <FormCardSectionComponent
                    title={t('contacts')}
                    footer={
                        <Button data-testid="add-contact" onClick={() => append({})} color="secondary">
                            + {t('contactsAdd')}
                        </Button>
                    }
                >
                    {contacts.map((contact, index) => (
                        <Fragment key={contact.id}>
                            <FormGrid xs={3}>
                                <InputTextField
                                    name={`contacts.${index}.name`}
                                    label={t('nameLabel')}
                                    control={control}
                                />
                                <InputTextField
                                    name={`contacts.${index}.email`}
                                    label={t('emailLabel')}
                                    control={control}
                                />
                                <InputTextField
                                    name={`contacts.${index}.phone`}
                                    label={t('phoneLabel')}
                                    control={control}
                                />
                                <IconButton aria-label="delete" onClick={() => remove(index)} size="large">
                                    <CloseIcon />
                                </IconButton>
                            </FormGrid>
                        </Fragment>
                    ))}
                </FormCardSectionComponent>
            </FormCard>
        </Page>
    );
};
