import { createTheme, Theme, ThemeOptions } from '@mui/material';
import { transparentize } from 'polished';
import { IEnvironment } from '../../shared';

export const createBaseTheme = (themeOptions: IEnvironment['theme'], palette = getPalette(themeOptions)): Theme => {
    const theme = createTheme({ palette });

    const typography = getTypography(theme);
    const components = getComponents(theme);

    return createTheme(theme, {
        typography,
        components,
    });
};

function getComponents({ palette: { grey, primary }, zIndex }: Theme): ThemeOptions['components'] {
    return {
        MuiBottomNavigation: {
            styleOverrides: {
                root: {
                    borderTop: `solid 1px ${grey['300']}`,
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    borderBottom: `solid 1px ${grey['300']}`,
                    backgroundColor: 'white',
                    color: primary.main,
                    background: '#FFF',
                    position: 'fixed',
                    marginBottom: '60px',
                    zIndex: zIndex.drawer + 1,
                },
            },
            defaultProps: {
                elevation: 0,
            },
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    width: '248px',
                    zIndex: 1,
                    flexShrink: 0,
                },
                paper: {
                    width: '248px',
                    paddingTop: 15,
                    boxSizing: 'border-box',
                    '.MuiList-root': {
                        paddingTop: 0,
                        paddingBottom: 0,
                    },
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: { minHeight: 55, '@media (min-width: 0px)': { minHeight: 55 } },
            },
        },
        MuiFormControl: {
            defaultProps: {
                fullWidth: true,
            },
        },
        MuiTextField: {
            defaultProps: {
                slotProps: {
                    inputLabel: { shrink: true },
                },
                fullWidth: true,
            },
        },
        MuiInputLabel: {
            defaultProps: {
                shrink: true,
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    background: '#FAFAFA',
                    justifyContent: 'flex-start',
                    padding: '16px 24px',
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    paddingBottom: 0,
                },
                title: {
                    fontSize: 18,
                    fontWeight: 'bolder',
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    '&:last-child': {
                        paddingBottom: '16px',
                    },
                },
            },
        },
    };
}

function getPalette(themeOptions: IEnvironment['theme']): ThemeOptions['palette'] {
    return {
        primary: {
            main: themeOptions.color,
            shade: transparentize(0.85, themeOptions.color),
        },
        secondary: {
            main: themeOptions.color,
            shade: transparentize(0.85, themeOptions.color),
        },
        background: {
            default: '#FAFAFA',
            paper: '#FFF',
        },
    };
}

function getTypography({ palette }: Theme): ThemeOptions['typography'] {
    return {
        appTitle: {
            fontSize: 14,
            display: 'flex',
            alignItems: 'center',
            textTransform: 'uppercase',
            paddingRight: 24,
            borderRight: `solid 1px ${palette.grey['300']}`,
            height: 55,
            width: 224,
        },
        subtitle1: {
            fontSize: 18,
            marginBottom: 16,
        },
        subtitle2: {
            fontSize: 16,
            marginBottom: 16,
        },
    };
}
