import { CommentTwoTone } from '@mui/icons-material';
import { Divider, Grid, List } from '@mui/material';
import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Page, IParams, useSelectedOrganisation, Widget, useHasPermission, PermissionKeys } from '../../../shared';
import { CommentComponent, DuplicatesWarning, SaveCommentComponent, SupportCaseTitle } from '../../components';
import { useGetComments, useGetSupportCase } from '../../hooks';

export const SupportCaseCommentsPage: FC = () => {
    const { t } = useTranslation();
    const { hasPermission } = useHasPermission();
    const hasWritePermission = hasPermission(PermissionKeys.SUPPORT_CASE_COMMENTS_WRITE);
    const navigate = useNavigate();
    const { organisation } = useSelectedOrganisation();
    const { id } = useParams<keyof IParams>() as IParams;
    const { data: supportCase } = useGetSupportCase(id);
    const { data: comments } = useGetComments(id, organisation ? organisation.id : '', {
        pageSize: 100,
    });

    return (
        <Page onBack={() => navigate(-1)} title={supportCase && <SupportCaseTitle supportCase={supportCase} />}>
            <DuplicatesWarning supportCaseId={id} />
            <Widget icon={<CommentTwoTone color="primary" />} title={t('comments')}>
                <Grid container direction="column" spacing={2}>
                    {hasWritePermission && (
                        <>
                            <Grid item>
                                <SaveCommentComponent supportCase={id} />
                            </Grid>
                            <Grid item>
                                <Divider />
                            </Grid>
                        </>
                    )}

                    <List sx={{ width: '100%' }}>
                        {comments?.data.map((comment) => (
                            <Fragment key={comment.id}>
                                <CommentComponent supportCase={id} comment={comment} />
                                <Divider variant="inset" component="li" />
                            </Fragment>
                        ))}
                    </List>
                </Grid>
            </Widget>
        </Page>
    );
};
