import React, { FC } from 'react';
import { ErrorOutlineOutlined, HighlightOffOutlined, TaskAltOutlined } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';

interface Props {
    label: string;
    value: string;
    status: keyof typeof icons;
}

const icons = {
    success: (
        <Box sx={{ px: '8px', py: '2px', backgroundColor: '#4caf50', width: 'fit-content' }}>
            <TaskAltOutlined sx={{ color: '#FFF', mt: 0.5, fontSize: '2em' }} />
        </Box>
    ),
    warning: (
        <Box sx={{ px: '8px', py: '2px', backgroundColor: '#ff9800', width: 'fit-content' }}>
            <ErrorOutlineOutlined sx={{ color: '#FFF', mt: 0.5, fontSize: '2em' }} />
        </Box>
    ),
    error: (
        <Box sx={{ px: '8px', py: '2px', backgroundColor: '#ef5350', width: 'fit-content' }}>
            <HighlightOffOutlined sx={{ color: '#FFF', mt: 0.5, fontSize: '2em' }} />
        </Box>
    ),
};

export const Status: FC<Props> = ({ label, value, status }) => {
    return (
        <Stack direction="row" spacing={1}>
            {icons[status]}
            <Box>
                <Typography sx={{ fontWeight: 'bold' }}>{label}</Typography>
                <Typography>{value}</Typography>
            </Box>
        </Stack>
    );
};
