import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { ISupportCase } from '../../../shared';
import { queryKeys } from '../../constants';
import { ISaveSupportCase } from '../../models';

async function saveSupportCase(
    supportCase: ISaveSupportCase,
    onUpdateSuccessMessage?: string,
    onUpdateErrorMessage?: string,
    onCreateSuccessMessage?: string,
    onCreateErrorMessage?: string,
): Promise<ISupportCase> {
    let result;
    if (supportCase.id) {
        result = await axios.put(`/support-api/support-cases/${supportCase.id}`, supportCase, {
            successMessage: onUpdateSuccessMessage,
            errorMessage: onUpdateErrorMessage,
        });
    } else {
        result = await axios.post('/support-api/support-cases', supportCase, {
            successMessage: onCreateSuccessMessage,
            errorMessage: onCreateErrorMessage,
        });
    }

    return result.data;
}

export const useSaveSupportCase = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation<ISupportCase, AxiosError, ISaveSupportCase>({
        mutationFn: (data) =>
            saveSupportCase(
                data,
                t('updateSupportCaseSuccess'),
                t('updateSupportCaseError'),
                t('createSupportCaseSuccess'),
                t('createSupportCaseError'),
            ),
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({ queryKey: [queryKeys.SUPPORT_CASES] });
            await queryClient.invalidateQueries({ queryKey: [queryKeys.SUPPORT_CASE, data.id] });
            await queryClient.invalidateQueries({ queryKey: [queryKeys.SUPPORT_CASES_DUPLICATES, data.id] });
        },
    });
};
