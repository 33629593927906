import { Box, Grid, Paper } from '@mui/material';
import { ReactNode } from 'react';
import { SubmitHandler } from 'react-hook-form';

export function FormCard(props: { children: ReactNode; actions: ReactNode; handleSubmit: SubmitHandler<any> }) {
    const actions = Array.isArray(props.actions) ? props.actions : [props.actions];

    return (
        <form onSubmit={props.handleSubmit} noValidate={true} autoComplete="off">
            <Paper variant="outlined">
                <Box sx={{ p: 3 }}>
                    <Grid container direction="column" spacing={2}>
                        {props.children}
                    </Grid>
                </Box>

                {props.actions && (
                    <Box
                        sx={{
                            p: 3,
                            bgcolor: 'background.default',
                            borderBottomLeftRadius: 1,
                            borderBottomRightRadius: 1,
                        }}
                    >
                        <Grid container alignItems="center" spacing={1}>
                            {actions.map((action, index) => (
                                <Grid item key={index}>
                                    {action}
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                )}
            </Paper>
        </form>
    );
}
