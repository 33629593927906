import { object, string } from 'yup';
import { baseSchema } from './base.validator';

export const addressSchema = () => {
    return object().shape({
        street: string().optional(),
        number: string().optional(),
        box: string().optional(),
        postalCode: string().optional(),
        city: string().optional(),
        country: string().optional(),
    });
};

export const addressWithCountrySchema = () => {
    return object().shape({
        street: string().optional(),
        number: string().optional(),
        box: string().optional(),
        postalCode: string().optional(),
        city: string().optional(),
        country: baseSchema().optional().nullable(),
    });
};
