import { createContext, FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { IOrganisation, useGetOrganisations } from '../index';

interface ISelectedOrganisationContext {
    organisation?: IOrganisation;
    organisations: IOrganisation[];
    setOrganisation: (organisation: IOrganisation | undefined) => void;
    isLoading: boolean;
}

const SelectedOrganisationContext = createContext<ISelectedOrganisationContext>({
    // eslint-disable-next-line
    setOrganisation: () => {},
    organisations: [],
    isLoading: true,
});

interface Props {
    children?: ReactNode;
}

export const SelectedOrganisationProvider: FC<Props> = ({ children }) => {
    const [organisation, setOrganisation] = useState<IOrganisation>();
    const { data, isPending } = useGetOrganisations({
        pageSize: 100,
        onlyMyOrganisations: true,
    });
    const organisations = useMemo(() => data?.data || [], [data]);

    useEffect(() => {
        if (!organisation && organisations.length) {
            const cashedOrganisation = organisations.find(
                (org) => org.id === localStorage.getItem('selectedOrganisation'),
            );
            if (!cashedOrganisation) {
                localStorage.setItem('selectedOrganisation', organisations[0].id);
            }
            setOrganisation(cashedOrganisation || organisations[0]);
        } else if (organisation) {
            localStorage.setItem('selectedOrganisation', organisation.id);
        }
    }, [organisation, organisations]);

    return (
        <SelectedOrganisationContext.Provider
            value={{
                organisation,
                setOrganisation,
                organisations,
                isLoading: isPending || (organisations?.length ? !organisation : !organisations),
            }}
        >
            {children}
        </SelectedOrganisationContext.Provider>
    );
};

export const useSelectedOrganisation = (): ISelectedOrganisationContext => useContext(SelectedOrganisationContext);
