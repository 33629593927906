import { IconButton, Tooltip } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal, ICard, ISupportCase } from '../../../shared';
import { useRemoveCardFromSupportCase } from '../../hooks';
import { LinkOff } from '@mui/icons-material';

export const UnlinkCardAction: FC<{ supportCase: ISupportCase; card: ICard }> = ({ supportCase, card }) => {
    const { t } = useTranslation();
    const { mutateAsync: removeCardFromSupportCase } = useRemoveCardFromSupportCase();

    const onUnlink = useCallback(async () => {
        await removeCardFromSupportCase({ supportCaseId: supportCase.id, cardId: card.id });
    }, [removeCardFromSupportCase, supportCase.id]);

    return (
        <ConfirmModal
            onConfirm={onUnlink}
            button={
                <Tooltip title={t('disconnectCard')} placement="bottom" arrow>
                    <IconButton data-testid="disconnect-card">
                        <LinkOff />
                    </IconButton>
                </Tooltip>
            }
            title={t('disconnectCardWarningTitle')}
            text={
                <>
                    {t('disconnectCardWarningText')} <b>{card.code}</b>
                </>
            }
        />
    );
};
