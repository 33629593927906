import { useCallback, useContext, useMemo } from 'react';
import { PermissionsContext, useSelectedOrganisation } from '../contexts';
import { PermissionKeys } from '../enums';

interface UseHasPermissionResult {
    hasPermission: (requiredPermission: PermissionKeys | PermissionKeys[], skipOrganisationCheck?: boolean) => boolean;
    isLoading: boolean;
}

export const useHasPermission = (): UseHasPermissionResult => {
    const { organisation, isLoading } = useSelectedOrganisation();
    const { permissions: userRoles } = useContext(PermissionsContext);

    const userOrganisationPermissions = useMemo(
        () =>
            userRoles
                ?.filter((role) => role.context?.key === organisation?.id)
                .flatMap((role) => role.roles)
                .flatMap((role) => role.permissions as PermissionKeys[]),
        [userRoles, organisation],
    );

    const userPermissions = useMemo(
        () => userRoles?.flatMap((role) => role.roles).flatMap((role) => role.permissions as PermissionKeys[]),
        [userRoles],
    );

    const hasPermission = useCallback(
        (requiredPermission: PermissionKeys | PermissionKeys[], skipOrganisationCheck = false): boolean => {
            if (!skipOrganisationCheck && !organisation) {
                return false;
            }
            return permissionChecker(
                (skipOrganisationCheck ? userPermissions : userOrganisationPermissions) || [],
                requiredPermission,
            );
        },
        [organisation, userPermissions, userOrganisationPermissions],
    );

    return { hasPermission, isLoading: !userOrganisationPermissions || isLoading };
};

function permissionChecker(permissions: PermissionKeys[], requiredPermission: PermissionKeys | PermissionKeys[]) {
    if (Array.isArray(requiredPermission)) {
        return requiredPermission.every((perm) => permissions.includes(perm));
    }
    return permissions.includes(requiredPermission);
}
