import { yupResolver } from '@hookform/resolvers/yup';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Button, InputAdornment, Stack, Typography } from '@mui/material';
import { FC, Fragment, useEffect } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    ControlledNumberInput,
    FormCardSectionComponent,
    IIntake,
    IIntakeExpenseForm,
    Widget,
} from '../../../../shared';
import { LumpSumExpenses } from '../../../components';
import { useUpdateIntake } from '../../../hooks';
import { intakeExpensesToFormMapper } from '../../../mappers';
import { useIntakeExpensesSchema } from '../../../validators';

interface Props {
    intake?: IIntake;
}

export const IntakeExpensesPage: FC<Props> = ({ intake }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { mutateAsync: updateIntake, isPending } = useUpdateIntake();

    const form = useForm<IIntakeExpenseForm>({
        resolver: yupResolver(useIntakeExpensesSchema()),
        mode: 'onSubmit',
    });
    const { fields: expenses } = useFieldArray({ control: form.control, name: 'expenses' });
    const lumpSumExpenses = intake?.expenses?.filter(({ lumpSum }) => lumpSum) || [];

    useEffect(() => {
        if (intake) {
            form.reset(intakeExpensesToFormMapper(intake));
        }
    }, [form, intake]);

    const onSubmit = async (item: IIntakeExpenseForm, next = true) => {
        if (intake) {
            await updateIntake({ supportCaseId: intake.supportCaseId, intakeId: intake.id, item });
            navigate(next ? '../completion' : '../income');
        }
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit((data) => onSubmit(data, false))} noValidate={true} autoComplete="off">
                <Widget
                    footer={
                        <Stack direction="row" justifyContent="space-between">
                            <Button
                                startIcon={<ChevronLeft />}
                                onClick={form.handleSubmit((data) => onSubmit(data, false))}
                                disabled={isPending}
                            >
                                {t('previous')}
                            </Button>
                            <Button
                                endIcon={<ChevronRight />}
                                variant="contained"
                                color="primary"
                                onClick={form.handleSubmit((data) => onSubmit(data))}
                                disabled={isPending}
                            >
                                {t('next')}
                            </Button>
                        </Stack>
                    }
                >
                    <Stack spacing={2}>
                        <FormCardSectionComponent title={t('expenses')}>
                            <Typography sx={{ mb: 2 }}>{t('expensesHelpText')}</Typography>
                            <Stack spacing={2} width="400px">
                                {expenses.map((expense, index) => (
                                    <Fragment key={expense.id}>
                                        <ControlledNumberInput
                                            label={expense.expenseTypeName}
                                            name={`expenses.${index}.amount`}
                                            slotProps={{
                                                input: {
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Typography>€</Typography>
                                                        </InputAdornment>
                                                    ),
                                                },
                                            }}
                                        />
                                    </Fragment>
                                ))}
                            </Stack>
                        </FormCardSectionComponent>
                        {lumpSumExpenses?.length !== 0 && <LumpSumExpenses lumpSumExpenses={lumpSumExpenses} />}
                    </Stack>
                </Widget>
            </form>
        </FormProvider>
    );
};
