import { Box, Button, Paper, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const Forbidden: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <Paper
            sx={{ maxWidth: 420, width: '100%', mt: 8, marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}
        >
            <Box sx={{ p: 4 }}>
                <img src={window.carity.environment.theme.logo} alt="Carity" />
                <Typography sx={{ mt: 2 }} variant="h5">
                    {t('forbiddenTitle')}
                </Typography>
                <Typography sx={{ mb: 2 }} variant="subtitle1" component="div">
                    {t('forbiddenText')}
                </Typography>
                <Button onClick={() => navigate(-1)} variant="contained">
                    {t('backButton')}
                </Button>
            </Box>
        </Paper>
    );
};
