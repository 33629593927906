import { Box, Stack, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

interface Props {
    children: ReactNode;
    title?: ReactNode;
    footer?: ReactNode;
}

export const Section: FC<Props> = ({ children, title, footer }) => {
    return (
        <Box sx={{ width: '100%' }}>
            {title && (
                <Typography variant="subtitle2" sx={{ mb: 2 }}>
                    {title}
                </Typography>
            )}
            <Stack spacing={2}>{children}</Stack>
            <Box sx={{ mt: 1 }}>{footer}</Box>
        </Box>
    );
};
