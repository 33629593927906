import { DownloadOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateTimeString, SortOrder, Table, useQueryParams } from '../../../shared';
import { useGetCardSets } from '../../hooks';
import { ICardSet } from '../../models';

export const CardSetsTable: FC = () => {
    const { t } = useTranslation();
    const { page, setPage, pageSize, setPageSize, sort, setSort } = useQueryParams({
        defaultSort: [{ field: 'createdDate', sort: 'desc' }],
    });

    const { isLoading, data: cardSets } = useGetCardSets({
        page,
        pageSize,
        sortBy: sort[0].field,
        sortOrder: sort[0].sort?.toUpperCase() as SortOrder,
    });

    const columns: GridColDef[] = [
        {
            field: 'createdDate',
            headerName: t('createdDate'),
            minWidth: 150,
            flex: 1,
            valueFormatter: (value: string) => formatDateTimeString(value),
        },
        {
            field: 'countCards',
            headerName: t('countCards'),
            minWidth: 150,
            flex: 1,
            sortable: false,
        },
        {
            field: ' ',
            headerName: '',
            width: 60,
            align: 'right',
            sortable: false,
            renderCell: ({ row: { id } }: GridCellParams<ICardSet>) => (
                <IconButton href={`/support-api/card-sets/${id}/download`} target="_blank">
                    <DownloadOutlined />
                </IconButton>
            ),
        },
    ];

    return (
        <Table
            rows={cardSets?.data || []}
            columns={columns}
            rowCount={cardSets?.pagination.size || 0}
            page={page}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
            loading={isLoading}
            sortModel={sort}
            onSortChange={setSort}
        />
    );
};
