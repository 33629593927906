import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { FC, ReactNode } from 'react';
import { ConfirmModal } from '../confirm-modal/confirm-modal.component';

interface Props {
    onEdit?: () => void;
    onDelete?: () => void;
    deleteWarningTitle?: string;
    deleteWarningText?: string | ReactNode;
}

export const RowActions: FC<Props> = ({ onEdit, onDelete, deleteWarningTitle, deleteWarningText }) => {
    return (
        <>
            {onEdit && (
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation();
                        onEdit();
                    }}
                >
                    <EditOutlined />
                </IconButton>
            )}
            {onDelete && (
                <ConfirmModal
                    onConfirm={onDelete}
                    button={
                        <IconButton aria-label="delete">
                            <DeleteOutline />
                        </IconButton>
                    }
                    title={deleteWarningTitle || ''}
                    text={deleteWarningText || ''}
                />
            )}
        </>
    );
};
