import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { queryKeys } from '../../shared';
import { IDashboardList } from '../models';

async function getDashboards(): Promise<IDashboardList[]> {
    const { data } = await axios.get('/reporting/dashboards');
    return data;
}

export const useDashboards = () => {
    return useQuery<IDashboardList[], AxiosError>({ queryKey: [queryKeys.DASHBOARDS], queryFn: getDashboards });
};
