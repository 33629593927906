import { Search } from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ConfirmModal, IUser, Page, Table, useQueryParamsContext } from '../../../shared';
import { useDeleteUser, useGetUsers } from '../../hooks';

export const UsersPage: FC = () => {
    const { t } = useTranslation();

    const {
        queryParams: { page, pageSize, sort, search },
        debouncedQueryParams,
        setPage,
        setPageSize,
        setSort,
        setSearch,
    } = useQueryParamsContext('users', {
        sort: [{ field: 'lastName, firstName', sort: 'asc' }],
    });

    const { isPending, data: users } = useGetUsers({
        page: page,
        limit: pageSize,
        sort: sort[0].field
            .split(', ')
            .map((sortBy) => `${sortBy}:${sort[0].sort?.toUpperCase()}`)
            .join(','),
        search: debouncedQueryParams.search,
    });
    const { mutateAsync: deleteUser } = useDeleteUser();

    const columns: GridColDef[] = [
        {
            field: 'lastName, firstName',
            headerName: t('nameLabel'),
            minWidth: 200,
            flex: 1,
            valueFormatter: (_, row) => `${row.lastName} ${row.firstName}`,
        },
        {
            field: 'status',
            headerName: t('status'),
            minWidth: 50,
            flex: 0.5,
            sortable: false,
            valueFormatter: (value: string) => t(value),
        },
        {
            field: ' ',
            headerName: '',
            width: 120,
            align: 'right',
            sortable: false,
            renderCell: ({ row: { id, firstName, lastName } }: GridCellParams<IUser>) => (
                <>
                    <IconButton component={Link} to={`/admin/users/${id}`} size="large">
                        <EditIcon />
                    </IconButton>
                    <ConfirmModal
                        onConfirm={() => id && deleteUser(id)}
                        button={
                            <IconButton aria-label="delete" size="large">
                                <DeleteOutlineIcon />
                            </IconButton>
                        }
                        title={t('userDeleteWarningTitle')}
                        text={
                            <>
                                {t('userDeleteWarningText')} <br />
                                <b>
                                    {lastName} {firstName}
                                </b>
                            </>
                        }
                    />
                </>
            ),
        },
    ];

    return (
        <Page
            title={t('users')}
            actions={[
                <TextField
                    value={search}
                    onChange={({ target }) => setSearch(target.value)}
                    label={t('search')}
                    size="small"
                    data-testid="search-input"
                    slotProps={{
                        input: {
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        },
                    }}
                />,
                <Button
                    component={Link}
                    to="/admin/users/new"
                    color="primary"
                    variant="contained"
                    data-testid="newUser"
                >
                    {t('newUser')}
                </Button>,
            ]}
        >
            <Table
                rows={users?.items || []}
                columns={columns}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                rowCount={users?.meta.totalItems || 0}
                loading={isPending}
                sortModel={sort}
                onSortChange={setSort}
            />
        </Page>
    );
};
