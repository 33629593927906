import { Grid, Stack } from '@mui/material';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelectedOrganisation } from '../../contexts';
import { CustomAttributeType } from '../../enums';
import { ICustomAttributeConfiguration } from '../../models';
import { AutocompleteComponent } from '../autocomplete/autocomplete.component';
import { ControlledCheckbox } from '../controlled-checkbox/controlled-checkbox.component';
import { ControlledNumberInput } from '../controlled-number-input/controlled-number-input.component';
import { InputTextField } from '../input-text-field/input-text-field.component';

interface Props {
    customAttributeConfigurations: ICustomAttributeConfiguration[];
}

export const RegistrationInputs: FC<Props> = ({ customAttributeConfigurations }) => {
    const { t } = useTranslation();
    const { organisation } = useSelectedOrganisation();
    const { control, register } = useFormContext();

    return (
        <Stack spacing={2}>
            <AutocompleteComponent
                name={'supportType'}
                label={t('supportType')}
                control={control}
                options={organisation?.supportTypes || []}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(value, option) => value.id === option.id}
                defaultValue={null}
            />
            <InputTextField
                control={control}
                name="comment"
                label={t('comment')}
                multiline
                fullWidth
                slotProps={{ htmlInput: { maxLength: 120 } }}
            />

            <Grid container>
                {customAttributeConfigurations.map((attribute, index) => {
                    if (attribute.type === CustomAttributeType.BOOLEAN) {
                        return (
                            <Grid item xs={12} key={attribute.id}>
                                <input
                                    {...register(`attributes.${index}.configurationId`)}
                                    hidden
                                    value={attribute.id}
                                />
                                <ControlledCheckbox
                                    control={control}
                                    label={attribute.name}
                                    name={`attributes.${index}.booleanValue`}
                                />
                            </Grid>
                        );
                    }

                    if (attribute.type === CustomAttributeType.AMOUNT) {
                        return (
                            <Grid item xs={6} md={3} key={attribute.id}>
                                <input
                                    {...register(`attributes.${index}.configurationId`)}
                                    hidden
                                    value={attribute.id}
                                />
                                <ControlledNumberInput
                                    label={attribute.name}
                                    name={`attributes.${index}.amountValue`}
                                    sx={{ pr: 2, pb: 2 }}
                                />
                            </Grid>
                        );
                    }
                    return <></>;
                })}
            </Grid>
        </Stack>
    );
};
