import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { ListModel, ListQueryParam, queryKeys } from '../../shared';
import { IRegistrationReport } from '../models/registration-report.model';

interface RegistrationsParams extends ListQueryParam {
    startDate?: string;
    endDate?: string;
    organisationIds?: string[];
}

async function getRegistrations(
    params: RegistrationsParams,
    errorMessage?: string,
): Promise<ListModel<IRegistrationReport>> {
    const { data } = await axios.get('/support-api/registrations', {
        params,
        errorMessage,
    });
    return data;
}

export const useRegistrations = (params: RegistrationsParams) => {
    const { t } = useTranslation();

    return useQuery<ListModel<IRegistrationReport>, AxiosError>({
        queryKey: [queryKeys.REGISTRATIONS, params],
        queryFn: () => getRegistrations(params, t('getRegistrationsError')),
    });
};
