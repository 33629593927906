import { mixed, object, string } from 'yup';
import { ContactType } from '../enums';

export const contactSchema = () => {
    return object().shape({
        id: string().optional(),
        type: mixed<ContactType>().oneOf(Object.values(ContactType)).required(),
        data: string().required(),
    });
};
