import { Divider, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ISupportCase, useSelectedOrganisation } from '../../../shared';
import { useGetSupportCase } from '../../hooks';
import { ISupportCaseDuplicate } from '../../models';
import { DuplicateInfo } from '../duplicate-info/duplicate-info.component';
import { MergeSupportCases } from '../merge-support-cases/merge-support-cases.component';
import { NoDuplicateSupportCase } from '../no-duplicate/no-duplicate.component';

interface Props {
    supportCase: ISupportCase;
    duplicate: ISupportCaseDuplicate;
    onClose: () => void;
}

export const DuplicateSubRow: FC<Props> = ({ supportCase, duplicate, onClose }) => {
    const { t } = useTranslation();
    const { organisation } = useSelectedOrganisation();
    const hasAccess =
        window.carity.environment.shareSupportCases ||
        duplicate.organisations.some(({ id }) => organisation?.id === id);
    const { data: supportCaseDuplicate } = useGetSupportCase(duplicate.id, { enabled: hasAccess });

    return (
        <>
            <Stack direction="row" spacing={2} sx={{ pt: 2 }}>
                <Stack spacing={1} sx={{ width: '100%', p: 1 }}>
                    <Typography variant="subtitle2" gutterBottom>
                        {t('currentSupportCase')}
                    </Typography>
                    <DuplicateInfo supportCase={supportCase} />
                </Stack>
                <Divider orientation="vertical" flexItem />
                <Stack spacing={1} sx={{ width: '100%' }}>
                    <Stack direction="row" spacing={1}>
                        <Typography variant="subtitle2" gutterBottom>
                            {t('duplicateSupportCase')}
                        </Typography>
                        <NoDuplicateSupportCase subjectId={supportCase.id} targetId={duplicate.id} onClose={onClose} />
                    </Stack>
                    <DuplicateInfo supportCase={supportCaseDuplicate} duplicate={duplicate} />
                </Stack>
            </Stack>
            {hasAccess && (
                <Stack direction="row" spacing={2} sx={{ pb: 2, pl: 1 }}>
                    <MergeSupportCases subjectId={supportCase.id} targetId={duplicate.id} onClose={onClose} />
                    <Divider orientation="vertical" flexItem />
                    <MergeSupportCases subjectId={duplicate.id} targetId={supportCase.id} onClose={onClose} />
                </Stack>
            )}
        </>
    );
};
