import { adminTranslationsNL } from './admin.nl';
import { authTranslationsNL } from './auth.nl';
import { commonTranslationsNL } from './common.nl';
import { registrationTranslationsNL } from './registration.nl';
import { reportsTranslationsNL } from './reports.nl';
import { supportTranslationsNL } from './support.nl';
import { validationTranslationsNL } from './validation.nl';

export const translationsNL = {
    translation: {
        ...adminTranslationsNL,
        ...authTranslationsNL,
        ...commonTranslationsNL,
        ...supportTranslationsNL,
        ...registrationTranslationsNL,
        ...reportsTranslationsNL,
        ...validationTranslationsNL,
    },
};
