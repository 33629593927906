import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, Stack } from '@mui/material';
import React, { FC, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCreateRegistration } from '../../../registration/hooks';
import {
    ControlledDatePicker,
    DialogTitleWithClose,
    IRegistrationForm,
    RegistrationInputs,
    useGetCustomAttributeConfigurations,
    useSelectedOrganisation,
} from '../../../shared';
import { registrationFromFormMapper, registrationToFormMapper } from '../../../shared/mappers';
import { useRegistrationSchema } from '../../../registration/validators';

interface Props {
    show: boolean;
    supportCase: string;
    handleClose: () => void;
}

export const SaveRegistrationModal: FC<Props> = ({ show, supportCase, handleClose }) => {
    const { t } = useTranslation();
    const { organisation } = useSelectedOrganisation();
    const { data: customAttributeConfigurations } = useGetCustomAttributeConfigurations({
        organisationId: organisation?.id,
        pageSize: 100,
    });
    const sortedConfigurations = useMemo(() => {
        return (
            customAttributeConfigurations?.data.sort((a, b) =>
                a.type === b.type ? a.name.localeCompare(b.name) : a.type?.toString().localeCompare(b.type?.toString()),
            ) || []
        );
    }, [customAttributeConfigurations]);
    const { mutateAsync: createRegistration, isPending: isCreatingRegistration } = useCreateRegistration();

    const form = useForm<IRegistrationForm>({
        defaultValues: { dateOfRegistration: new Date(new Date().toDateString()) },
        resolver: yupResolver(useRegistrationSchema()),
        mode: 'all',
    });
    const { handleSubmit, reset, control } = form;

    useEffect(() => {
        reset({
            ...registrationToFormMapper(organisation, sortedConfigurations),
            dateOfRegistration: new Date(new Date().toDateString()),
        });
    }, [organisation, show, customAttributeConfigurations]);

    const onSubmit = async (data: IRegistrationForm) => {
        if (supportCase && organisation && data.supportType.id && sortedConfigurations) {
            handleClose();
            await createRegistration({
                supportCase,
                registration: registrationFromFormMapper(data, organisation?.id, sortedConfigurations),
            });
        }
    };

    return (
        <Dialog open={show} fullWidth maxWidth="sm">
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormProvider {...form}>
                    <DialogTitleWithClose onClose={handleClose}>{t('addRegistration')}</DialogTitleWithClose>
                    <DialogContent>
                        <Stack spacing={2} mt={2}>
                            <ControlledDatePicker name={'dateOfRegistration'} control={control} label={t('date')} />
                            <RegistrationInputs customAttributeConfigurations={sortedConfigurations} />
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" variant="contained" color="primary" disabled={isCreatingRegistration}>
                            {t('save')}
                        </Button>
                        <Button onClick={handleClose} color="secondary">
                            {t('cancel')}
                        </Button>
                    </DialogActions>
                </FormProvider>
            </form>
        </Dialog>
    );
};
