import { useMemo } from 'react';
import { array, boolean, object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { useSupporteeSchema } from './supportee.validator';
import { addressWithCountrySchema, contactSchema } from '../../shared';

export function useSupportCaseSchema() {
    const { t } = useTranslation();
    const supporteeSchema = useSupporteeSchema();

    return useMemo(
        () =>
            object().shape({
                primarySupportee: supporteeSchema.required(),
                contacts: array()
                    .of(contactSchema().shape({ primaryContact: boolean().required() }))
                    .required(),
                address: addressWithCountrySchema().optional(),
                livingSituation: string().optional(),
                familySituation: string().optional(),
            }),
        [t],
    );
}
