import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ISupportee } from '../../../shared';
import { queryKeys } from '../../constants';

async function deleteSupportee(
    supportCaseId: string,
    id: string,
    onDeleteSuccessMessage?: string,
    onDeleteErrorMessage?: string,
): Promise<ISupportee> {
    const { data } = await axios.delete(`/support-api/support-cases/${supportCaseId}/supportees/${id}`, {
        successMessage: onDeleteSuccessMessage,
        errorMessage: onDeleteErrorMessage,
    });
    return data;
}

export const useDeleteSupportee = (supportCaseId: string) => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation<ISupportee, AxiosError, string>({
        mutationFn: (id) => deleteSupportee(supportCaseId, id, t('deleteSupporteeSuccess'), t('deleteSupporteeError')),

        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [queryKeys.SUPPORT_CASE, supportCaseId] });
            await queryClient.invalidateQueries({ queryKey: [queryKeys.SUPPORT_CASES_DUPLICATES, supportCaseId] });
        },
    });
};
