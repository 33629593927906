import { Stack } from '@mui/material';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { ISupportCase, LabelValue } from '../../../shared';
import { useAddressFormatter } from '../../formatters';
import { ISupportCaseDuplicate } from '../../models';
import { FullName } from '../full-name/full-name.component';
import { SupportPeriodInfo } from './support-period-info.component';

interface Props {
    supportCase?: ISupportCase;
    duplicate?: ISupportCaseDuplicate;
}

export const DuplicateInfo: FC<Props> = ({ supportCase, duplicate }) => {
    const { t } = useTranslation();
    const address = useAddressFormatter(supportCase?.address);

    const supportPeriods = supportCase?.lastSupportPeriods || duplicate?.lastSupportPeriods || [];

    return (
        <Stack>
            <LabelValue label={t('caseNumberLabel')} value={supportCase?.caseNumber || duplicate?.caseNumber} />

            <LabelValue
                label={t('supportees')}
                value={
                    supportCase ? (
                        <>
                            {supportCase.supportees.map((supportee, index) => (
                                <Fragment key={supportee.id}>
                                    <FullName data={supportee} />
                                    {index < supportCase.supportees.length - 1 && <>, </>}
                                </Fragment>
                            ))}
                        </>
                    ) : (
                        <>{t('noAccessToSupportCase')}</>
                    )
                }
            />

            <LabelValue label={t('addressLabel')} value={supportCase ? address : t('noAccessToSupportCase')} />
            <LabelValue
                label={t('lastSupportPeriods')}
                value={
                    supportPeriods.length ? (
                        <>
                            {supportPeriods.map((period) => (
                                <Stack>
                                    <SupportPeriodInfo supportPeriod={period} />
                                </Stack>
                            ))}
                        </>
                    ) : (
                        '-'
                    )
                }
            />
        </Stack>
    );
};
