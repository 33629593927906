import { useMemo } from 'react';
import { number, object } from 'yup';
import { useTranslation } from 'react-i18next';

export function useCardSetSchema() {
    const { t } = useTranslation();
    return useMemo(
        () =>
            object().shape({
                countCards: number().min(1, t('amountMustBeGreaterThan0')).typeError(t('invalidNumber')).required(),
            }),
        [t],
    );
}
