import { AxiosError } from 'axios';
import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { BaseClient } from '../../clients';
import { IBaseModel } from '../../models';

export function useSave<ResponseModel extends IBaseModel, Data extends Record<string, any>>(
    client: BaseClient<ResponseModel, Data>,
    listKey: string,
    itemKey: string,
): UseMutationResult<ResponseModel, AxiosError, { item: Data; id?: string }> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ item, id }) => (id ? client.updateOne(id, item) : client.createOne(item)),
        onSuccess: async ({ id }) => {
            await queryClient.invalidateQueries({ queryKey: [listKey] });
            await queryClient.invalidateQueries({ queryKey: [itemKey, id] });
        },
    });
}

export function useDelete<ResponseModel extends IBaseModel, Data extends Record<string, any>>(
    client: BaseClient<ResponseModel, Data>,
    listKey: string,
): UseMutationResult<ResponseModel, AxiosError, string> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (id) => client.deleteOne(id),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [listKey] });
        },
    });
}
