import { Box } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation } from 'react-router-dom';
import { PermissionKeys, ProtectedRoute, SideBar, SideNav, useHasPermission } from '../../../shared';
import { supportCaseRoutes } from '../../support.routes';

export const SupportCasePage: FC = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { hasPermission } = useHasPermission();
    const hasIntakesReadPermission = hasPermission(PermissionKeys.INTAKES_READ);

    const getSupportCaseUrl = (to: string) => {
        return `${location.pathname.split('/').slice(0, 4).join('/')}/${to}`;
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <SideBar>
                <SideNav
                    items={[
                        {
                            path: getSupportCaseUrl('detail'),
                            text: t('details'),
                        },
                        ...(!window.carity.environment.shareSupportCases
                            ? [
                                  {
                                      path: getSupportCaseUrl('organisations'),
                                      text: t('organisations'),
                                  },
                              ]
                            : []),
                        {
                            path: getSupportCaseUrl('support-periods'),
                            text: t('supportPeriods'),
                        },
                        ...(hasIntakesReadPermission
                            ? [{ path: getSupportCaseUrl('intakes'), text: t('intakes') }]
                            : []),

                        {
                            path: getSupportCaseUrl('registrations'),
                            text: t('registrations'),
                        },
                        {
                            path: getSupportCaseUrl('comments'),
                            text: t('comments'),
                        },
                        {
                            path: getSupportCaseUrl('cards'),
                            text: t('cards'),
                        },
                    ]}
                />
            </SideBar>
            <Box sx={{ flex: 1 }}>
                <Routes>
                    {supportCaseRoutes.map((subRoute) => (
                        <Route
                            key={subRoute.path}
                            path={subRoute.path}
                            element={
                                <ProtectedRoute requiredPermission={subRoute.requiredPermissions}>
                                    <subRoute.component />
                                </ProtectedRoute>
                            }
                        />
                    ))}
                </Routes>
            </Box>
        </Box>
    );
};
