import { Box, CircularProgress } from '@mui/material';
import React from 'react';

interface Props {
    fullscreen?: boolean;
}

export const Loading: React.FC<Props> = ({ fullscreen }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                width: fullscreen ? '100vw' : '100%',
                height: fullscreen ? '100vh' : '100%',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <CircularProgress />
        </Box>
    );
};
