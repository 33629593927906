import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { ListModel, ListQueryParam } from '../../../shared';
import { queryKeys } from '../../constants';
import { IComment } from '../../models';

async function getComments(
    caseId: string,
    organisationId: string,
    params: ListQueryParam,
    errorMessage: string,
): Promise<ListModel<IComment>> {
    const { data } = await axios.get(`/support-api/support-cases/${caseId}/comments`, {
        params: { ...params, organisationId },
        errorMessage: errorMessage,
    });
    return data;
}

export const useGetComments = (caseId: string, organisation: string, params: ListQueryParam) => {
    const { t } = useTranslation();
    return useQuery<ListModel<IComment>, AxiosError>({
        queryKey: [queryKeys.COMMENTS, caseId, organisation, params],
        queryFn: () => getComments(caseId, organisation, params, t('getCommentsError')),
        enabled: !!caseId,
    });
};
