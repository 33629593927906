import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { AUTH_API_BASE_URL, AuthListQueryParam, IUser, ListModelItems, queryKeys } from '../../../shared';

async function getUsers(params: AuthListQueryParam, onGetErrorMessage?: string): Promise<ListModelItems<IUser>> {
    const { data } = await axios.get(`${AUTH_API_BASE_URL}/v1/users`, { params, errorMessage: onGetErrorMessage });

    return data;
}

export const useGetUsers = (params: AuthListQueryParam) => {
    const { t } = useTranslation();

    return useQuery<ListModelItems<IUser>, AxiosError>({
        queryKey: [queryKeys.USERS, params],
        queryFn: () => getUsers(params, t('getUsersError')),
    });
};
