import { Alert, AlertTitle, Button, Grid } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSupportCaseDuplicates } from '../../hooks';
import { DuplicatesModal } from '../duplicates-modal/duplicates-modal.component';

export const DuplicatesWarning: FC<{ supportCaseId: string }> = ({ supportCaseId }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const { data: duplicates = [] } = useSupportCaseDuplicates(supportCaseId);

    return duplicates.length > 0 ? (
        <Grid item sm={12} sx={{ mb: 3 }}>
            <Alert
                severity="warning"
                action={
                    <Button color="warning" variant="contained" onClick={() => setIsOpen(true)}>
                        {t('showDuplicates')}
                    </Button>
                }
            >
                <AlertTitle>{t('duplicates')}</AlertTitle>
                {t('duplicatesMessage', { count: duplicates.length })}
            </Alert>
            <DuplicatesModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                supportCaseId={supportCaseId}
                duplicates={duplicates}
            />
        </Grid>
    ) : null;
};
