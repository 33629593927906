import { yupResolver } from '@hookform/resolvers/yup';
import { QrCodeTwoTone } from '@mui/icons-material';
import { Button, Divider, Stack } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    FormGrid,
    IRegistrationForm,
    RegistrationInputs,
    useGetCustomAttributeConfigurations,
    useSelectedOrganisation,
    Widget,
} from '../../../shared';
import { registrationFromFormMapper, registrationToFormMapper } from '../../../shared/mappers';
import { InfoLastRegistrations } from '../../components';
import { useCreateRegistration } from '../../hooks';
import { ISupportCaseInfo } from '../../models';
import { useRegistrationSchema } from '../../validators';

interface Props {
    supportCase: ISupportCaseInfo;
}

export const RegistrationForm: FC<Props> = ({ supportCase }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { organisation } = useSelectedOrganisation();
    const { mutateAsync: createRegistration, isPending: isCreatingRegistration } = useCreateRegistration();
    const { data: customAttributeConfigurations } = useGetCustomAttributeConfigurations({
        organisationId: organisation?.id,
        pageSize: 100,
    });
    const sortedConfigurations = useMemo(() => {
        return (
            customAttributeConfigurations?.data.sort((a, b) =>
                a.type === b.type ? a.name.localeCompare(b.name) : a.type?.toString().localeCompare(b.type?.toString()),
            ) || []
        );
    }, [customAttributeConfigurations]);

    const form = useForm<IRegistrationForm>({ resolver: yupResolver(useRegistrationSchema()) });

    const onSubmit = useCallback(
        async (registration: IRegistrationForm) => {
            if (supportCase && organisation?.id && registration.supportType.id && customAttributeConfigurations) {
                await createRegistration({
                    supportCase: supportCase.supportCaseId,
                    registration: registrationFromFormMapper(
                        registration,
                        organisation?.id,
                        customAttributeConfigurations.data,
                    ),
                });
                navigate('/registration');
            }
        },
        [supportCase, organisation, customAttributeConfigurations],
    );

    useEffect(() => {
        form.reset(registrationToFormMapper(organisation, sortedConfigurations));
    }, [organisation, form, sortedConfigurations]);

    return (
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormProvider {...form}>
                <Widget
                    icon={<QrCodeTwoTone color="primary" />}
                    title={t('registrations')}
                    footer={
                        <FormGrid ml={2}>
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={organisation?.supportTypes?.length == 0 || isCreatingRegistration}
                            >
                                {t('register')}
                            </Button>

                            <Button type="button" variant="outlined" onClick={() => navigate('/registration')}>
                                {t('cancelRegistration')}
                            </Button>
                        </FormGrid>
                    }
                >
                    <Stack spacing={2} mb={4}>
                        <InfoLastRegistrations registrations={supportCase.lastRegistrations} /> <Divider />
                    </Stack>
                    <RegistrationInputs customAttributeConfigurations={sortedConfigurations} />
                </Widget>
            </FormProvider>
        </form>
    );
};
