import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { queryKeys } from '../../constants';
import { IComment } from '../../models';

async function deleteComment(caseId: string, id: string, onDeleteErrorMessage: string): Promise<IComment> {
    const { data } = await axios.delete(`/support-api/support-cases/${caseId}/comments/${id}`, {
        errorMessage: onDeleteErrorMessage,
    });
    return data;
}

export const useDeleteComment = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation<IComment, AxiosError, { supportCase: string; comment: string }>({
        mutationFn: (data) => deleteComment(data.supportCase, data.comment, t('deleteCommentError')),

        onSuccess: async (data, variables) => {
            await queryClient.invalidateQueries({ queryKey: [queryKeys.COMMENTS, variables.supportCase] });
        },
    });
};
