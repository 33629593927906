import { object, string } from 'yup';

export const referrerContactSchema = () => {
    return object().shape({
        id: string().optional(),
        name: string().optional(),
        email: string().optional(),
        phone: string().optional(),
    });
};
