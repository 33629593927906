import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button } from '@mui/material';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { object, string } from 'yup';
import { FormCard, FormCardSectionComponent, InputTextField, IParams, ISaveIncomeType, Page } from '../../../shared';
import { useIncomeType, useSaveIncomeType } from '../../hooks';

export const IncomeTypeEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams<keyof IParams>() as IParams;

    const { data: incomeType, isFetching } = useIncomeType(id);
    const { mutateAsync: saveIncomeType, isPending } = useSaveIncomeType();

    const form = useForm<ISaveIncomeType>({
        resolver: yupResolver(object().shape({ name: string().required() })),
        mode: 'onSubmit',
    });

    useEffect(() => {
        if (incomeType) {
            form.reset(incomeType);
        }
    }, [form, incomeType]);

    const onSubmit = useCallback(
        async (item: ISaveIncomeType) => {
            await saveIncomeType({ id, item });
            navigate('/admin/income-types');
        },
        [saveIncomeType, id, navigate],
    );

    const actions = useMemo(
        () => [
            <Button variant="contained" color="primary" onClick={form.handleSubmit(onSubmit)} disabled={isPending}>
                {t('save')}
            </Button>,
            <Button onClick={() => navigate('/admin/income-types')} color="secondary">
                {t('cancel')}
            </Button>,
        ],
        [form, onSubmit, isPending, t, navigate],
    );
    const reversedActions = useMemo(() => [...actions].reverse(), [actions]);

    return (
        <Page
            title={<Box width="300px">{id ? t('editIncomeType') : t('newIncomeType')}</Box>}
            actions={reversedActions}
            onBack={() => navigate('/admin/income-types')}
            loading={isFetching || isPending}
        >
            <FormCard handleSubmit={form.handleSubmit(onSubmit)} actions={actions}>
                <FormCardSectionComponent title={t('data')}>
                    <InputTextField
                        name="name"
                        label={t('nameLabel')}
                        control={form.control}
                        required
                        sx={{ width: '400px' }}
                    />
                </FormCardSectionComponent>
            </FormCard>
        </Page>
    );
};
