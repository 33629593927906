import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Login, Unauthenticated, useGetUser } from '../../../auth';
import {
    DrawerContextProvider,
    Loading,
    PermissionsContext,
    SelectedOrganisationProvider,
    useGetUserRoles,
} from '../../../shared';
import { Header } from '../header/header.component';
import { Routes } from '../routes/routes.component';

export const Main: FC = () => {
    const location = useLocation();
    const { data: user, isLoading: isUserLoading } = useGetUser();
    const { data: roles, isLoading: areRolesLoading } = useGetUserRoles(user?.data?.id);

    if (isUserLoading || areRolesLoading) return <Loading fullscreen />;

    if (location.pathname === '/unauthenticated') return <Unauthenticated />;

    if (!user?.success) return <Login />;

    return (
        <PermissionsContext.Provider value={{ permissions: roles?.items }}>
            <SelectedOrganisationProvider>
                <DrawerContextProvider>
                    <Header />
                    <Routes />
                </DrawerContextProvider>
            </SelectedOrganisationProvider>
        </PermissionsContext.Provider>
    );
};
