import { IRouteConfig, IRouteConfigWithComponent, PermissionKeys } from '../shared';
import {
    AdminPage,
    CardSetsPage,
    CustomAttributeConfigurationEditPage,
    CustomAttributeConfigurationsPage,
    ExpenseTypeEditPage,
    ExpenseTypesPage,
    IncomeTypeEditPage,
    FoodRestrictionsPage,
    FoodRestrictionEditPage,
    IncomeTypesPage,
    OrganisationEditPage,
    OrganisationsPage,
    OrganisationTypeEditPage,
    OrganisationTypePage,
    ReferralOrganisationEditPage,
    ReferralOrganisationsPage,
    SupportTypesEditPage,
    SupportTypesFrequenciesEditPage,
    SupportTypesPage,
    UserEditPage,
    UsersPage,
} from './pages';

export const adminRoutes: IRouteConfig[] = [
    {
        path: '/admin',
        redirect: '/admin/custom-attribute-configurations',
    },
    { path: '/admin/*', component: AdminPage },
];

export const adminSubRoutes: IRouteConfigWithComponent[] = [
    {
        path: '/organisations',
        component: OrganisationsPage,
        requiredPermissions: PermissionKeys.ORGANISATIONS_READ,
        skipOrganisationCheck: true,
    },
    {
        path: '/organisations/edit',
        component: OrganisationEditPage,
        requiredPermissions: PermissionKeys.ORGANISATIONS_WRITE,
        skipOrganisationCheck: true,
    },
    {
        path: '/organisations/edit/:id',
        component: OrganisationEditPage,
        requiredPermissions: PermissionKeys.ORGANISATIONS_WRITE,
        skipOrganisationCheck: true,
    },
    {
        path: '/organisation-types',
        component: OrganisationTypePage,
        requiredPermissions: PermissionKeys.ORGANISATION_TYPES_READ,
        skipOrganisationCheck: true,
    },
    {
        path: '/organisation-types/edit',
        component: OrganisationTypeEditPage,
        requiredPermissions: PermissionKeys.ORGANISATION_TYPES_WRITE,
        skipOrganisationCheck: true,
    },
    {
        path: '/organisation-types/edit/:id',
        component: OrganisationTypeEditPage,
        requiredPermissions: PermissionKeys.ORGANISATION_TYPES_WRITE,
        skipOrganisationCheck: true,
    },
    {
        path: '/support-types',
        component: SupportTypesPage,
        requiredPermissions: PermissionKeys.SUPPORT_TYPES_READ,
        skipOrganisationCheck: true,
    },
    {
        path: '/support-types/new',
        component: SupportTypesEditPage,
        requiredPermissions: PermissionKeys.SUPPORT_TYPES_WRITE,
        skipOrganisationCheck: true,
    },
    {
        path: '/support-types/:id/edit',
        component: SupportTypesEditPage,
        requiredPermissions: PermissionKeys.SUPPORT_TYPES_WRITE,
        skipOrganisationCheck: true,
    },
    {
        path: '/users',
        component: UsersPage,
        requiredPermissions: PermissionKeys.USERS_READ,
        skipOrganisationCheck: true,
    },
    {
        path: '/users/new',
        component: UserEditPage,
        requiredPermissions: PermissionKeys.USERS_WRITE,
        skipOrganisationCheck: true,
    },
    {
        path: '/users/:id',
        component: UserEditPage,
        requiredPermissions: PermissionKeys.USERS_WRITE,
        skipOrganisationCheck: true,
    },
    {
        path: '/referral-organisations',
        component: ReferralOrganisationsPage,
        requiredPermissions: PermissionKeys.REFERRAL_ORGANISATIONS_WRITE,
        skipOrganisationCheck: true,
    },
    {
        path: '/referral-organisations/edit',
        component: ReferralOrganisationEditPage,
        requiredPermissions: PermissionKeys.REFERRAL_ORGANISATIONS_WRITE,
        skipOrganisationCheck: true,
    },
    {
        path: '/referral-organisations/edit/:id',
        component: ReferralOrganisationEditPage,
        requiredPermissions: PermissionKeys.REFERRAL_ORGANISATIONS_WRITE,
        skipOrganisationCheck: true,
    },
    {
        path: '/custom-attribute-configurations',
        component: CustomAttributeConfigurationsPage,
        requiredPermissions: PermissionKeys.ATTRIBUTES_WRITE,
    },
    {
        path: '/custom-attribute-configurations/edit',
        component: CustomAttributeConfigurationEditPage,
        requiredPermissions: PermissionKeys.ATTRIBUTES_WRITE,
    },
    {
        path: '/custom-attribute-configurations/edit/:id',
        component: CustomAttributeConfigurationEditPage,
        requiredPermissions: PermissionKeys.ATTRIBUTES_WRITE,
    },
    {
        path: '/card-sets',
        component: CardSetsPage,
        requiredPermissions: PermissionKeys.CARDS_WRITE,
        skipOrganisationCheck: true,
    },
    {
        path: '/frequencies-edit',
        component: SupportTypesFrequenciesEditPage,
        requiredPermissions: PermissionKeys.SUPPORT_TYPE_FREQUENCIES_WRITE,
    },
    {
        path: 'income-types',
        component: IncomeTypesPage,
        requiredPermissions: [PermissionKeys.INCOME_TYPES_READ, PermissionKeys.INCOME_TYPES_WRITE],
        skipOrganisationCheck: true,
    },
    {
        path: 'income-types/new',
        component: IncomeTypeEditPage,
        requiredPermissions: [PermissionKeys.INCOME_TYPES_READ, PermissionKeys.INCOME_TYPES_WRITE],
        skipOrganisationCheck: true,
    },
    {
        path: 'income-types/:id/edit',
        component: IncomeTypeEditPage,
        requiredPermissions: [PermissionKeys.INCOME_TYPES_READ, PermissionKeys.INCOME_TYPES_WRITE],
        skipOrganisationCheck: true,
    },
    {
        path: 'expense-types',
        component: ExpenseTypesPage,
        requiredPermissions: [PermissionKeys.EXPENSE_TYPES_READ, PermissionKeys.EXPENSE_TYPES_WRITE],
        skipOrganisationCheck: true,
    },
    {
        path: 'expense-types/new',
        component: ExpenseTypeEditPage,
        requiredPermissions: [PermissionKeys.EXPENSE_TYPES_READ, PermissionKeys.EXPENSE_TYPES_WRITE],
        skipOrganisationCheck: true,
    },
    {
        path: 'expense-types/:id/edit',
        component: ExpenseTypeEditPage,
        requiredPermissions: [PermissionKeys.EXPENSE_TYPES_READ, PermissionKeys.EXPENSE_TYPES_WRITE],
        skipOrganisationCheck: true,
    },
    {
        path: '/food-restrictions',
        component: FoodRestrictionsPage,
        requiredPermissions: [PermissionKeys.FOOD_RESTRICTIONS_READ, PermissionKeys.FOOD_RESTRICTIONS_WRITE],
        skipOrganisationCheck: true,
    },
    {
        path: '/food-restrictions/new',
        component: FoodRestrictionEditPage,
        requiredPermissions: [PermissionKeys.FOOD_RESTRICTIONS_READ, PermissionKeys.FOOD_RESTRICTIONS_WRITE],
        skipOrganisationCheck: true,
    },
    {
        path: '/food-restrictions/:id/edit',
        component: FoodRestrictionEditPage,
        requiredPermissions: [PermissionKeys.FOOD_RESTRICTIONS_READ, PermissionKeys.FOOD_RESTRICTIONS_WRITE],
        skipOrganisationCheck: true,
    },
];
