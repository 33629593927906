import { Checkbox, ListItemText, MenuItem } from '@mui/material';
import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelectedOrganisation } from '../../contexts';
import { IOrganisation } from '../../models';
import { ControlledSelect } from '../controlled-select/controlled-select.component';

interface Props {
    name: string;
    label: string;
    form: UseFormReturn;
}

export const ControlledSelectOrganisations: FC<Props> = ({ name, label, form }) => {
    const { t } = useTranslation();
    const { organisations: organisationOptions } = useSelectedOrganisation(); //organisations that I have access to
    const currentOrganisations = form.watch(name);

    const getOrganisationNameById = (id: string): string =>
        organisationOptions?.find((item) => item.id == id)?.name || t('unknown');

    const formatSelectedOrganisations = (ids: string[]) =>
        ids?.length == organisationOptions?.length ? t('all') : ids.map(getOrganisationNameById).join(', ');

    return (
        <ControlledSelect
            name={name}
            label={label}
            control={form.control}
            multiple
            items={organisationOptions.map(({ id }) => id)}
            renderValue={(ids) => formatSelectedOrganisations(ids as string[])}
            size="small"
        >
            <MenuItem value="all">
                <Checkbox checked={currentOrganisations?.length === (organisationOptions?.length || 0)} />
                <ListItemText primary={t('all')} />
            </MenuItem>
            {organisationOptions.map((organisation: IOrganisation) => (
                <MenuItem value={organisation.id} key={organisation.id}>
                    <Checkbox checked={currentOrganisations?.includes(organisation.id)} />
                    <ListItemText primary={organisation.name} />
                </MenuItem>
            ))}
        </ControlledSelect>
    );
};
