export const reportsTranslationsNL = {
    reports: 'Rapporten',
    report: {
        registrations: 'Registraties',
    },
    startDate: 'Begindatum',
    endDate: 'Einddatum',
    min: 'Minimum',
    max: 'Maximum',
    all: 'Alle',
    export: 'Exporteer rapport',
    filter: 'Filter',
};
