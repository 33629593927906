import { IOrganisation } from '../../models';
import { useGetOrganisations } from './get-organisations.hook';

export function useOrganisations(): IOrganisation[] {
    const { data } = useGetOrganisations({
        page: 1,
        pageSize: 100,
        sortBy: 'name',
        sortOrder: 'ASC',
    });
    return data?.data || [];
}
