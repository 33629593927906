import { add } from 'date-fns';
import { ICompleteIntake, IIntake, IIntakeCompletionForm, IOrganisation } from '../../shared';
import { mapRequiredDateString } from './date-string.mapper';
import { IntakeIncomeResult } from './use-intake-income-result.mapper';

export const intakeCompletionToFormMapper = (
    organisation: IOrganisation,
    intake: IIntake,
    intakeIncomeResult: IntakeIncomeResult,
): IIntakeCompletionForm => {
    return {
        createSupportPeriod: intake.externalIntake || intakeIncomeResult.someIncomeLimitMet || false,
        supportTypesInfo: intake.externalIntake
            ? organisation.supportTypes.map(({ id }) => ({
                  supportTypeId: id,
              }))
            : organisation.supportTypes
                  .filter(
                      (type) =>
                          intakeIncomeResult.incomeLimits?.find((limit) => limit.supportTypeId === type.id)?.enabled,
                  )
                  .map(({ id }) => ({
                      supportTypeId: id,
                  })),
        startDate: new Date(),
        endDate: add(new Date(), { weeks: organisation.defaultSupportPeriod }),
        comment: intake.comment || '',
        referralOrganisation: intake?.supportPeriod?.referralOrganisation || null,
        contact: intake?.supportPeriod?.contact || null,
        isNewContact: false,
    };
};

export const intakeCompletionFormFormMapper = (item: IIntakeCompletionForm): ICompleteIntake => {
    return {
        ...item,
        supportPeriodStartDate: item.createSupportPeriod ? mapRequiredDateString(item.startDate) : undefined,
        supportPeriodEndDate: item.createSupportPeriod ? mapRequiredDateString(item.endDate) : undefined,
        supportTypes: item.supportTypesInfo,
        referralOrganisation: item.referralOrganisation?.id || null,
        contact: item.contact?.id || null,
        newContact:
            item.referralOrganisation && item.newContact
                ? { ...item.newContact, organisation: item.referralOrganisation.id }
                : null,
    };
};
