import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import { AxiosError } from 'axios';
import React, { FC, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import {
    ConfirmModal,
    ControlledCheckbox,
    FormCard,
    FormCardSectionComponent,
    FormGrid,
    InputTextField,
    IParams,
    IUserForm,
    Page,
    StatusCode,
} from '../../../shared';
import { UserRoles } from '../../components';
import { useDeleteUser, useGetUser, useSaveUser } from '../../hooks';
import { useUserSchema } from '../../validators';

export const UserEditPage: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams<keyof IParams>() as IParams;

    const { data } = useGetUser(id);
    const { mutateAsync: deleteUser } = useDeleteUser();

    const onDelete = useCallback(async () => {
        await deleteUser(id);
        navigate('/admin/users');
    }, [deleteUser, navigate, id]);

    const { control, reset, handleSubmit, setError } = useForm<IUserForm>({
        resolver: yupResolver(useUserSchema()),
        mode: 'onSubmit',
        shouldUnregister: true,
    });

    useEffect(() => {
        if (data) {
            reset({ ...data, enabled: data.status === 'enabled' });
        }
    }, [data, reset]);

    const { mutateAsync: saveUser } = useSaveUser();

    const onSubmit = async ({ enabled, ...form }: IUserForm) => {
        try {
            const user = await saveUser({
                id,
                ...form,
                sub: data ? data.sub : form.email,
                status: enabled ? 'enabled' : 'pending',
            });

            if (id) navigate(`/admin/users`);
            else navigate(`/admin/users/${user.id}`);
        } catch (err) {
            if ((err as AxiosError)?.response?.status === StatusCode.CONFLICT) {
                setError('email', {
                    message: t('emailUniqErr'),
                });
            } else {
                throw err;
            }
        }
    };

    const actions = [
        <Button onClick={() => navigate('/admin/users')} color="secondary">
            {t('cancel')}
        </Button>,
        <ConfirmModal
            onConfirm={onDelete}
            button={
                <Button color="primary" variant="outlined" data-testid="deleteUser">
                    {t('delete')}
                </Button>
            }
            title={t('userDeleteWarningTitle')}
            text={t('userDeleteWarningText')}
        />,
        <Button type="submit" variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
            {t('save')}
        </Button>,
    ];

    return (
        <Page title={id ? `${data?.firstName} ${data?.lastName}` : t('newUser')} actions={actions}>
            <FormCard actions={actions.slice().reverse()} handleSubmit={handleSubmit(onSubmit)}>
                <FormCardSectionComponent title={t('data')}>
                    <FormGrid xs={3}>
                        <InputTextField name="email" label={t('emailLabel')} control={control} required />
                        <InputTextField name="firstName" label={t('firstNameLabel')} control={control} required />
                        <InputTextField name="lastName" label={t('lastNameLabel')} control={control} required />
                    </FormGrid>
                </FormCardSectionComponent>
                <FormCardSectionComponent title={t('status')}>
                    <ControlledCheckbox name="enabled" label={t('enabled')} control={control} />
                </FormCardSectionComponent>
                {id && (
                    <FormCardSectionComponent title={t('roles')}>
                        <UserRoles userId={id} />
                    </FormCardSectionComponent>
                )}
            </FormCard>
        </Page>
    );
};
