import { ISaveSupportCase, ISupportCaseForm } from '../models';
import { supporteeMapperFromForm } from './supportee.mapper';
import { FamilySituation, LivingSituation } from '../enums';

export const supportCaseFromFormMapper = (
    data: ISupportCaseForm,
    organisationId: string,
    relations: { value: string; translation: string }[],
): ISaveSupportCase => {
    return {
        ...data,
        supportees: [],
        primarySupportee: supporteeMapperFromForm(data.primarySupportee, relations),
        organisations: [{ id: organisationId }],
        livingSituation: (data?.livingSituation as LivingSituation) || null,
        familySituation: (data?.familySituation as FamilySituation) || null,
        address: { ...data.address, country: data.address?.country?.code || '' },
    };
};
