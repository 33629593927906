import { FC, useMemo } from 'react';
import { Alert, AlertTitle, List, ListItem, Stack, Typography } from '@mui/material';
import { FullName } from '../full-name/full-name.component';
import { useGetSupportCase } from '../../hooks';
import { useTranslation } from 'react-i18next';

interface Props {
    supportCaseId?: string;
}

export const IntakeIntroWarning: FC<Props> = ({ supportCaseId }) => {
    const { t } = useTranslation();
    const { data: supportCase } = useGetSupportCase(supportCaseId as string);

    const invalidData = useMemo(() => {
        return supportCase?.supportees
            ?.filter((supportee) => !supportee.nationalSecurityNumber || !supportee.gender || !supportee.dateOfBirth)
            .map((supportee) => {
                const invalidNSN = !supportee.nationalSecurityNumber ? `${t('nationalSecurityNumberLabel')} ` : '';
                const invalidGender = !supportee.gender ? `${t('genderLabel')} ` : '';
                const invalidDateOfBirth = !supportee.dateOfBirth ? `${t('dateOfBirthLabel')}` : '';

                return {
                    supportee: supportee,
                    invalid: (invalidNSN + invalidGender + invalidDateOfBirth).split(' ').join(', '),
                };
            });
    }, [supportCase]);

    return (
        <>
            {invalidData && invalidData.length > 0 && (
                <Alert severity="warning">
                    <AlertTitle>{t('intakeWarningTitle')}</AlertTitle>
                    <Typography variant="body2">{t('intakeWarningPart1')}</Typography>
                    <List sx={{ listStyleType: 'disc', pl: 2 }}>
                        {invalidData.map((data) => (
                            <ListItem
                                sx={{ span: { fontStyle: 'normal' }, pl: 1, display: 'list-item' }}
                                key={data.supportee.id}
                            >
                                <Stack direction="row">
                                    <FullName data={data.supportee} />
                                    <Typography variant="body2" ml={1}>{`(${data.invalid})`}</Typography>
                                </Stack>
                            </ListItem>
                        ))}
                    </List>
                    <Typography variant="body2">{t('intakeWarningPart2')}</Typography>
                </Alert>
            )}
        </>
    );
};
