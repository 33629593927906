import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateString, ISupportPeriod } from '../../../shared';

interface Props {
    supportPeriod: ISupportPeriod;
}

export const SupportPeriodInfo: FC<Props> = ({ supportPeriod: period }) => {
    const { t } = useTranslation();
    const active = new Date(period.startDate) <= new Date() && new Date() <= new Date(period.endDate);
    return (
        <span>
            {period.supportTypesInfo.map(({ supportType }) => supportType?.name).join(', ')}
            {`: ${formatDateString(period.startDate)} - ${formatDateString(period.endDate)}`}
            {!active && <b> ({t('inactive')})</b>}
        </span>
    );
};
