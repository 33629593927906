import axios, { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ISupportCase } from '../../../shared';
import { queryKeys } from '../../constants';
import { useTranslation } from 'react-i18next';

async function mergeSupportCase(
    subjectId: string,
    targetId: string,
    onSuccessMessage?: string,
    onErrorMessage?: string,
): Promise<ISupportCase> {
    const { data } = await axios.post(
        `/support-api/support-cases/${subjectId}/merge`,
        { targetId },
        {
            successMessage: onSuccessMessage,
            errorMessage: onErrorMessage,
        },
    );
    return data;
}

export const useMergeSupportCase = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation<ISupportCase, AxiosError, { subjectId: string; targetId: string }>({
        mutationFn: ({ subjectId, targetId }) =>
            mergeSupportCase(subjectId, targetId, t('mergeSupportCaseSuccess'), t('mergeSupportCaseError')),

        onSuccess: async (result, params) => {
            await queryClient.invalidateQueries({ queryKey: [queryKeys.SUPPORT_CASES] });
            await queryClient.invalidateQueries({ queryKey: [queryKeys.SUPPORT_CASE, params.targetId] });
            await queryClient.invalidateQueries({ queryKey: [queryKeys.SUPPORT_CASE_INFO, params.targetId] });
            await queryClient.invalidateQueries({ queryKey: [queryKeys.SUPPORT_CASES_DUPLICATES, params.targetId] });
        },
    });
};
