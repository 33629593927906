import React, { FC, Fragment } from 'react';
import { FormCardSectionComponent, FormGrid, InputTextField } from '../../../shared';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const OrganisationPreferredPostalCodeInputs: FC = () => {
    const { t } = useTranslation();
    const { control } = useFormContext();

    const {
        fields: preferredPostalCodes,
        append,
        remove,
    } = useFieldArray({
        control,
        name: 'preferredPostalCodes',
    });

    return (
        <FormCardSectionComponent
            title={t('preferredPostalCodes')}
            footer={
                <Button onClick={() => append('')} color="secondary">
                    + {t('addPreferredPostalCode')}
                </Button>
            }
        >
            {preferredPostalCodes.map((postalCode, index) => (
                <Fragment key={postalCode.id}>
                    <FormGrid>
                        <InputTextField
                            name={`preferredPostalCodes.${index}`}
                            label={t('postalCodeLabel')}
                            control={control}
                            required
                        />
                        <IconButton aria-label="delete" onClick={() => remove(index)} size="large">
                            <CloseIcon />
                        </IconButton>
                    </FormGrid>
                </Fragment>
            ))}
        </FormCardSectionComponent>
    );
};
