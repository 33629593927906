import { Button } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Page } from '../../../shared';
import { OrganisationTypesTable } from '../../components';

export const OrganisationTypePage: FC = () => {
    const { t } = useTranslation();

    return (
        <Page
            title={t('organisationTypes')}
            actions={
                <Button
                    component={Link}
                    to="/admin/organisation-types/edit"
                    color="primary"
                    variant="contained"
                    data-testid="newOrganisationType"
                >
                    {t('newOrganisationType')}
                </Button>
            }
        >
            <OrganisationTypesTable />
        </Page>
    );
};
