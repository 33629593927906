import * as countries from 'i18n-iso-countries';
import i18n from 'i18next';
import { useMemo } from 'react';
import { IAddress } from '../../shared';

export const useAddressFormatter = (address?: IAddress) => {
    return useMemo(() => {
        if (!address) return '';
        const country = address.country ? countries.getName(address.country, i18n.language.slice(0, 2)) : '';
        return [
            `${address.street || ''} ${address.number || ''} ${address.box || ''}`.trim(),
            `${address.postalCode || ''} ${address.city || ''}`.trim(),
            country,
        ]
            .filter(Boolean)
            .join(', ');
    }, [address]);
};
