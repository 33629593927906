import React, { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    ControlledCheckbox,
    ControlledDatePicker,
    FormCardSectionComponent,
    FormGrid,
    getDefaultSupportTypes,
    IIntakeCompletionForm,
    useSelectedOrganisation,
} from '../../../shared';
import { SupportTypesInfo } from '../support-types-info/support-types-info.component';
import { ReferrerInputs } from '../save-support-period-modal/referrer-inputs.component';

interface Props {
    form: UseFormReturn<IIntakeCompletionForm>;
}

export const IntakeCreateSupportPeriod: FC<Props> = ({ form }) => {
    const { t } = useTranslation();
    const { organisation } = useSelectedOrganisation();
    const createSupportPeriod = form.watch('createSupportPeriod');
    const defaultSupportTypes = getDefaultSupportTypes(organisation);

    return (
        <FormCardSectionComponent title={t('supportPeriod')}>
            <ControlledCheckbox name="createSupportPeriod" control={form.control} label={t('createSupportPeriod')} />
            {createSupportPeriod && (
                <>
                    <FormGrid sx={{ mb: 2, mt: 1 }}>
                        <ControlledDatePicker name="startDate" label={t('startDateLabel')} />
                        <ControlledDatePicker name="endDate" label={t('endDateLabel')} />
                    </FormGrid>

                    <SupportTypesInfo
                        supportTypes={organisation?.supportTypes || []}
                        defaultSupportTypes={defaultSupportTypes}
                    />

                    <ReferrerInputs />
                </>
            )}
        </FormCardSectionComponent>
    );
};
