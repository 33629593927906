import { TextField } from '@mui/material';
import { ComponentProps, FC } from 'react';
import { Control, Controller } from 'react-hook-form';

type Props = ComponentProps<typeof TextField> & {
    name: string;
    control?: Control<any>;
    required?: boolean;
    multiline?: boolean;
    fullWidth?: boolean;
    disabled?: boolean;
};

export const InputTextField: FC<Props> = ({
    name,
    label,
    control,
    required,
    multiline,
    fullWidth,
    disabled,
    ...props
}) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                    label={label}
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                    required={required}
                    id={`${name}-field`}
                    multiline={multiline}
                    rows={multiline ? 3 : undefined}
                    fullWidth={fullWidth}
                    disabled={disabled}
                    {...props}
                />
            )}
        />
    );
};
