import axios, { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ISupportCase } from '../../../shared';
import { queryKeys } from '../../constants';
import { useTranslation } from 'react-i18next';

async function deleteSupportCase(
    id: string,
    onDeleteSuccessMessage?: string,
    onDeleteErrorMessage?: string,
): Promise<ISupportCase> {
    const { data } = await axios.delete(`/support-api/support-cases/${id}`, {
        successMessage: onDeleteSuccessMessage,
        errorMessage: onDeleteErrorMessage,
    });
    return data;
}

export const useDeleteSupportCase = () => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation<ISupportCase, AxiosError, string>({
        mutationFn: (id) => deleteSupportCase(id, t('deleteSupportCaseSuccess'), t('deleteSupportCaseError')),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [queryKeys.SUPPORT_CASES] });
        },
    });
};
